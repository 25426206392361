import { NgModule, Injectable, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppControlService } from './app-control.service';
import { WebSerialService } from './serial/web-serial.service';
import { ClientSerialService } from './serial/client-serial.service';
import {
    ConfigurationService,
    CoreModule,
    CustomerDisplayModule,
    PersonalizationService,
    ScreensWithPartsModule,
    SelfCheckoutModule,
    SharedModule,
    PLUGINS
} from '@jumpmind/openpos-client-core-lib';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AutoDeployPlugin } from './plugins/auto-deploy-plugin';
import { PriceCheckerHomeComponent } from './price-checker/price-checker-home/price-checker-home.component';
import { PriceCheckerItemDetailComponent } from './price-checker/price-checker-item-detail/price-checker-item-detail.component';
import { OrderCustomerComponent } from './orders/components/order-customer/order-customer.component';
import { OrderDueComponent } from './orders/components/order-due/order-due.component';
import { OrderHeaderComponent } from './orders/components/order-header/order-header.component';
import { OrderHeaderLabelComponent } from './orders/components/order-header-label/order-header-label.component';
// import { OrderItemComponent } from './orders/components/order-item/order-item.component';
import { OrderListComponent } from './orders/order-list/order-list.component';
import { OrderSummaryComponent } from './orders/components/order-summary/order-summary.component';
import { OrderStatusComponent } from './orders/components/order-status/order-status.component';
import { OrderItemCountComponent } from './orders/components/order-item-count/order-item-count.component';
import { OrderNotesComponent } from './orders/components/order-notes/order-notes.component';
import { OrderTotalComponent } from './orders/components/order-total/order-total.component';
import { OrderPickComponent } from './orders/order-pick/order-pick.component';
import { OrderBatchPickComponent } from './orders/order-batch-pick/order-batch-pick.component';
import { OrderPackComponent } from './orders/order-pack/order-pack.component';
import { OrderAmountDueComponent } from './orders/components/order-amount-due/order-amount-due.component';
import { OrderDateComponent } from './orders/components/order-date/order-date.component';
import { OrderListHeaderComponent } from './orders/components/order-list-header/order-list-header.component';
import { OrderFulfillingLocationComponent } from './orders/components/order-fulfilling-location/order-fulfilling-location.component';
import { ShippingMethodComponent } from './orders/components/shipping-method/shipping-method.component';

import * as Hammer from 'hammerjs';
import { OrderDatesComponent } from './orders/components/order-dates/order-dates.component';
import { ItemSearchResultsComponent } from './item-search/item-search-results/item-search-results.component';
import { OrderReviewComponent } from './orders/order-review/order-review.component';
import { OrderChooseAddressDialogComponent } from './orders/order-choose-address-dialog/order-choose-address-dialog.component';
import { PptComponent } from './ppt/ppt.component';
import { SiteComponent } from './site/site.component';
import { OrderNoteDialogComponent } from './orders/order-note/order-note-dialog.component';
import { MobileMgmtDashboardComponent } from './mobile-mgmt/mobile-mgmt-dashboard/mobile-mgmt-dashboard.component';
import { ClientelingClientDashboardComponent } from './clienteling/clienteling-client-dashboard/clienteling-client-dashboard.component';
// tslint:disable-next-line:max-line-length
import { CheckoutDeviceToggleButtonComponent } from './mobile-mgmt/components/checkout-device-toggle-button/checkout-device-toggle-button.component';
import { ImageInstructionsComponent } from './mobile-mgmt/components/image-instructions/image-instructions.component';
import { IconInstructionsComponent } from './mobile-mgmt/components/icon-instructions/icon-instructions.component';
import { TransTotalsPanelComponent } from './mobile-mgmt/components/trans-totals-panel/trans-totals-panel.component';
import { CheckoutDeviceActionPanelComponent } from './mobile-mgmt/components/checkout-device-action-panel/checkout-device-action-panel.component';
import { StoreCycleComponent } from './manage/store-cycle/store-cycle.component';
import { TillCountComponent } from './manage/till-count/till-count.component';
import { TillSectionComponent } from './manage/components/till-section/till-section.component';
import { TillCountDialogComponent } from './manage/till-count/till-count-dialog.component';
import { ReportsDashboardComponent } from './manage/reports-dashboard/reports-dashboard.component';
import { ReportListComponent } from './manage/components/report-list/report-list.component';
import { ReportViewComponent } from './manage/components/report-view/report-view.component';
import { ShowReportComponent } from './manage/show-report/show-report.component';
import { ManageTillsComponent } from './manage/manage-tills/manage-tills.component';
import { ManageUsersComponent } from './manage/manage-users/manage-users.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PromotionSearchComponent } from './promotion-search/promotion-search.component';
import { PromotionSummaryComponent } from './promotion-search/promotion-summary/promotion-summary.component';
import { DashboardComponent } from './central/dashboard/dashboard.component';
import { AlertsComponent } from './central/alerts/alerts.component';
import { SyncStatusComponent } from './central/syncstatus/syncstatus.component';
import { CentralTransactionSearchComponent } from './central/central-transaction-search/central-transaction-search.component';
import { EmployeeDiscountSelectComponent } from './sale/employee-discount-select/employee-discount-select.component';
import { GiftReceiptItemsSelectionComponent } from './sale/gift-receipt-items-select/gift-receipt-items-selection.component';
import { MobileMgmtDeviceDetailsComponent } from './mobile-mgmt/mobile-mgmt-device-details/mobile-mgmt-device-details.component';
import { ManageDisplayTypesComponent } from './manage/manage-display-types/manage-display-types.component';
import { GiftReceiptValueCheckComponent } from './gift-receipt/gift-receipt-value-check/gift-receipt-value-check.component';
import { OrderStatusSectionComponent } from './orders/components/order-status-section/order-status-section.component';
import { OrderItemListComponent } from './orders/components/order-item-list/order-item-list.component';
import { OrderItemComponent } from './orders/components/order-item/order-item.component';
import { OrderItemHeaderComponent } from './orders/components/order-item-header/order-item-header.component';
import { OrderPackageHeaderComponent } from './orders/components/order-package-header/order-package-header.component';
import { OrderAcknowledgeButtonGroupComponent } from './orders/components/order-acknowledge-button-group/order-acknowledge-button-group.component';
import { OpenOrdersComponent } from './orders/open-orders/open-orders.component';
import { OrderDeliverComponent } from './orders/order-deliver/order-deliver.component';
import { InstallGroupsComponent } from './central/install-groups/install-groups.component';
import { CentralBaconStripComponent } from './central/screen-parts/central-bacon-strip/central-bacon-strip.component';
import { CentralTransactionDetailsComponent } from './central/central-transaction-details/central-transaction-details.component';
import { CentralReportsDashboardComponent } from './central/central-reports-dashboard/central-reports-dashboard.component';
import { CentralLocalNavStripComponent } from './central/screen-parts/central-local-nav-strip/central-local-nav-strip.component';
import { InstallGroupDetailsComponent } from './central/install-group-details/install-group-details.component';
import { CentralPriceCheckerComponent } from './central/central-price-checker/central-price-checker.component';
import { CentralConfigurationComponent } from './central/central-configuration/central-configuration.component';
import { PartyDashboardComponent } from './party-dashboard/party-dashboard.component';
import { PartyDetailsComponent } from './party-details/party-details.component';
import { PartyInprogressComponent } from './party-inprogress/party-inprogress.component';
import { DeploymentsComponent } from './central/deployments/deployments.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { CentralStoreMapFilterComponent } from './central/screen-parts/central-store-map-filter/central-store-map-filter.component';
import { CentralStoreDetailsComponent } from './central/central-store-details/central-store-details.component';
import { SsnEntryComponent } from './ssn-entry/ssn-entry.component';
import { ProductInquiryComponent } from './item-search/product-inquiry/product-inquiry.component';
import { LocalConfirmationDialog } from './serial/web-serial.service'
import { MicroCapScreenComponent } from './micro-cap-screen/micro-cap-screen.component';

const dialogs = [
    OrderChooseAddressDialogComponent,
    OrderNoteDialogComponent,
    TillCountDialogComponent,
    EmployeeDiscountSelectComponent,
    GiftReceiptValueCheckComponent,
    SsnEntryComponent,
    CentralStoreDetailsComponent
];

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
    overrides = <any>{
        swipe: { direction: Hammer.DIRECTION_ALL },
    };
}

const screens = [
    PriceCheckerHomeComponent,
    PriceCheckerItemDetailComponent,
    OrderListComponent,
    OrderPickComponent,
    OrderBatchPickComponent,
    OrderPackComponent,
    OrderDeliverComponent,
    ItemSearchResultsComponent,
    ProductInquiryComponent,
    OrderReviewComponent,
    PptComponent,
    SiteComponent,
    MobileMgmtDashboardComponent,
    MobileMgmtDeviceDetailsComponent,
    ClientelingClientDashboardComponent,
    StoreCycleComponent,
    TillCountComponent,
    ReportsDashboardComponent,
    CentralReportsDashboardComponent,
    ShowReportComponent,
    ManageTillsComponent,
    ManageDisplayTypesComponent,
    PromotionSearchComponent,
    PartyDashboardComponent,
    PartyDetailsComponent,
    PartyInprogressComponent,
    ManageUsersComponent,
    GiftReceiptItemsSelectionComponent,
    DashboardComponent,
    AlertsComponent,
    SyncStatusComponent,
    DeploymentsComponent,
    CentralTransactionSearchComponent,
    CentralTransactionDetailsComponent,
    GiftReceiptItemsSelectionComponent,
    OpenOrdersComponent,
    InstallGroupsComponent,
    InstallGroupDetailsComponent,
    CentralPriceCheckerComponent,
    CentralConfigurationComponent,
    MicroCapScreenComponent
];

const screenParts = [
    OrderListHeaderComponent,
    CentralBaconStripComponent,
    CentralLocalNavStripComponent
];

@NgModule({
    entryComponents: [
        ...screens,
        ...dialogs,
        LocalConfirmationDialog
    ],
    declarations: [
        ...screens,
        OrderSummaryComponent,
        OrderStatusSectionComponent,
        OrderItemComponent,
        OrderCustomerComponent,
        OrderItemListComponent,
        OrderHeaderComponent,
        OrderHeaderLabelComponent,
        OrderDueComponent,
        OrderItemCountComponent,
        OrderStatusComponent,
        AppComponent,
        OrderNotesComponent,
        OrderTotalComponent,
        OrderAmountDueComponent,
        OrderDateComponent,
        CheckoutDeviceToggleButtonComponent,
        ImageInstructionsComponent,
        IconInstructionsComponent,
        TransTotalsPanelComponent,
        CheckoutDeviceActionPanelComponent,
        TillSectionComponent,
        ReportListComponent,
        ReportViewComponent,
        ...screenParts,
        OrderFulfillingLocationComponent,
        OrderDatesComponent,
        ...dialogs,
        PromotionSummaryComponent,
        OrderItemListComponent,
        OrderItemComponent,
        OrderItemHeaderComponent,
        OrderAcknowledgeButtonGroupComponent,
        ShippingMethodComponent,
        OrderPackageHeaderComponent,
        CentralStoreMapFilterComponent,
        LocalConfirmationDialog,
            ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        CoreModule,
        SharedModule,
        GoogleMapsModule,
        ScreensWithPartsModule,
        SelfCheckoutModule,
        CustomerDisplayModule
    ],
    providers: [
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: MyHammerConfig,
        },
        { provide: PLUGINS, useExisting: AutoDeployPlugin, multi: true },
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(configService: ConfigurationService, appControlService: AppControlService, personalization: PersonalizationService,
        webSerialService: WebSerialService, clientSerialService: ClientSerialService) {
    }
}