import { Component, Input } from '@angular/core';
import { IOrderSummary, MediaBreakpoints, OpenposMediaService } from '@jumpmind/openpos-client-core-lib';
import { Observable } from 'rxjs/internal/Observable';
import { OrderStatus } from '../../interfaces/order-status.enum';

@Component({
    selector: 'nu-order-summary',
    templateUrl: './order-summary.component.html',
    styleUrls: ['./order-summary.component.scss']
})
export class OrderSummaryComponent {
    @Input()
    orderSummary: IOrderSummary;

    @Input()
    actionIcon: string;

    @Input()
    displayVertical = false;

    @Input()
    showStatus = false;

    isMobile$: Observable<boolean>;

    constructor(private media: OpenposMediaService) {
        this.initIsMobile();
    }

    initIsMobile(): void {
        this.isMobile$ = this.media.observe(new Map([
            [MediaBreakpoints.MOBILE_PORTRAIT, true],
            [MediaBreakpoints.MOBILE_LANDSCAPE, true],
            [MediaBreakpoints.TABLET_PORTRAIT, true],
            [MediaBreakpoints.TABLET_LANDSCAPE, true],
            [MediaBreakpoints.DESKTOP_PORTRAIT, false],
            [MediaBreakpoints.DESKTOP_LANDSCAPE, false]
        ]));
    }

    public isOrderOpen(): boolean {
        return this.orderSummary.statusCode === OrderStatus.OPEN;
    }

    public isOrderPendingDelivery(): boolean {
        return this.orderSummary.statusCode === OrderStatus.PACKED;
    }
}
