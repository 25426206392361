import { Component, Injector } from '@angular/core';
import { MediaBreakpoints, OpenposMediaService, PosScreenDirective, ScreenComponent } from '@jumpmind/openpos-client-core-lib';
import { TransactionDetailsInterface } from '@jumpmind/openpos-client-core-lib/lib/screens-with-parts/transaction-details/transaction-details.interface';
import { Observable } from 'rxjs';

@ScreenComponent({
    name: 'CentralTransactionDetails'
})
@Component({
    selector: 'nu-central-transaction-details',
    templateUrl: './central-transaction-details.component.html',
    styleUrls: ['./central-transaction-details.component.scss']
})
export class CentralTransactionDetailsComponent extends PosScreenDirective<TransactionDetailsInterface> {

    isMobile: Observable<boolean>;

    constructor(injector: Injector, media: OpenposMediaService) {
        super(injector);
        this.isMobile = media.observe(new Map([
            [MediaBreakpoints.MOBILE_PORTRAIT, true],
            [MediaBreakpoints.MOBILE_LANDSCAPE, true],
            [MediaBreakpoints.TABLET_PORTRAIT, true],
            [MediaBreakpoints.TABLET_LANDSCAPE, false],
            [MediaBreakpoints.DESKTOP_PORTRAIT, false],
            [MediaBreakpoints.DESKTOP_LANDSCAPE, false]
        ]));
    }

    buildScreen() { }

}
