import { Component, Injector, ViewChild } from '@angular/core';
import { PosScreenDirective, ScreenComponent } from '@jumpmind/openpos-client-core-lib';
import { SyncStatusInterface } from './syncstatus.interface';

@ScreenComponent({
  name: 'SyncStatus'
})
@Component({
  selector: 'nu-syncstatus',
  templateUrl: './syncstatus.component.html',
  styleUrls: ['./syncstatus.component.scss']
})
export class SyncStatusComponent extends PosScreenDirective<SyncStatusInterface> {

  @ViewChild('storestatusmap') storeStatusMap;

 
  constructor(injector: Injector) {
    super(injector);
  }

  buildScreen(): any {
  }

  ngOnInit() {

  }
  ngAfterViewInit(): void {

  }


  onPanelClicked(item: any, event: any): void {
    this.doAction(item, item.itemId);
}    

  
}
