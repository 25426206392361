<app-bacon-strip>
    <main class="content" responsive-class>
        <div *ngFor="let appId of appIds">
            <app-instructions [instructions]="getTogglePrefix() + getDisplayLabel(appId)"></app-instructions>
            <app-content-card>
                <div *ngIf="!devices || devices.length === 0" class="manage-display no-paired-available">
                    <app-icon [iconName]="screen.noDisplaysIcon"
                              iconClass="xl primary manage-display-icon"></app-icon>
                    <div class="manage-display-info">
                        <h4 class="manage-display-title" responsive-class>{{screen.noDisplaysLabel}}</h4>
                        <p class="manage-display-help">{{screen.noDisplaysHelp}}</p>
                    </div>
                </div>
                <ul *ngIf="devices?.length > 0" class="manage-display-list">
                    <li *ngFor="let device of getDevicesByAppId(appId); trackBy: devicesTrackByFn.bind(this)" class="manage-display">
                        <app-icon [iconName]="screen.displayIcon" iconClass="xl manage-display-icon" class="'primary'"></app-icon>
                        <div class="manage-display-info">
                            <div class="field manage-display-title" responsive-class>
                                <div class="field-label">{{getDisplayLabel(device.appId)}}</div>
                                <div class="field-value">{{device.deviceId}}</div>
                            </div>
                            <div class="field manage-display-description" *ngIf="hasSecondaryMessage(device)">
                                <div class="field-label">{{getSecondaryLabel(device)}}</div>
                                <div class="field-value" *ngIf="getSecondaryMessage(device)">{{getSecondaryMessage(device)}}</div>
                            </div>
                        </div>
                        <app-secondary-button class="manage-display-toggle-button" (buttonClick)="initiateToggle(device.deviceId)">
                            {{screen.initiateToggleAction.title}}
                        </app-secondary-button>
                    </li>
                </ul>
            </app-content-card>
        </div>
    </main>
</app-bacon-strip>