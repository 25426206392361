import { Component, OnDestroy, Injector, OnInit } from '@angular/core';
import {
    ImageService,
    PosScreenDirective,
    ScreenComponent
} from '@jumpmind/openpos-client-core-lib';
import { SiteInterface } from './site.interface';

@ScreenComponent({
    name: 'Site'
})
@Component({
    selector: 'nu-site',
    templateUrl: './site.component.html',
    styleUrls: ['./site.component.scss']
})
export class SiteComponent extends PosScreenDirective<SiteInterface> implements OnDestroy, OnInit {

    url: string;
    sandbox: string;

    constructor(private imageService: ImageService, injector: Injector) {
        super(injector);
    }

    ngOnInit(): void {
    }

    buildScreen() {
        this.url = this.screen.url;
        this.sandbox = this.screen.sandbox;
    }

    ngOnDestroy(): void {
    }
}
