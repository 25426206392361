<div class="order-item-container" responsive-class>
    <app-icon *ngIf="getIconName()" [ngClass]="{'checked': item.checked}" 
              [iconName]="getIconName()" [iconClass]="getIconClass()" responsive-class></app-icon>
    <div [class]="classes + ' order-item'" responsive-class>
        <app-image [imageUrl]="item.imageUrl | imageUrl" altText="Image Not Found"></app-image>
        <div responsive-class class="item-details">
            <strong>{{item.description}}</strong>
            <div class="additional-details">
                <div>
                    <div>{{item.itemNumberLabel}} {{item.itemNumber}}</div>
                    <div>{{item.itemQuantityLabel}} {{item.quantity}}</div>
                </div>
                <div responsive-class *ngIf="showOrderDetails && (isMobile$ | async)" class="order-details">
                    <app-icon [iconName]="item.orderTypeIcon" [iconClass]="'xs'"></app-icon>
                    <span class="secondary-text">{{item.orderType}} {{item.orderItemKey.orderNumber}}</span>
                </div>
            </div>
        </div>
        <div responsive-class *ngIf="showOrderDetails && !(isMobile$ | async)" class="order-details">
            <app-icon [iconName]="item.orderTypeIcon" [iconClass]="'xs'"></app-icon>
            <span class="secondary-text">{{item.orderType}} {{item.orderItemKey.orderNumber}}</span>
        </div>
    </div>
</div>
