import {
    IDevice,
    MessageTypes,
    PosScreenDirective,
    ScreenComponent,
    SessionService
} from '@jumpmind/openpos-client-core-lib';
import { Component, Injector } from '@angular/core';
import { ManageDisplayTypesInterface, SecondaryMessages } from './manage-display-types.interface';
import { takeUntil } from 'rxjs/operators';

@ScreenComponent({
    name: 'ManageDisplayTypes'
})
@Component({
    selector: 'nu-manage-display-types',
    templateUrl: './manage-display-types.component.html',
    styleUrls: ['./manage-display-types.component.scss']
})
export class ManageDisplayTypesComponent extends PosScreenDirective<ManageDisplayTypesInterface> {
    devices: IDevice[];
    appIds: Set<String> = new Set<String>();
    secondaryMessages: SecondaryMessages;

    constructor(injector: Injector, private sessionService: SessionService) {
        super(injector);

        // Refresh the view when the state of peripherals change
        this.sessionService.getMessages(MessageTypes.DEVICE_PAIRING_CHANGED)
            .pipe(takeUntil(this.destroyed$))
            .subscribe(message => {
                this.devices = message.devices;
                this.recollectAppIds();
                this.secondaryMessages = message.secondaryMessages;
            });
    }

    devicesTrackByFn(index, item: IDevice) {
        if (this.hasSecondaryMessage(item)) {
            const itemMessage = this.secondaryMessages[item.deviceId];
            return itemMessage.label + itemMessage.message;
        }
        return '';
    }

    buildScreen() {
        this.devices = this.screen.devices;
        this.recollectAppIds();
        this.secondaryMessages = this.screen.secondaryMessages;
    }

    recollectAppIds() {
        this.appIds = new Set<String>();
        this.devices.forEach(device => {
            this.appIds.add(device.appId);
        });
    }

    getDevicesByAppId(appId: string): IDevice[] {
        return this.devices.filter(device => device.appId === appId);
    }

    initiateToggle(deviceId: string): void {
        this.doAction(this.screen.initiateToggleAction, deviceId);
    }

    hasSecondaryMessage(device: IDevice): boolean {
        return !!this.secondaryMessages[device.deviceId];
    }

    getSecondaryLabel(device: IDevice): string {
        return this.secondaryMessages[device.deviceId].label;
    }

    getSecondaryMessage(device: IDevice): string {
        return this.secondaryMessages[device.deviceId].message;
    }

    getTogglePrefix(): string {
        return `${this.screen.toggleTitlePrefix} `;
    }

    getDisplayLabel(appId: string): string {
        return this.screen.labels[appId];
    }
}
