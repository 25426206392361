import { Inject, Injectable, Component } from '@angular/core';
import { filter } from 'rxjs/operators';
import { SerialMessage } from './serial-message';
import { ClientSerialPairMessage } from './client-serial-pair-message';
import { ClientSerialConfig } from './client-serial-pair-message'
import { SessionService, ActionMessage, MessageTypes,  ElectronPlatform } from '@jumpmind/openpos-client-core-lib';
import { LEADING_TRIVIA_CHARS } from '@angular/compiler/src/render3/view/template';

@Injectable({
    providedIn: 'root'
})
// Support for writing various commands from the server to a given serial port. 
// Fo thin client support of things like a traditional POS printer or a 2x20 pole display.
export class ClientSerialService {

    pairMessage: ClientSerialPairMessage;
    
    constructor(protected sessionService: SessionService
        , private electronPlatform: ElectronPlatform) {

        // Subscribe to Proxy messages that go to a printer
        sessionService.getMessages('Proxy').pipe(
        filter(m => m.proxyType === 'ClientSerial')).subscribe(message => {
            this.log("Received message " + JSON.stringify(message));
            if (message.action === 'ClientSerialStartScanner') {
                this.startScanner((message as any))
            } else {
                this.write((message as SerialMessage))
            }            
        });

        sessionService.getMessages('ClientSerial').pipe().subscribe(message => {
            this.log("Received pair message " + JSON.stringify(message));
            this.openPorts((message as ClientSerialPairMessage))
        });            
    }

    async openPorts(message: ClientSerialPairMessage): Promise<void> {
        if (message) {
            this.pairMessage = message;
        }

        let pairedConfigs = new Array<ClientSerialConfig>();

        if (this.electronPlatform.platformPresent()) {
            (window.openposElectron as any).nodeSerial.openPorts(this.pairMessage);
        } else {
            this.logError("ClientSerialService can only function within the electron envirnoment.");
        }
        

        for (let clientSerialConfig of this.pairMessage.clientSerialStatus.clientSerialPorts) {
            let pairedConfig: ClientSerialConfig = new ClientSerialConfig()
            pairedConfig.displayName = clientSerialConfig.displayName;
            pairedConfig.logicalName = clientSerialConfig.logicalName;
            pairedConfig.settings = new Object();
            pairedConfig.pairedFlag = true;
    
            pairedConfigs.push(pairedConfig);            
        }

        this.log(JSON.stringify(pairedConfigs));
    
        this.sessionService.sendMessage(new ActionMessage("ClientSerialPairingStatus", true, pairedConfigs));
    }

    async write(message: SerialMessage): Promise<void> {  
        if (this.electronPlatform.platformPresent()) {
            try {
                await (window.openposElectron as any).nodeSerial.write(message);
            } catch (error) {
                this.logError("Failed to write serial data " + error.message)
                this.handleError(message, error);
            }
            
        } else {
            this.logError("ClientSerialService can only function within the electron envirnoment.");
        }

        this.handleSuccess(message, "SUCCESS");
    }

    async startScanner(message: any): Promise<void> {    
        if (this.electronPlatform.platformPresent()) {
            try {
                (window.openposElectron as any).nodeSerial.startScanner(message, (scanData: Uint8Array) => {
                    let scanDataString = new TextDecoder().decode(scanData);
                    const scanMessage = new ActionMessage('Scan', true, {"data" : scanDataString.trim() })
                    this.log('sending scan message: ' + JSON.stringify(scanMessage));
                    this.sessionService.sendMessage(scanMessage);              
                });
            } catch (error) {
                this.logError("Failed to write serial data " + error.message)
                this.handleError(message, error);
            }
            
        } else {
            this.logError("ClientSerialService can only function within the electron envirnoment.");
        }
    }
    
    handleSuccess(message: any, response: string) {
        this.log(`sending serial successful response`);

        const responseMessage = new ActionMessage('response', true, { messageId: message.messageId, payload: response, success: true });
        responseMessage.type = MessageTypes.PROXY;
        this.sessionService.sendMessage(responseMessage);
    }

    handleError(message: any, response: string) {
        this.logError(`failed: ${response}`);
        const responseMessage = new ActionMessage('response', true, { messageId: message.messageId, payload: response, success: false });
        responseMessage.type = MessageTypes.PROXY;
        this.sessionService.sendMessage(responseMessage);
    }

    log(message: string) {
        console.log("[client-serial-service] " + message);
    }

    logError(message: string) {
        console.error("[client-serial-service] " + message);
    }    
    
}





