import { Inject, Injectable, Component } from '@angular/core';
import { filter } from 'rxjs/operators';
import { SerialMessage } from './serial-message';
import { ClientSerialPairMessage, ClientSerialConfig, ClientSerialStatus } from './client-serial-pair-message';
import { SessionService, ActionMessage, MessageTypes } from '@jumpmind/openpos-client-core-lib';

import { MatDialogRef, MatDialog, MatDialogConfig, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

export interface ScanData {
    rawType?: string;
    type?: string;
    data: string;
    rawData?: string;
}

@Component({
    selector: 'local-confirmation-dialog',
    templateUrl: './local-confirmation-dialog.html',
  })
  export class LocalConfirmationDialog {
  
    public dialogMessage;
    public dialogTitle;
  
    constructor(
      public dialogRef: MatDialogRef<LocalConfirmationDialog>,
      @Inject(MAT_DIALOG_DATA) public data: any) {
        this.dialogMessage = data;
      }
  
    onOkClick(): void {
      this.dialogRef.close();
    }
  }


@Injectable({
    providedIn: 'root'
})
// Support for writing various commands from the server to a given serial port. 
// Fo thin client support of things like a traditional POS printer or a 2x20 pole display.
export class WebSerialService {
    
    constructor(
        protected sessionService: SessionService,
        public dialog: MatDialog) {

        // Subscribe to Proxy messages that go to a printer
        sessionService.getMessages('Proxy').pipe(
        filter(m => m.proxyType === 'WebSerial')).subscribe(message => {
            if (message.action === 'ClientSerialStartScanner') {
                this.startScanner((message as any))
            } else {
                this.write((message as SerialMessage))
            }            
        });

        sessionService.getMessages('WebSerialPair').pipe().subscribe(message => {
                this.pair(sessionService, (message as ClientSerialPairMessage))
        });            
    }

    async pair(sessionService: SessionService, message: ClientSerialPairMessage): Promise<void> {

        let navigatorWithSerial:any = navigator;

        let pairedConfigs = new Array<ClientSerialConfig>();

        let port = this.openPort(message);
        if (!port) {
            console.log("[WebSerialService] pair failed to open a port " + this.messageToString(message));
            sessionService.sendMessage(new ActionMessage("ClientSerialPairingStatus", true, null));
            return;
        }

        let providedConfig = message.clientSerialStatus.clientSerialPorts[0];

        let pairedConfig: ClientSerialConfig = new ClientSerialConfig()

        pairedConfig.displayName = providedConfig.displayName;
        pairedConfig.logicalName = providedConfig.logicalName;
        pairedConfig.settings = new Object();
        pairedConfig.pairedFlag = true;

        pairedConfigs.push(pairedConfig);

        console.log("[WebSerialService] " + pairedConfigs);
    
        sessionService.sendMessage(new ActionMessage("ClientSerialPairingStatus", true, pairedConfigs));
    }

    async write(message: SerialMessage): Promise<void> {    

        let proxyMessage = message as any;
        
        try {
            const bytes = this._base64ToArrayBuffer(message.payload)
            console.log("[WebSerialService] serial write byte count " + bytes.byteLength);
            if (proxyMessage.additionalFields.plainTextPayload) {
                console.log("[WebSerialService] serial write data: '" + proxyMessage.additionalFields.plainTextPayload + "'");
            }
            
            let waitForDrawerCloseCommand = "waitForDrawerClose";
            if (bytes.byteLength == waitForDrawerCloseCommand.length) {
                var dec = new TextDecoder("utf-8");
                let command = dec.decode(bytes);
                if (command === waitForDrawerCloseCommand) {
                    this.waitForDrawerClose(message);
                    return;
                }
            }

            let port:any = await this.openPort(message);
            if (!port) {
                console.log("[WebSerialService] no port provided for " + this.messageToString(message));
                return;
            }                    
            
            const writer = port.writable.getWriter();   
            let decoder = new TextDecoderStream();
            let inputStream = decoder.readable;
            let reader = inputStream.getReader();

            await writer.write(bytes);

            writer.releaseLock();
            reader.releaseLock();
            decoder.readable.cancel();

            this.handleSuccess(message, "SUCCESS");
        } catch (ex) {
            this.handleError(message, ex);
        }       
    }

    async waitForDrawerClose(message: SerialMessage): Promise<void> {     
        console.log("[WebSerialService] waitForDrawerClose");
        try {
            let timeoutMillis = 60*1000;
            let port:any = await this.openPort(message);

            let start = Date.now();

            const writer = port.writable.getWriter();   
            let decoder = new TextDecoderStream();
            let inputDone = port.readable.pipeTo(decoder.writable); // this appears to be needed to read the input properly.
            let inputStream = decoder.readable;
            const reader = inputStream.getReader();               

            const checkDrawerStatusCommand = new Uint8Array([0x1D, 0x72, 2]);
            let DRAWER_CLOSED = 1;

            while (Date.now()-start < timeoutMillis) {
                await writer.write(checkDrawerStatusCommand);    
                await this.sleep(500);   
                const { value, done } = await reader.read();
                if (value) {
                    
                    let statusCode = value.charCodeAt(0); // byte one
                    let isStringOne = value.charAt(0) == '1'; // for testing.
                    console.log("[WebSerialService] Drawer reported status of " + value + " code: " + statusCode);
                    // Closed could be 1 or 0 depending on the drawer wiring. TODO support flipping if needed.
                    
                    if (statusCode == DRAWER_CLOSED || isStringOne) {
                        break;
                    } else {
                        await this.sleep(500);      
                    }
                }
            }

            
            writer.releaseLock();
            reader.releaseLock();
            decoder.readable.cancel();

            this.handleSuccess(message, "Drawer reported closed.");
        } catch (ex) {
            this.handleError(message, ex);
        }       
    }    

    async openPort(message: any): Promise<any> {
        let navigatorWithSerial:any = navigator;
        console.info("[WebSerialService] Opening/retriving serial port " + this.messageToString(message));

        const ports = await navigatorWithSerial.serial.getPorts();

        let baudRate = 9600;
        if (message.clientSerialPorts) {
            let statusMessage = message as ClientSerialStatus;
            if (statusMessage.clientSerialPorts[0].settings.baudRate) {
                baudRate = parseInt(statusMessage.clientSerialPorts[0].settings.baudRate);
            }
        } else if (message.additionalFields && message.additionalFields.baudRate) {
            baudRate = message.additionalFields.baudRate;
        }

        let port = null;

        if (!ports || ports.length == 0) {
            await this.showNofitication("Please select the printer port on the next screen.").pipe(take(1)).toPromise()

            try {
                port = await navigatorWithSerial.serial.requestPort();
            } catch (ex) {
                console.log("Failed to pair port. might be cancelled " + ex.message);
                return null;
            }
            
        } else {
            port = ports[0];    
        }

        // dataBits: The number of data bits per frame (either 7 or 8).
        // stopBits: The number of stop bits at the end of a frame (either 1 or 2).
        // parity: The parity mode (either "none", "even" or "odd").
        // bufferSize: The size of the read and write buffers that should be created (must be less than 16MB).
        // flowControl: The flow control mode (either "none" or "hardware").
                        
        try {
            if (!port.isOpen) {
                await port.open({ baudRate: 19200, bufferSize: 32000 });
                port.isOpen = true;
            } 
            return port;
        }
        catch (ex) {
            console.log("[WebSerialService] Error while openeing port " + message + " " + ex.message);  
            return null;
        }      
    }

    async startScanner(message: any): Promise<void> {
        console.log("[WebSerialService] Staring scanner readLoop... " + message);
        let port:any = await this.openPort(message);
        if (!port) {
            console.log("[WebSerialService] no port provided for " + message);
            return;
        }        

        try {        
            const writer = port.writable.getWriter();   
            let decoder = new TextDecoderStream();
            let inputDone = port.readable.pipeTo(decoder.writable);
            let inputStream = decoder.readable;
            let reader = inputStream.getReader();      
            this.readLoop(reader);
        }
        catch (ex) {
            console.log("error during scanner read " + ex);
        }
    }    

    async readLoop(reader: any) {
        let buffer:string = "";
        while (true) {
            const { value, done } = await reader.read();
            if (value) {
                buffer += value;
                let lastChar = buffer.charCodeAt(buffer.length-1);
                if (lastChar == 3 || lastChar == 10) {
                    console.log("[WebSerialService] Got serial scan " + buffer);        
                    
                    let scanData = new Object() as ScanData;
                    scanData.data = buffer.trim();
                    const scanMessage = new ActionMessage('Scan', true, scanData);                    
                    this.sessionService.sendMessage(scanMessage);
                    buffer = "";
                }
            }
            if (done) {
                console.log('[WebSerialService][readLoop] DONE', done);
                reader.releaseLock();
                break;
            }
        }
    }        

    _base64ToArrayBuffer(base64: any) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    }
    
    handleSuccess(message: any, response: string) {
        console.info(`[WebSerialService] serial successful`);

        const responseMessage = new ActionMessage('response', true, { messageId: message.messageId, payload: response, success: true });
        responseMessage.type = MessageTypes.PROXY;
        this.sessionService.sendMessage(responseMessage);
    }

    handleError(message: any, response: string) {
        console.error(`[WebSerialService] failed: ${response}`);
        const responseMessage = new ActionMessage('response', true, { messageId: message.messageId, payload: response, success: false });
        responseMessage.type = MessageTypes.PROXY;
        this.sessionService.sendMessage(responseMessage);
    }    

    sleep(ms: number) {
        return new Promise( resolve => setTimeout(resolve, ms) );
    }    

    showNofitication(message: string): Observable<any> {
        console.log("Will try and show notification. " + message);
    
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = message;
        const dialogRef = this.dialog.open(LocalConfirmationDialog, dialogConfig);
        return dialogRef.afterClosed();
    }    

    portToString(port) {
        if (port) {
            return "port=" + port + " port.getInfo()=" + JSON.stringify(port.getInfo());
        } else {
            return "port is null";
        }
    }    
    messageToString(message) {
        if (message) {
            return "port=" + message + " port.getInfo()=" + JSON.stringify(message);
        } else {
            return "port is null";
        }
    }        
    
}



