import { Component, Input } from '@angular/core';
import { ActionService, IActionItem } from '@jumpmind/openpos-client-core-lib';
import { IOrderNote } from './order-note.interface';

@Component({
    selector: 'nu-order-notes',
    templateUrl: './order-notes.component.html',
    styleUrls: ['./order-notes.component.scss']
})
export class OrderNotesComponent {
    @Input()
    notes: IOrderNote[];

    @Input()
    addNoteButton: IActionItem;

    constructor(public actionService: ActionService) { }

    doAction(action: IActionItem) {
        this.actionService.doAction(action);
    }

}
