<app-dialog-header></app-dialog-header>
<div class="body">
    <app-instructions *ngIf="screen.instructions" [instructions]="screen.instructions" [instructionsSize]="'text-sm'"></app-instructions>

    <div class="text-area-outer">
        <textarea responsive-class cdkFocusInitial class="text-area" matInput [(ngModel)]="note"></textarea>
    </div>
    <div *ngIf="screen.noteFromOptions && screen.noteFromOptions.length > 0" class="note-from">
        <mat-form-field>
            <mat-label>{{screen.noteFromLabel}}</mat-label>
            <mat-select [(value)]="selectedOption">
                <mat-option *ngFor="let option of screen.noteFromOptions;" [value]="option">
                    {{option}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <mat-dialog-actions class="buttons">
        <app-primary-button *ngIf="screen.addNoteButton" (buttonClick)="onAddNote(screen.addNoteButton)" 
            [actionItem]="screen.addNoteButton"
            [disabled]="(screen.noteFromOptions && screen.noteFromOptions.length > 0 && !selectedOption) || !note">
            {{screen.addNoteButton.title}}
        </app-primary-button>
    </mat-dialog-actions>
</div>