import { Component } from '@angular/core';
import { ScreenComponent, slideLeftAnimationTrigger, slideLeftRightAnimationTrigger,
    slideUpDownAnimationTrigger, slideDownUpAnimationTrigger } from '@jumpmind/openpos-client-core-lib';
import { ReportsDashboardComponent } from '../../manage/reports-dashboard/reports-dashboard.component';

@ScreenComponent({
    name: 'CentralReportsDashboard'
})
@Component({
    selector: 'nu-central-reports-dashboard',
    templateUrl: './central-reports-dashboard.component.html',
    styleUrls: ['./central-reports-dashboard.component.scss'],
    animations: [
        slideLeftAnimationTrigger,
        slideLeftRightAnimationTrigger,
        slideUpDownAnimationTrigger,
        slideDownUpAnimationTrigger
    ]
})
export class CentralReportsDashboardComponent extends ReportsDashboardComponent {
}
