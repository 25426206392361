<app-dialog-header></app-dialog-header>

<mat-dialog-content>
    <ng-container>
        <div class="gift-checker">
            <div class="enter-gift-code">
                <p>{{screen.enterCodeLabel}}</p>
                <input class="input-gift-code"
                       type="text"
                       placeholder="{{screen.placeholder}}"
                       oninput="this.value = this.value.toUpperCase()"
                       (keypress)="onGiftKeyValidator($event)" #inputGiftCode/>
                <button class="show-value-button"
                        mat-raised-button
                        (actionClick)="clickShowValue()"
                        (click)="clickShowValue()">
                    {{screen.showValueLabel}}
                </button>
            </div>

            <div class="unmask-value">
                <p>{{screen.resultLabel}}</p>
                <span [ngStyle]="!screen.enteredGiftCodeValid && {color: 'red'}">{{screen.resultValue}}</span>
            </div>
        </div>
    </ng-container>
</mat-dialog-content>

<app-prompt-button-row></app-prompt-button-row>