<div *ngIf="promotionSummary" class="promotion-summary-outer">
  <div>
    <app-icon *ngIf="promotionSummary.icon" [iconName]="promotionSummary.icon" iconClass="mat-64"></app-icon>
  </div>

  <div responsive-class class="summary-left-side">
    <div class="summary-details-header">
        <div class="summary-title">{{promotionSummary.name}}</div>
        <div class="summary-status"><mat-chip *ngIf="promotionSummary.status" class="summary-status-chip">{{promotionSummary.status.value}}</mat-chip></div>
    </div>
    <div class="summary-details-section">
      <div class="summary-details summary-detail-column">
        <div *ngIf="promotionSummary.promotionId">
          <span class="summary-label">{{promotionSummary.promotionId.label}}:</span>
          <span>{{promotionSummary.promotionId.value}}</span>
        </div>
      </div>      
      <div class="summary-details summary-detail-column">
        <div *ngIf="promotionSummary.autoApply">
          <span class="summary-label">{{promotionSummary.autoApply.label}}:</span>
          <span>{{promotionSummary.autoApply.value}}</span>
        </div>
      </div>
      <div class="summary-details summary-detail-column">
        <div *ngIf="promotionSummary.promptUser">
          <span class="summary-label">{{promotionSummary.promptUser.label}}:</span>
          <span>{{promotionSummary.promptUser.value}}</span>
        </div>
      </div>

      <div class="summary-details summary-detail-column">
        <div *ngIf="promotionSummary.startTime">
          <span class="summary-label">{{promotionSummary.startTime.label}}:</span>
          <span>{{promotionSummary.startTime.value}}</span>
        </div>
      </div>
      <div class="summary-details summary-detail-column">
        <div *ngIf="promotionSummary.endTime">
          <span class="summary-label">{{promotionSummary.endTime.label}}:</span>
          <span>{{promotionSummary.endTime.value}}</span>
        </div>
      </div>
      <div class="summary-details summary-detail-column">
        <div *ngIf="promotionSummary.usedTime">
          <span class="summary-label">{{promotionSummary.usedTime.label}}:</span>
          <span>{{promotionSummary.usedTime.value}}</span>
        </div>
      </div>
    </div>
  </div>

  <div responsive-class class="summary-right-side">
    <div responsive-class class="promotion-buttons">
      <button *ngFor="let button of promotionSummary.actions" mat-button color="primary" responsive-class
        class="promotion-button" (click)="onClick(button)" [disabled]="!button.enabled">
        <span class="promotion-button-text">{{button.title}}</span>
        <app-icon [iconName]="button.icon" iconClass="mat-24"></app-icon>
      </button>
    </div>
  </div>
</div>