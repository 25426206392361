<app-bacon-strip class="promotion-bacon-strip">
<div class="main-layout">
    <div class="on-deck-container">
        <div class="on-deck-header">
            <svg class="header-icon" width="25px" viewBox="0 0 50 57" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g id="bab-party-integration" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Group" fill="#FFFFFF" fill-rule="nonzero">
                        <path d="M44.4444444,50.625 L5.55555556,50.625 L5.55555556,19.6875 L44.4444444,19.6875 M44.4444444,5.625 L41.6666667,5.625 L41.6666667,0 L36.1111111,0 L36.1111111,5.625 L13.8888889,5.625 L13.8888889,0 L8.33333333,0 L8.33333333,5.625 L5.55555556,5.625 C2.47222222,5.625 0,8.15625 0,11.25 L0,50.625 C0,53.7316017 2.48730695,56.25 5.55555556,56.25 L44.4444444,56.25 C47.5126931,56.25 50,53.7316017 50,50.625 L50,11.25 C50,8.14339828 47.5126931,5.625 44.4444444,5.625 M37.5833333,28.29375 L34.6388889,25.3125 L21.0833333,39.0375 L15.1944444,33.075 L12.25,36.05625 L21.0833333,45 L37.5833333,28.29375 Z" id="Shape"></path>
                    </g>
                </g>
            </svg>            
            <div class="on-deck-title">On Deck Today</div>
        </div>
        <div class="on-deck-body">
            <div class="time-index">
                <div class="time-slot">10:00 AM</div>
                <div class="time-slot">10:30 AM</div>
                <div class="time-slot">11:00 AM</div>
                <div class="time-slot">11:30 AM</div>
                <div class="time-slot">12:00 PM</div>
                <div class="time-slot">12:30 PM</div>
                <div class="time-slot">1:30 PM</div>
                <div class="time-slot">2:00 PM</div>
                <div class="time-slot">2:30 PM</div>
                <div class="time-slot">3:00 PM</div>
                <div class="time-slot">3:30 PM</div>
                <div class="time-slot">4:00 PM</div>
                <div class="time-slot">4:30 PM</div>
                <div class="time-slot">5:00 PM</div>
                <div class="time-slot">5:30 PM</div>
                <div class="time-slot">6:00 PM</div>
                <div class="time-slot">6:30 PM</div>
            </div>
            <div class="calendar-grid">
                <div class="calender-entry" (click)="doAction('PartySelected', '1' )">
                    <div style="grid-area: icon" >
                        <svg width="25px"  viewBox="0 0 50 62" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <g id="bab-party-integration" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <path d="M25,16.684492 C28.0833333,16.684492 30.5555556,14.1818182 30.5555556,11.1229947 C30.5555556,10.0663102 30.2777778,9.09304813 29.75,8.25882353 L25,2.84217094e-14 L20.25,8.25882353 C19.7222222,9.09304813 19.4444444,10.0663102 19.4444444,11.1229947 C19.4444444,14.1945248 21.9317514,16.684492 25,16.684492 M37.7777778,44.4919786 L34.8055556,41.4887701 L31.8055556,44.4919786 C28.1944444,48.0791444 21.8611111,48.1069519 18.2222222,44.4919786 L15.2777778,41.4887701 L12.2222222,44.4919786 C10.4166667,46.2716578 8,47.2727273 5.44444444,47.2727273 C3.41666667,47.2727273 1.55555556,46.6331551 0,45.5764706 L0,58.3957219 C0,59.931487 1.24365347,61.1764706 2.77777778,61.1764706 L47.2222222,61.1764706 C48.7563465,61.1764706 50,59.931487 50,58.3957219 L50,45.5764706 C48.4444444,46.6331551 46.5833333,47.2727273 44.5555556,47.2727273 C42,47.2727273 39.5833333,46.2716578 37.7777778,44.4919786 M41.6666667,25.026738 L27.7777778,25.026738 L27.7777778,19.4652406 L22.2222222,19.4652406 L22.2222222,25.026738 L8.33333333,25.026738 C3.73096042,25.026738 0,28.7616887 0,33.368984 L0,37.6513369 C0,40.6545455 2.44444444,43.1016043 5.44444444,43.1016043 C6.94444444,43.1016043 8.33333333,42.5454545 9.27777778,41.5165775 L15.2777778,35.5935829 L21.1388889,41.5165775 C23.1944444,43.5743316 26.7777778,43.5743316 28.8333333,41.5165775 L34.7222222,35.5935829 L40.6944444,41.5165775 C41.6666667,42.5454545 43.0555556,43.1016043 44.5277778,43.1016043 C47.5277778,43.1016043 50,40.6545455 50,37.6513369 L50,33.368984 C50,28.7616887 46.2690396,25.026738 41.6666667,25.026738 Z" id="Shape-Copy" fill="#FFFFFF" fill-rule="nonzero"></path>
                            </g>
                        </svg>
                    </div>
                    <div style="grid-area: title" class="calendar-entry-title" >
                        Jack's Birthday
                    </div>   
                    <div class="calendar-entry-status" >
                        <svg width="30px"  viewBox="0 0 60 60" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <g id="bab-party-integration" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="Group-Copy" fill="#FFFFFF" fill-rule="nonzero">
                                    <path d="M30,0 C13.5,0 0,13.5 0,30 C0,46.5 13.5,60 30,60 C46.5,60 60,46.5 60,30 C60,13.5 46.5,0 30,0 M30,54 C16.77,54 6,43.23 6,30 C6,16.77 16.77,6 30,6 C43.23,6 54,16.77 54,30 C54,43.23 43.23,54 30,54 M43.77,16.74 L24,36.51 L16.23,28.77 L12,33 L24,45 L48,21 L43.77,16.74 Z" id="Shape"></path>
                                </g>
                            </g>
                        </svg>
                    </div>                                        
                    <div class="calendar-entry-subtitle">
                        <div class="event-time">10:00 AM-11:30 AM</div>  
                        <div class="event-type">Fantastic Fun</div>
                    </div>      
                </div>

                <div class="calender-entry time-1500 calendar-entry-pending" (click)="doAction('PartySelected', '2' )">
                    <div style="grid-area: icon" >
                        <svg width="17px"  viewBox="0 0 27 55" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <g id="bab-party-integration" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="Group-4" transform="translate(-40.000000, -36.000000)" fill="#FFFFFF" fill-rule="nonzero">
                                    <g id="Balloon-Copy-2" transform="translate(40.000000, 36.000000)">
                                        <path d="M16.2624,30.6309298 L18.48,33.9184061 L14.52,33.9184061 C14.124,40.9890892 13.2,48.0336812 11.88,55 L9.24,54.5825427 C10.56,47.7466793 11.4576,40.8586338 11.88,33.9184061 L7.92,33.9184061 L10.1376,30.6309298 C4.3296,28.152277 0,19.2030361 0,13.0455408 C0,5.84068756 5.9098413,0 13.2,0 C16.7008577,0 20.0583293,1.37443696 22.5338095,3.82095044 C25.0092897,6.26746391 26.4,9.58564826 26.4,13.0455408 C26.4,19.2030361 22.0704,28.152277 16.2624,30.6309298 Z" id="Path"></path>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div>
                    <div style="grid-area: title" class="calendar-entry-title" >
                        Alex's 8th Bday
                    </div>   
                    <div class="calendar-entry-status" >
                        <svg width="30px"  viewBox="0 0 50 56" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <g id="bab-party-integration" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="Group" fill="#FFFFFF" fill-rule="nonzero">
                                    <path d="M38.8888889,22.2222222 L11.1111111,22.2222222 L11.1111111,16.6666667 L38.8888889,16.6666667 M38.8888889,33.3333333 L11.1111111,33.3333333 L11.1111111,27.7777778 L38.8888889,27.7777778 M30.5555556,44.4444444 L11.1111111,44.4444444 L11.1111111,38.8888889 L30.5555556,38.8888889 M25,5.55555556 C26.5341243,5.55555556 27.7777778,6.79920903 27.7777778,8.33333333 C27.7777778,9.86745764 26.5341243,11.1111111 25,11.1111111 C23.4658757,11.1111111 22.2222222,9.86745764 22.2222222,8.33333333 C22.2222222,6.79920903 23.4658757,5.55555556 25,5.55555556 M44.4444444,5.55555556 L32.8333333,5.55555556 C31.6666667,2.33333333 28.6111111,0 25,0 C21.3888889,0 18.3333333,2.33333333 17.1666667,5.55555556 L5.55555556,5.55555556 C2.48730695,5.55555556 0,8.0428625 0,11.1111111 L0,50 C0,53.0682486 2.48730695,55.5555556 5.55555556,55.5555556 L44.4444444,55.5555556 C47.5126931,55.5555556 50,53.0682486 50,50 L50,11.1111111 C50,8.0428625 47.5126931,5.55555556 44.4444444,5.55555556 Z" id="Shape"></path>
                                </g>
                            </g>
                        </svg>
                    </div>                                        
                    <div class="calendar-entry-subtitle">
                        <div class="event-time">3:00 PM-4:30 PM</div>  
                        <div class="event-type event-type-pending">Silver Celebration</div>
                    </div>      
                </div>                
            </div>
        </div>
    </div>
    <div class="right-column">
        <div class="tasks-container">
            <div class="tasks-header">
                <svg class="header-icon" width="25px" viewBox="0 0 54 60" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g id="bab-party-integration" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="Group-Copy-3" transform="translate(-0.000000, 0.000000)" fill="#FFFFFF" fill-rule="nonzero">
                            <path d="M42,24 L12,24 L12,18 L42,18 M42,36 L12,36 L12,30 L42,30 M33,48 L12,48 L12,42 L33,42 M27,6 C28.6568542,6 30,7.34314575 30,9 C30,10.6568542 28.6568542,12 27,12 C25.3431458,12 24,10.6568542 24,9 C24,7.34314575 25.3431458,6 27,6 M48,6 L35.46,6 C34.2,2.52 30.9,0 27,0 C23.1,0 19.8,2.52 18.54,6 L6,6 C2.6862915,6 0,8.6862915 0,12 L0,54 C0,57.3137085 2.6862915,60 6,60 L48,60 C51.3137085,60 54,57.3137085 54,54 L54,12 C54,8.6862915 51.3137085,6 48,6 Z" id="Shape"></path>
                        </g>
                    </g>
                </svg>                
                <div class="tasks-title">Tasks</div>
            </div>
            <div class="tasks-body">
                <div class="task-item">                        
                    <svg width="24px"  viewBox="0 0 44 44" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g id="bab-party-integration" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Group" fill="#0155A5" fill-rule="nonzero">
                                <path d="M33,24.2 L24.2,24.2 L24.2,33 L19.8,33 L19.8,24.2 L11,24.2 L11,19.8 L19.8,19.8 L19.8,11 L24.2,11 L24.2,19.8 L33,19.8 M22,0 C9.8497355,0 0,9.8497355 0,22 C0,27.8347628 2.31785049,33.4305489 6.44365081,37.5563492 C10.5694511,41.6821495 16.1652372,44 22,44 C27.8347628,44 33.4305489,41.6821495 37.5563492,37.5563492 C41.6821495,33.4305489 44,27.8347628 44,22 C44,16.1652372 41.6821495,10.5694511 37.5563492,6.44365081 C33.4305489,2.31785049 27.8347628,0 22,0 Z" id="Shape"></path>
                            </g>
                        </g>
                    </svg>
                    <div class="task-item-text" (click)="doAction('NewParty' )">New Party</div>
                </div>
                <div class="task-item">                     
                    <svg width="24px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g id="bab-party-integration" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Group" fill="#0055A8" fill-rule="nonzero">
                                <path d="M14.8571429,0 C23.0625163,0 29.7142857,6.65176943 29.7142857,14.8571429 C29.7142857,18.5371429 28.3657143,21.92 26.1485714,24.5257143 L26.7657143,25.1428571 L28.5714286,25.1428571 L40,36.5714286 L36.5714286,40 L25.1428571,28.5714286 L25.1428571,26.7657143 L24.5257143,26.1485714 C21.92,28.3657143 18.5371429,29.7142857 14.8571429,29.7142857 C6.65176943,29.7142857 0,23.0625163 0,14.8571429 C0,6.65176943 6.65176943,0 14.8571429,0 M14.8571429,4.57142857 C9.14285714,4.57142857 4.57142857,9.14285714 4.57142857,14.8571429 C4.57142857,20.5714286 9.14285714,25.1428571 14.8571429,25.1428571 C20.5714286,25.1428571 25.1428571,20.5714286 25.1428571,14.8571429 C25.1428571,9.14285714 20.5714286,4.57142857 14.8571429,4.57142857 Z" id="Shape"></path>
                            </g>
                        </g>
                    </svg>
                    <div class="task-item-text" (click)="doAction('SearchParty' )">Search for a Party</div>
                </div>
                <div class="task-item">
                    <svg width="24px" viewBox="0 0 45 50" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g id="bab-party-integration" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Group-Copy-4" fill="#0055A8" fill-rule="nonzero">
                                <path d="M35,20 L10,20 L10,15 L35,15 M35,30 L10,30 L10,25 L35,25 M27,40 L10,40 L10,35 L27,35 M22.5,5 C23.8807119,5 25,6.11928813 25,7.5 C25,8.88071187 23.8807119,10 22.5,10 C21.1192881,10 20,8.88071187 20,7.5 C20,6.11928813 21.1192881,5 22.5,5 M40,5 L29.55,5 C28.5,2.1 25.75,0 22.5,0 C19.25,0 16.5,2.1 15.45,5 L5,5 C2.23857625,5 0,7.23857625 0,10 L0,45 C0,47.7614237 2.23857625,50 5,50 L40,50 C42.7614237,50 45,47.7614237 45,45 L45,10 C45,7.23857625 42.7614237,5 40,5 Z" id="Shape"></path>
                            </g>
                        </g>
                    </svg>
                    <div class="task-item-text" (click)="doAction('PartySelected', '2' )">Prep for Alex's 8th Bday at 3:00 PM</div>
                </div>
            </div>            
        </div>
        <div class="coming-up-container">
            <div class="coming-up-header">
                <svg class="header-icon" width="25px" viewBox="0 0 50 57" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g id="bab-party-integration" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="Group-Copy-2" fill="#FFFFFF" fill-rule="nonzero">
                            <path d="M44.4444444,50.625 L5.55555556,50.625 L5.55555556,19.6875 L44.4444444,19.6875 M44.4444444,5.625 L41.6666667,5.625 L41.6666667,0 L36.1111111,0 L36.1111111,5.625 L13.8888889,5.625 L13.8888889,0 L8.33333333,0 L8.33333333,5.625 L5.55555556,5.625 C2.47222222,5.625 0,8.15625 0,11.25 L0,50.625 C0,53.7316017 2.48730695,56.25 5.55555556,56.25 L44.4444444,56.25 C47.5126931,56.25 50,53.7316017 50,50.625 L50,11.25 C50,8.14339828 47.5126931,5.625 44.4444444,5.625" id="Shape"></path>
                        </g>
                    </g>
                </svg>                              
                <div class="coming-up-title">Coming Up</div>
            </div>

            <div class="coming-up-body">
               <div class="coming-up-item">
                   <div class="coming-up-item-icon">
                        <svg width="25px"  viewBox="0 0 35 74" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <g id="bab-party-integration" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="Balloon-Copy-3" fill="#CBCBCB" fill-rule="nonzero">
                                    <path d="M21.56,41.1035599 L24.5,45.5150153 L19.25,45.5150153 C18.725,55.0031454 17.5,64.4562639 15.75,73.8043478 L12.25,73.244163 C14,64.0711369 15.19,54.8280876 15.75,45.5150153 L10.5,45.5150153 L13.44,41.1035599 C5.74,37.7774627 0,25.7685009 0,17.5057751 C0,7.83760248 7.83501688,0 17.5,0 C22.1412886,0 26.592482,1.84435315 29.8743687,5.12732282 C33.1562553,8.41029248 35,12.8629549 35,17.5057751 C35,25.7685009 29.26,37.7774627 21.56,41.1035599 Z" id="Path"></path>
                                </g>
                            </g>
                        </svg>
                   </div>
                   <div class="coming-up-item-title">Emily's Party</div>
                   <div class="coming-up-item-subtitle">Tomorrow at 11:00 AM </div>
                </div>
                <div class="coming-up-item">
                    <div class="coming-up-item-icon">

                        <svg width="30px" viewBox="0 0 50 61" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <g id="bab-party-integration" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <path d="M24.5833333,16.6666667 C27.5895833,16.6666667 30,14.1666667 30,11.1111111 C30,10.0555556 29.7291667,9.08333333 29.2145833,8.25 L24.5833333,0 L19.9520833,8.25 C19.4375,9.08333333 19.1666667,10.0555556 19.1666667,11.1111111 C19.1666667,14.1793597 21.5917909,16.6666667 24.5833333,16.6666667 M37.7777778,44.5903955 L34.8055556,41.6666667 L31.8055556,44.5903955 C28.1944444,48.0826271 21.8611111,48.1096987 18.2222222,44.5903955 L15.2777778,41.6666667 L12.2222222,44.5903955 C10.4166667,46.3229755 8,47.2975518 5.44444444,47.2975518 C3.41666667,47.2975518 1.55555556,46.6749058 0,45.6461864 L0,58.126177 C0,59.6212982 1.24365347,60.8333333 2.77777778,60.8333333 L47.2222222,60.8333333 C48.7563465,60.8333333 50,59.6212982 50,58.126177 L50,45.6461864 C48.4444444,46.6749058 46.5833333,47.2975518 44.5555556,47.2975518 C42,47.2975518 39.5833333,46.3229755 37.7777778,44.5903955 M41.6666667,24.6568627 L27.7777778,24.6568627 L27.7777778,19.1666667 L22.2222222,19.1666667 L22.2222222,24.6568627 L8.33333333,24.6568627 C3.73096042,24.6568627 0,28.3439295 0,32.8921569 L0,37.1196078 C0,40.0843137 2.44444444,42.5 5.44444444,42.5 C6.94444444,42.5 8.33333333,41.9509804 9.27777778,40.9352941 L15.2777778,35.0882353 L21.1388889,40.9352941 C23.1944444,42.9666667 26.7777778,42.9666667 28.8333333,40.9352941 L34.7222222,35.0882353 L40.6944444,40.9352941 C41.6666667,41.9509804 43.0555556,42.5 44.5277778,42.5 C47.5277778,42.5 50,40.0843137 50,37.1196078 L50,32.8921569 C50,28.3439295 46.2690396,24.6568627 41.6666667,24.6568627 Z" id="Shape" fill="#CBCBCB" fill-rule="nonzero"></path>
                            </g>
                        </svg>

                    </div>
                    <div class="coming-up-item-title">Roger's Birthday</div>
                    <div class="coming-up-item-subtitle">4/2/2022 at 2:00 PM</div>
                 </div>   
                 <div class="coming-up-item">
                    <div class="coming-up-item-icon">

                        <svg width="30px" viewBox="0 0 50 61" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <g id="bab-party-integration" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <path d="M24.5833333,16.6666667 C27.5895833,16.6666667 30,14.1666667 30,11.1111111 C30,10.0555556 29.7291667,9.08333333 29.2145833,8.25 L24.5833333,0 L19.9520833,8.25 C19.4375,9.08333333 19.1666667,10.0555556 19.1666667,11.1111111 C19.1666667,14.1793597 21.5917909,16.6666667 24.5833333,16.6666667 M37.7777778,44.5903955 L34.8055556,41.6666667 L31.8055556,44.5903955 C28.1944444,48.0826271 21.8611111,48.1096987 18.2222222,44.5903955 L15.2777778,41.6666667 L12.2222222,44.5903955 C10.4166667,46.3229755 8,47.2975518 5.44444444,47.2975518 C3.41666667,47.2975518 1.55555556,46.6749058 0,45.6461864 L0,58.126177 C0,59.6212982 1.24365347,60.8333333 2.77777778,60.8333333 L47.2222222,60.8333333 C48.7563465,60.8333333 50,59.6212982 50,58.126177 L50,45.6461864 C48.4444444,46.6749058 46.5833333,47.2975518 44.5555556,47.2975518 C42,47.2975518 39.5833333,46.3229755 37.7777778,44.5903955 M41.6666667,24.6568627 L27.7777778,24.6568627 L27.7777778,19.1666667 L22.2222222,19.1666667 L22.2222222,24.6568627 L8.33333333,24.6568627 C3.73096042,24.6568627 0,28.3439295 0,32.8921569 L0,37.1196078 C0,40.0843137 2.44444444,42.5 5.44444444,42.5 C6.94444444,42.5 8.33333333,41.9509804 9.27777778,40.9352941 L15.2777778,35.0882353 L21.1388889,40.9352941 C23.1944444,42.9666667 26.7777778,42.9666667 28.8333333,40.9352941 L34.7222222,35.0882353 L40.6944444,40.9352941 C41.6666667,41.9509804 43.0555556,42.5 44.5277778,42.5 C47.5277778,42.5 50,40.0843137 50,37.1196078 L50,32.8921569 C50,28.3439295 46.2690396,24.6568627 41.6666667,24.6568627 Z" id="Shape" fill="#CBCBCB" fill-rule="nonzero"></path>
                            </g>
                        </svg>

                    </div>
                    <div class="coming-up-item-title">Scout Troop 4430</div>
                    <div class="coming-up-item-subtitle">4/3/2022 at 10:00 AM</div>
                 </div>                                 
            </div>                
        </div>    
    </div>
</div>
</app-bacon-strip>


