<app-bacon-strip></app-bacon-strip>
<main responsive-class>
    <app-scan-or-search class="mat-elevation-z2"></app-scan-or-search>
    <app-instructions *ngIf="screen.instructions" class="text-center" [instructions]="screen.instructions" [instructionsSize]="'text-md'"></app-instructions>
    <app-infinite-scroll
        [dataKey]="screen.resultsDataKey"
        [itemTemplate]="itemTemplate"
        [noItemsTemplate]="noItemTemplate"
        virtualScrollMinBufferPx="200"
        virtualScrollMaxBufferPx="400"
        [itemHeightPx]=" (isMobile | async) ? 150 : 50">
        <ng-template #itemTemplate let-user>
            <section class="item-template">
                <div responsive-class class="name">{{user.name}}</div>
                <ul responsive-class class="properties">
                    <li *ngFor="let prop of user.properties" class="muted-color">
                        {{prop.label}}: {{prop.value | valueFormatter: prop.valueFormatter}}
                    </li>
                </ul>
                <div responsive-class class="actions primary buttons-wrapper">
                    <button  mat-button *ngFor="let button of user.userActions" (click)="doAction(button, user.index)">
                        <span [ngClass]="{'muted-color': ! button.enabled}" class="button-title">{{button.title}}</span>
                        <app-icon *ngIf="button.icon" [ngClass]="{'muted-color': ! button.enabled}" [iconName]="button.icon"></app-icon>
                    </button>
                </div>
                <div responsive-class class="workgroup">{{user.workgroup}}</div>
            </section>
        </ng-template>
        <ng-template #noItemTemplate>
            <section class="no-item-template">
                <app-instructions *ngIf="screen.noResultsMessage" class="message" [instructions]="screen.noResultsMessage"></app-instructions>
            </section>
        </ng-template>
    </app-infinite-scroll>
    <app-secondary-button *ngIf="screen.addUserAction" class="button" [disabled]="!screen.addUserAction.enabled" (buttonClick)="doAction(screen.addUserAction)">
        <span>{{screen.addUserAction.title}}</span>
    </app-secondary-button>
</main>
