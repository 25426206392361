import { Component } from '@angular/core';
import { ActivationEnd, ActivationStart, Router } from '@angular/router';
import { PersonalizationService, LocaleService,
    LocationService, OpenposMediaService,
    LockScreenService, PrinterService,
    StatusService, VERSIONS, FailoverService } from '@jumpmind/openpos-client-core-lib';
import { BlueFletchAuthProvider } from './auth-providers/blue-fletch-auth-provider';
import { COMMERCE_VERSION } from '../commerce-version';

import { filter } from 'rxjs/operators';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'app-root',
    templateUrl: './app.component.html',
    styles: [
        `
        nu-splash-screen {
            position: fixed;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
        }
        `
    ]
})
export class AppComponent {
    isLoading = true;

    constructor(
        private router: Router,
        protected personalization: PersonalizationService,
        protected localeService: LocaleService,
        protected locationService: LocationService,
        protected mediaService: OpenposMediaService,
        protected blueFletchAuthProvider: BlueFletchAuthProvider,
        protected lockScreenService: LockScreenService,
        protected printService: PrinterService,
        protected statusService: StatusService,
        protected failoverService: FailoverService
    ) {
        router.events.pipe(
            filter(e => e instanceof ActivationStart)
        ).subscribe(() => {
            this.isLoading = true;
        });
        router.events.pipe(
            filter(e => e instanceof ActivationEnd)
        ).subscribe(() => {
            this.isLoading = false;
        });
        VERSIONS.push(COMMERCE_VERSION);
    }
}
