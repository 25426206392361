import { Component, Input } from '@angular/core';
import { IOrderPackage } from '../../interfaces/order-package.interface';

@Component({
    selector: 'nu-order-package-header',
    templateUrl: './order-package-header.component.html',
    styleUrls: ['./order-package-header.component.scss']
})
export class OrderPackageHeaderComponent {
    @Input()
    package: IOrderPackage;
}
