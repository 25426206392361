import { ReportsDashboardInterface } from './reports-dashboard.interface';
import {
    IDynamicFormPartEventArg,
    IForm,
    OpenposMediaService,
    PosScreenDirective,
    PrinterService,
    ScreenComponent,
    slideLeftAnimationTrigger,
    slideLeftRightAnimationTrigger,
    slideUpDownAnimationTrigger,
    slideDownUpAnimationTrigger
} from '@jumpmind/openpos-client-core-lib';
import { ChangeDetectorRef, Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AnimationEvent } from '@angular/animations';

export enum ReportScreenStep {
    selectReport = 0, filters = 1, reportResults = 2
}

@ScreenComponent({
    name: 'ReportsDashboard'
})
@Component({
    selector: 'nu-reports-dashboard',
    templateUrl: './reports-dashboard.component.html',
    styleUrls: ['./reports-dashboard.component.scss'],
    animations: [
        slideLeftAnimationTrigger,
        slideLeftRightAnimationTrigger,
        slideUpDownAnimationTrigger,
        slideDownUpAnimationTrigger
    ]
})
export class ReportsDashboardComponent extends PosScreenDirective<ReportsDashboardInterface> implements OnInit {
    @ViewChild('content', { static: false }) content: ElementRef;

    isMobile$: Observable<boolean>;
    isParamsFormInvalid = true;
    selectedReport: string;
    selectedReportName: string;
    paramsForm: IForm;
    paramsPanelVisible = false;
    helpSelectReportVisible = true;
    helpApplyFiltersVisible = false;
    mobileStep: ReportScreenStep = ReportScreenStep.selectReport;
    isMobile = false;

    constructor(
        injector: Injector,
        private printerService: PrinterService,
        private media: OpenposMediaService,
        private changeDetectorRef: ChangeDetectorRef) {
        super(injector);
    }

    buildScreen(): void {
    }

    mobileStepBack() {
        this.mobileStep -= 1;
    }

    ngOnInit(): void {
        const mobileMap = new Map([
            ['xs', true],
            ['sm', true],
            ['md', false],
            ['lg', false],
            ['xl', false]
        ]);
        this.isMobile$ = this.media.mediaObservableFromMap(mobileMap).pipe(takeUntil(this.destroyed$));
        this.helpSelectReportVisible = !this.screen.renderedReport && !this.selectedReport;
        this.helpApplyFiltersVisible = !this.screen.renderedReport && !!this.selectedReport;
        this.isMobile$.subscribe(res => {
            this.isMobile = res;
        });
    }

    selectReport(name: string): void {
        loopCategories:
        for (const category of this.screen.categories) {
            for (const categoryActionButton of category.buttons) {
                if (categoryActionButton.defaultPayload === name) {
                    this.selectedReportName = categoryActionButton.title;
                    break loopCategories;
                }
            }
        }
        this.selectedReport = name;
        this.doAction('SelectReport', name);
        this.helpSelectReportVisible = false;
        this.mobileStep = ReportScreenStep.filters;
    }

    printReport(): void {
        this.printerService.print(this.screen.renderedReport).subscribe();
    }

    onFilterFormInit(event: IDynamicFormPartEventArg): void {
        this.isParamsFormInvalid = event.formGroup.invalid;
        this.paramsForm = event.form;
    }

    onFilterFormChanges(event: IDynamicFormPartEventArg): void {
        this.onFilterFormInit(event);

        // Not sure why this is needed to prevent the error: ExpressionChangedAfterItHasBeenCheckedError:
        this.changeDetectorRef.detectChanges();
    }

    doDoneAction() {
        this.doAction(this.screen.doneButton.action);
    }

    runReport(): void {
        if (this.isParamsFormInvalid) {
            return;
        }

        this.doAction('RunReport', {
            name: this.selectedReport,
            paramsForm: this.paramsForm,
            nonInputFormElements: this.screen.nonInputFormElements,
            paramDataTypes: this.screen.paramDataTypes
        });
    }

    onHelpSelectReportAnimationDone(event: AnimationEvent): void {
        if (event.toState === 'void') {
            this.helpApplyFiltersVisible = true;
        }
    }

    onHelpApplyFiltersAnimationDone(event: AnimationEvent): void {
        if (event.toState === null) {
            this.paramsPanelVisible = true;
        }
    }

    onReportSlideAnimationDone(event: AnimationEvent): void {
        if (event.toState === 'void') {
            if (this.content?.nativeElement) {
                this.content.nativeElement.scrollTop = 0;
            }
            this.helpApplyFiltersVisible = true;
        }
    }

    onParamsPanelSlideAnimationDone(event: AnimationEvent): void {
        if (event.toState === null) {
            this.helpApplyFiltersVisible = true;
        } else if (event.toState === 'void') {
            this.runReport();
        }
    }

    onRunReportClick(): void {
        this.paramsPanelVisible = false;
        this.helpApplyFiltersVisible = false;
        this.mobileStep = ReportScreenStep.reportResults;
        this.runReport();
    }
}

