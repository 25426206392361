<div class="till-count-outer">
    <app-dialog-header></app-dialog-header>
    <div class="till-count-body">
        <app-instructions class="till-count-header" [instructions]="screen.instructions" [instructionsSize]="'text-sm'"></app-instructions>
        <app-content-card>
            <div class="till-sections">
                <nu-till-section *ngFor="let billSection of screen.billSections" [section]="billSection" (sectionValueChanged)="updateTotals($event)"></nu-till-section>
                <nu-till-section *ngFor="let coinSection of screen.coinSections" [section]="coinSection" (sectionValueChanged)="updateTotals($event)"></nu-till-section>
            </div>
        </app-content-card>
        <div class="footer">
            <div responsive-class class="footer-details">
                <div class="label">{{countedLabel}}</div>
                <b class="amount"><app-currency-text [amountText]="getCountedTotal()"></app-currency-text></b>
            </div>
            <div *ngIf="screen.showExpected" responsive-class class="footer-details">
                <div class="label">{{expectedLabel}}</div>
                <b class="amount"><app-currency-text [amountText]="screen.expectedAmount"></app-currency-text></b>
            </div>
            <div *ngIf="screen.showExpected" responsive-class class="footer-details">
                <div class="label">{{detailedCountDiscrepancyLabel}}</div>
                <b class="amount"><app-currency-text [amountText]="getDifference()"></app-currency-text></b>
            </div>
            <mat-dialog-actions>
                <app-primary-button class="continue-button" 
                (buttonClick)="continue()">
                <span>{{screen.continueButton.title}}</span>
                </app-primary-button>
            </mat-dialog-actions>
        </div>
    </div>
</div>
