import { Component, Input, OnInit } from '@angular/core';
import { getHourMinSeconds, getFormattedTime, TimeUnitLabels } from '@jumpmind/openpos-client-core-lib';

@Component({
    selector: 'nu-order-due',
    templateUrl: './order-due.component.html',
    styleUrls: ['order-due.component.scss']
})
export class OrderDueComponent implements OnInit {

    @Input()
    iconName: string;

    @Input()
    label: string;

    @Input()
    dueDate: number;

    @Input()
    showDate: boolean;

    @Input()
    timeLabels = new TimeUnitLabels('hour', 'hours', 'minute', 'minutes', 'second', 'seconds', 'Now');

    messageFormat = ' in HH mm';
    overdueMessageFormat = ' HH mm ago';

    overdue: boolean;
    showInHours: boolean;
    message: string;

    ngOnInit(): void {
        this.checkDueDate();
        setInterval(() => this.checkDueDate(), 30000);
    }

    private checkDueDate() {
        this.showInHours = !this.showDate;
        const timeTillDueDate =  new Date(this.dueDate).getTime() - Date.now();
        this.overdue = timeTillDueDate < 0;
        const time = getHourMinSeconds(Math.abs(timeTillDueDate) / 1000);

        let chosenFormat = this.messageFormat;
        if (this.overdue) {
            chosenFormat = this.overdueMessageFormat;
        }

        if (time.hours > 24) {
            this.showInHours = false;
        }

        this.message = getFormattedTime(chosenFormat, time, this.timeLabels);
    }

}
