
import { Component, Injector } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { PosScreenDirective, ScreenComponent, UIDataMessageService } from '@jumpmind/openpos-client-core-lib';
import { Observable } from 'rxjs';
import { InstallGroupDetails, InstallGroupMember, ScheduledUpdate } from './install-group-details.interface';

@ScreenComponent({
    name: 'InstallGroupDetails'
})
@Component({
    templateUrl: 'install-group-details.component.html',
    styleUrls: ['install-group-details.component.scss']
})

export class InstallGroupDetailsComponent extends PosScreenDirective<InstallGroupDetails> {
    readonly updateColumns = ['icon', 'when', 'version', 'actions'];
    readonly storeColumns = ['checkbox', 'id', 'name'];

    readonly memberSelections = new Map<string, boolean>();

    memberData = new MatTableDataSource<InstallGroupMember>();

    scheduledUpdates$?: Observable<ScheduledUpdate[]>;
    members$?: Observable<InstallGroupMember[]>;

    membersFilter = '';

    constructor(injector: Injector, private _dataProvider: UIDataMessageService) {
        super(injector);

        this.memberData.filterPredicate = (data, filter) => (!!data.businessUnitId && data.businessUnitId.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
                || (!!data.businessUnitName && data.businessUnitName.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) >= 0);
    }

    buildScreen(): void {
        this.memberSelections.clear();

        if (this.screen.scheduledUpdatesProviderKey) {
            this.scheduledUpdates$ = this._dataProvider.getData$(this.screen.scheduledUpdatesProviderKey);
        }

        if (this.screen.membersListProviderKey) {
            this._dataProvider.getData$(this.screen.membersListProviderKey).subscribe((value) => {
                this.memberData.data = value;
            });
        }
    }

    get hasMemberChanges(): boolean {
        return this.memberSelections.size > 0;
    }

    onBusinessUnitChanged(model: InstallGroupMember, newValue: boolean) {
        if (this.memberSelections.has(model.businessUnitId)) {
            model.member = newValue;

            // if it was toggled on and back off, remove it from the change list
            this.memberSelections.delete(model.businessUnitId);
        } else {
            model.member = newValue;
            this.memberSelections.set(model.businessUnitId, newValue);
        }
    }

    saveMemberChanges() {
        if (!this.screen.saveMemberChangesAction) {
            return;
        }

        const values = [];

        this.memberSelections.forEach((value, key) => {
            values.push({ businessUnitId: key, member: value });
        });

        this.doAction(this.screen.saveMemberChangesAction, values);
    }

    onFilterKeyUp(event: KeyboardEvent) {
        if (event.key === 'Escape') {
            this.memberData.filter = '';
        }
    }
}
