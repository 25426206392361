import { TillCountInterface } from './till-count.interface';
import { PosScreenDirective, ScreenComponent } from '@jumpmind/openpos-client-core-lib';
import { Component } from '@angular/core';
import { ITillSection } from '../components/till-section/till-section.interface';

@ScreenComponent({
    name: 'TillCount'
})
@Component({
    selector: 'nu-till-count',
    templateUrl: './till-count.component.html',
    styleUrls: ['./till-count.component.scss']
})
export class TillCountComponent extends PosScreenDirective<TillCountInterface> {

    countedTotal = 0;
    detailedCountDiscrepancyLabel: string;
    expectedLabel: string;
    countedLabel: string;

    buildScreen() {
        this.detailedCountDiscrepancyLabel = this.screen.detailedCountDiscrepancyCorrectLabel;
        this.expectedLabel = this.screen.expectedLabel;
        this.countedLabel = this.screen.countedLabel;
    }

    continue() {
        this.doAction(this.screen.continueButton.action, this.countedTotal);
    }

    updateTotals($event: ITillSection) {
        this.screen.billSections.forEach(section => {
            if (section.label === $event.label) {
                section.count = $event.count;
                return;
            }
        });
        this.screen.coinSections.forEach(section => {
            if (section.label === $event.label) {
                section.count = $event.count;
                return;
            }
        });
        this.updateCount();
    }

    updateCount() {
        this.countedTotal = 0;
        this.screen.billSections.forEach(section => {
            this.countedTotal += (section.moneyValue * section.count * 100);
        });
        this.screen.coinSections.forEach(section => {
            this.countedTotal += (section.moneyValue * section.count * 100);
        });

        this.countedTotal = this.countedTotal / 100;
    }

    getCountedTotal(): string {
        return '' + this.countedTotal;
    }

    getDifference(): string {
        const diff = this.screen.expectedAmount - this.countedTotal;

        return '' + this.setDiscrepancyText(+diff);
    }

    setDiscrepancyText(diff: number): string {
        if (diff === 0) {
            this.detailedCountDiscrepancyLabel = this.screen.detailedCountDiscrepancyCorrectLabel;
            // Show nothing instead of $0.00
            return '';
        } else if (diff > 0) {
            this.detailedCountDiscrepancyLabel = this.screen.detailedCountDiscrepancyUnderLabel;
            return diff.toString();
        } else {
            this.detailedCountDiscrepancyLabel = this.screen.detailedCountDiscrepancyOverLabel;
            return diff.toString();
        }

    }

}
