import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { OpenposAppComponent } from '@jumpmind/openpos-client-core-lib';

const routes: Routes = [
    { path: '**', component: OpenposAppComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { enableTracing: true, useHash: true, relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
