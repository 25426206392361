import { ScreenComponent, PosScreenDirective } from '@jumpmind/openpos-client-core-lib';
import { Component } from '@angular/core';
import { StoreCycleInterface } from './store-cycle.interface';

@ScreenComponent({
    name: 'StoreCycle'
})
@Component({
    selector: 'nu-store-cycle',
    templateUrl: './store-cycle.component.html',
    styleUrls: ['./store-cycle.component.scss']
})
export class StoreCycleComponent extends PosScreenDirective<StoreCycleInterface> {
    buildScreen() {
    }

    isStoreButtonPrimary(): boolean {
        return !this.screen.storeOpen;
    }

    isDeviceButtonPrimary(): boolean {
        return this.screen.storeOpen && !this.screen.deviceOpen;
    }

    isTillButtonPrimary(): boolean {
        return this.screen.storeOpen && this.screen.deviceOpen;
    }
}
