<div *ngIf="screen.itemNotFoundMessage"
class="container"
gdAreas="   notfound    notfound    notfound    notfound    notfound    |
            help        help        help        help        help        |
            logo        .           .           .           ."
            gdColumns=" 1fr 1fr 1fr 1fr 1fr"
            gdGap="4px" >
    <div gdArea="notfound"
        class="not-found-message">
        {{screen.itemNotFoundMessage}}
    </div>
    <div gdArea="help"
        class="help-message">
        {{screen.helpMessage}}
    </div>
    <div gdArea="logo">
        <img  class="price-checker-logo" [src]="screen.logoUrl | imageUrl">
    </div>
</div>

<div *ngIf="!screen.itemNotFoundMessage"
        gdAreas="   title       title       title       title       image       |
                    props       props       .           .           image       |
                    .           pricelabel  pricelabel  pricelabel  image       |
                    price       price       price       price       price       |
                    logo        .           .           .           printButton |
                    logo        .           .           .           printButton |
                    logo        disclaimer  disclaimer  disclaimer  printButton"
        gdColumns=" 1fr 1fr 1fr 1fr 1fr"
        gdGap="4px"
        class="container">
    <div gdArea="title" class="title">{{screen.itemDescription}}</div>
    <div gdArea="props" class="props">
        <app-display-property *ngFor="let prop of screen.itemProperties" [property]="prop"></app-display-property>
    </div>
    <div gdArea="image" class="image-container">
        <img  class="item-image" [src]="screen.itemImageUrl | imageUrl">
    </div>
    <div gdArea="pricelabel" class="current-price-label">
        {{screen.currentPrice.label}}
    </div>
    <div gdArea="price" class="current-price">
        {{screen.currentPrice.value | valueFormatter:screen.currentPrice.valueFormatter}}
    </div>
    <div gdArea="logo" class="logo-container">
        <img  class="price-checker-logo" [src]="screen.logoUrl | imageUrl">
    </div>
    <div gdArea="disclaimer" class="disclaimer">
        {{screen.disclaimer}}
    </div>
    <button *ngIf="!!screen.printButton" mat-raised-button  gdArea="printButton" color=primary class="button" (click)="onPrint()">
        <app-icon [iconName]="screen.printButton.icon"></app-icon>
        <span>&nbsp;{{screen.printButton.title}}</span>
    </button>
</div>
<app-scan-part></app-scan-part>
