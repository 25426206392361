import { Component, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { ScreenComponent, PosScreenDirective, UIDataMessageService, OpenposMediaService, MediaBreakpoints } from '@jumpmind/openpos-client-core-lib';
import { ClientelingClientDashboardInterface } from './clienteling-client-dashboard.interface';
import { DiscoveryService } from '@jumpmind/openpos-client-core-lib';

@ScreenComponent({
    name: 'ClientelingClientDashboard'
})
@Component({
    selector: 'clienteling-client-dashboard',
    templateUrl: './clienteling-client-dashboard.component.html',
    styleUrls: ['./clienteling-client-dashboard.component.scss']
})
export class ClientelingClientDashboardComponent extends PosScreenDirective<ClientelingClientDashboardInterface>  {

    isMobile$: Observable<boolean>;
    selectedDeviceId: string;

    constructor(injector: Injector, private dataMessageService: UIDataMessageService,
        mediaService: OpenposMediaService, private discoveryService: DiscoveryService) {
        super(injector);
        this.isMobile$ = mediaService.observe(new Map([
            [MediaBreakpoints.MOBILE_PORTRAIT, true],
            [MediaBreakpoints.MOBILE_LANDSCAPE, true],
            [MediaBreakpoints.TABLET_PORTRAIT, false],
            [MediaBreakpoints.TABLET_LANDSCAPE, false],
            [MediaBreakpoints.DESKTOP_PORTRAIT, false],
            [MediaBreakpoints.DESKTOP_LANDSCAPE, false]
        ]));
    }

    doDebug() {
        this.doAction('ViewDevice');
    }

    viewDevice() { this.doAction('ViewDevice'); }
    viewSelectedDevice() { this.doAction('ViewSelectedDevice'); }



    public replaceTokens(value: string): string {
        if (!value) {
            return value;
        }

        let BASE_URL_TOKEN = '${apiServerBaseUrl}';

        const apiServerBaseUrl = this.discoveryService.getApiServerBaseURL();

        let url = value.replace(BASE_URL_TOKEN, apiServerBaseUrl);
        url = url.replace("/api", "");
        return url;
    }

    buildScreen() {

        var screenNotTypeSafe = this.screen as any;

        const allItems = new Map<number, any>();
        const allDisabledItems = new Map<number, any>();

        for (let i = 0; i < screenNotTypeSafe.data.clientelingFeedItems.length; i++) {
            const item = screenNotTypeSafe.data.clientelingFeedItems[i];
            allItems.set(i, item);
        }

    }

    onSuggestionItemClick(item: any, event: any): void {
        this.doAction("SuggestedItemClicked", item.itemId);
    }    


}
