<app-bacon-strip class="bacon-strip" responsive-class>
    <div class="manage-tills-body">
        <app-instructions [instructions]="screen.instructions"></app-instructions>
        <app-content-card>
            <ul>
                <li responsive-class *ngFor="let till of screen.tills">
                    <div class="till-item">
                        <app-icon [iconName]="'Till'" [iconClass]="'xl primary'"></app-icon>
                        <div class="till-header">
                            <p><b>{{till.tillIdLabel}}{{till.tillId}}</b></p>
                            <div class="till-header-status">
                                <p>{{till.tillStatusLabel}}{{till.tillStatus}}</p>
                                <app-icon [iconName]="till.tillStatusIcon"></app-icon>
                            </div>
                        </div>
                        <div class="till-details">
                            <p *ngFor="let detail of till.tillDetails"> {{detail}}</p>
                        </div>
                        <div class="buttons">
                            <app-secondary-button *ngIf="till.additionalAction" class="button" [disabled]="!till.additionalAction.enabled" (buttonClick)="doAction(till.additionalAction, till.tillId)">
                                <span>{{till.additionalAction.title}}</span>
                            </app-secondary-button>
                            <app-secondary-button class="button" [disabled]="!till.action.enabled" (buttonClick)="doAction(till.action, till.tillId)">
                                <span>{{till.action.title}}</span>
                            </app-secondary-button>
                        </div>
                    </div>
                </li>
            </ul>
        </app-content-card>
    </div>
</app-bacon-strip>