import { Component, Injector } from '@angular/core';
import { IActionItem, OpenposMediaService, PosScreenDirective, ScreenComponent } from '@jumpmind/openpos-client-core-lib';
import { OrderListInterface } from './order-list.interface';
import { Observable, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { IOrderStatusSection } from '../components/order-status-section/order-status-section.interface';

@ScreenComponent({ name: 'OrderList' })
@Component({
    selector: 'nu-order-list',
    templateUrl: './order-list.component.html',
    styleUrls: ['./order-list.component.scss']
})
export class OrderListComponent extends PosScreenDirective<OrderListInterface> {

    isMobile$: Observable<boolean>;
    $searchText = new Subject<string>();

    public statusSelectedVal: string;
    public deliveryMethodSelectedVal: string;
    public allOrdersFilterSelectedVal: string;
    public actions: IActionItem[];

    constructor(injector: Injector, media: OpenposMediaService) {
        super(injector);
        this.isMobile$ = media.mediaObservableFromMap(new Map([
            ['xs', true],
            ['sm', true],
            ['md', false],
            ['lg', false],
            ['xl', false]
        ]));
        this.subscriptions.add(this.$searchText.pipe(debounceTime(1000)).subscribe(searchText => {
            if (this.screen) {
                this.screen.searchText = searchText;
                this.doAction(this.screen.search, searchText);
            }
        }));
    }

    buildScreen(): void {
        if (this.screen.deliveryMethodFilter.selectedAction || this.screen.statusFilter.selectedAction) {
            this.deliveryMethodSelectedVal = this.screen.deliveryMethodFilter.selectedAction;
            this.statusSelectedVal = this.screen.statusFilter.selectedAction;
        } else {
            this.allOrdersFilterSelectedVal = this.screen.allOrdersFilter.action;
        }
    }

    public onAllOrdersFilterSelected(val: string): void {
        this.allOrdersFilterSelectedVal = val;
        this.statusSelectedVal = null;
        this.deliveryMethodSelectedVal = null;
        this.doAction(val);
    }

    public onStatusValChange(val: string): void {
        this.statusSelectedVal = val;
        this.allOrdersFilterSelectedVal = null;
    }

    public onDeliveryMethodValChanged(val: string): void {
        this.deliveryMethodSelectedVal = val;
        this.allOrdersFilterSelectedVal = null;
        this.doAction(val);
    }

    public getStatusSectionOrientation(): string {
        return this.isFilteredOnStatus() ? 'vertical' : 'horizontal';
    }

    public getFilteredVisibilityClass(section: IOrderStatusSection): string {
        if (this.isFilteredOnStatus()) {
            return this.shouldDisplaySection(section) ? 'vertical filtered-visible' : 'vertical filtered-not-visible';
        }
        return this.getStatusSectionOrientation();
    }

    private shouldDisplaySection(section: IOrderStatusSection): boolean {
        return !this.isFilteredOnStatus() ||
            (this.isFilteredOnStatus() && section.filterAction && section.filterAction.action === this.statusSelectedVal);
    }

    private isFilteredOnStatus(): boolean {
        return !!this.statusSelectedVal;
    }

}
