import { ChangeDetectorRef, Component, Injector, TemplateRef, ViewChild } from '@angular/core';
import {
    IActionItem, IDynamicFormPartEventArg, IForm, ISellItem,
    PosScreenDirective, ScreenComponent, slideLeftAnimationTrigger, UIDataMessageService
} from '@jumpmind/openpos-client-core-lib';
import { Observable } from 'rxjs';
import { CentralPriceCheckerInterface } from './central-price-checker.interface';

@ScreenComponent({
    name: 'CentralPriceChecker'
})
@Component({
    selector: 'nu-central-price-checker',
    templateUrl: './central-price-checker.component.html',
    styleUrls: ['./central-price-checker.component.scss'],
    animations: [
        slideLeftAnimationTrigger
    ]
})
export class CentralPriceCheckerComponent extends PosScreenDirective<CentralPriceCheckerInterface> {

    @ViewChild('viewMessage') viewMessageTemplate: TemplateRef<any>;
    @ViewChild('viewSale') viewSaleTemplate: TemplateRef<any>;

    items: Observable<ISellItem[]>;
    isParamsFormInvalid = true;
    paramsForm: IForm;
    isEmpty = true;

    constructor(injector: Injector,
        private dataMessageService: UIDataMessageService,
        private changeDetectorRef: ChangeDetectorRef) {
        super(injector);
    }


    buildScreen(): void {
        this.items = this.dataMessageService.getData$(this.screen.providerKey);
    }

    public getContentView(): TemplateRef<any> {
        if (this.screen.hasTransaction) {
            return this.viewSaleTemplate;
        }
        return this.viewMessageTemplate;
    }

    onFilterFormInit(event: IDynamicFormPartEventArg): void {
        this.isParamsFormInvalid = event.formGroup.invalid;
        this.paramsForm = event.form;
    }

    onFilterFormChanges(event: IDynamicFormPartEventArg): void {
        this.onFilterFormInit(event);
        // Not sure why this is needed to prevent the error: ExpressionChangedAfterItHasBeenCheckedError:
        this.changeDetectorRef.detectChanges();
    }

    onApplyParams(action: IActionItem) {
        if (this.isParamsFormInvalid) {
            return;
        }
        this.doAction(action, this.paramsForm);
    }

    onItemsChanged(items: ISellItem[]): void {
        this.isEmpty = items.length === 0;
        // Angular gets angry if you update the current view when a child property changes. This prevents the error:
        // ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked.
        this.changeDetectorRef.detectChanges();
        if (this.screen.hasTransaction) {
            setTimeout(() => {
                (document.querySelector('#scan-or-search input') as HTMLElement).focus();
            });
        }
    }
}
