<app-bacon-strip class="order-bacon-strip" [searchEnabled]="true">
    <div responsive-class class="body-container">
        <div *ngIf="!(isMobile$ | async)" class="header">
            <nu-order-header-label 
                [iconName]="screen.headerIcon" 
                [headerText]="screen.headerLabel"
                [orderType]="screen.orderSummary.orderType"
                [orderNumber]="screen.orderSummary.number">
            </nu-order-header-label>
            <div>
                <app-scan-or-search  class="mat-elevation-z1" [focusInitial]="false"></app-scan-or-search>
            </div>
        </div>
        <div class="content">
            <div class="content-header">
                <nu-order-header-label
                    *ngIf="(isMobile$ | async)"
                    [iconName]="screen.headerIcon" 
                    [headerText]="screen.headerLabel"
                    [orderType]="screen.orderSummary.orderType"
                    [orderNumber]="screen.orderSummary.number">
                </nu-order-header-label>
                <app-instructions [instructions]="screen.pickListMessage" [instructionsSize]="'text-sm'"></app-instructions>
            </div>
            <app-expansion-panel>
                <ng-container header>
                    <nu-order-item-header [orderSummary]="screen.orderSummary"></nu-order-item-header>
                </ng-container>
                <ng-container content>
                    <nu-order-item-list [items]="screen.items" [selectable]="screen.orderSummary.status !== 'Open'"
                                            (itemClick)="onItemClick($event)"></nu-order-item-list>
                </ng-container>
            </app-expansion-panel>
        </div>
        <div class="buttons" responsive-class>
            <app-secondary-button
                    *ngIf="screen.printPickListButton"
                    (buttonClick)="doAction(screen.printPickListButton)">
                {{screen.printPickListButton.title}}
            </app-secondary-button>
            <app-primary-button
                    [disabled]="!screen.primaryActionButton.enabled"
                    (buttonClick)="doAction(screen.primaryActionButton)">
                <span>{{screen.primaryActionButton.title}}</span>
            </app-primary-button>
        </div>
    </div>
</app-bacon-strip>
<app-status-strip *ngIf="!(isMobile$ | async)"></app-status-strip>

