<div class="content mat-elevation-z5"
     responsive-class>
    <div class="title">
        <app-icon-button [iconName]="screenData.backButton.icon" (buttonClick)="doAction(screenData.backButton)"></app-icon-button>
        <span>{{screenData.totalNumberOfOrders}}&nbsp;{{screenData.title}}</span>
    </div>
    <div *ngIf="!(isMobile$ | async)">
        <img *ngIf="screenData?.logo" [src]="screenData.logo | imageUrl" alt="commerce logo">
    </div>
    <app-rounded-input *ngIf="(isMobile$ | async)" [iconName]="screenData.search.icon"
        [placeholder]="screenData.search.title" [value]="screenData.searchText" (valueChange)="onSearchValueChanged($event)">
    </app-rounded-input>
</div>
