<app-bacon-strip class="header"></app-bacon-strip>

<div class="wrapper-content">
    <div class="gift-receipt-button-wrapper">
        <app-secondary-button class="gift-receipt-button"
                              (click)="selectAll()">
            <div *ngIf="screen.selectAllLabel">
                <span class="gift-receipt-button-text">{{screen.selectAllLabel}}</span>
            </div>
        </app-secondary-button>
    </div>
    <ng-container *ngIf="screen.items && screen.items.length > 0">
        <div class="items-wrapper">
            <div *ngFor="let item of screen.items" class="sale-item">
                <mat-card class="item-card" (click)="select(item)">
                    <mat-card-content class="item-content">
                            <input type="checkbox"
                                   [checked]="item.giftReceipt">
                            <div *ngIf="item.name" class="item-card-title" responsive-class>
                                {{item.name}}
                            </div>
                            <app-currency-text class="item-card-price" responsive-class
                                               [amountText]="item.sellingPrice">
                            </app-currency-text>

                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </ng-container>
    <app-secondary-button class="gift-receipt-button-submit" responsive-class
                          (actionClick)="submit()"
                          (click)="submit()">
        <div *ngIf="screen.submitLabel">
            <span class="gift-receipt-button-text">{{screen.submitLabel}}</span>
        </div>
    </app-secondary-button>
</div>
