<button mat-stroked-button class="checkout-device-button" [disabled]="disabled" (click)="onClick()" responsive-class>
    <div *ngIf="isMobile" class="checkout-device-button-content" responsive-class>
        <div class="device-status-icon">
            <app-icon [iconName]="deviceIcon"></app-icon>
        </div>
        <div class="device-name {{checkoutSummary.helpRequested ? 'help-requested' : ''}} {{!checkoutSummary.checkoutStatusText ? 'device-offline': ''}}">
            {{checkoutSummary.checkoutDevice.name}} {{checkoutSummary.totalsStatusText ? checkoutSummary.checkoutStatusText : checkoutSummary.checkoutDevice.statusText}}
        </div>
        <div class="device-status" *ngIf="checkoutSummary.checkoutStatusText">
            <app-icon [iconName]="displayIcon()" [iconClass]="'mat-16'"></app-icon>
            <div>
                {{!checkoutSummary.totalsStatusText ? checkoutSummary.checkoutStatusText : ''}} {{checkoutSummary.totalsStatusText}}
            </div>
        </div>
        <div class="device-call-to-action help-requested" *ngIf="checkoutSummary.helpRequested">
            <app-icon [iconName]="displayIcon()" [@bounce]="checkoutSummary.helpRequested ? (bounceInterval | async) : 'down'"></app-icon>
        </div>
    </div>

    <div *ngIf="!isMobile" class="checkout-device-button-content">
        <div class="device-status-line">

            <div class="device-status-icon" [@bounce]="checkoutSummary.helpRequested ? (bounceInterval | async) : 'down'"
                [class.warn]="checkoutSummary.helpRequested">
                <app-icon [iconName]="displayIcon()"></app-icon>
            </div>
            <div class="device-name">
                {{checkoutSummary.checkoutDevice.statusText}}
            </div>
        </div>
        <div class="transaction-status-line">
            {{checkoutSummary.checkoutStatusText}}
        </div>
        <div class="totals-status-line">
            {{checkoutSummary.totalsStatusText}}
        </div>
    </div>
</button>