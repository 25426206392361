<nu-central-bacon-strip class="transaction-bacon-strip fh">
    <nu-central-local-nav-strip class="local-nav-strip">
        <div class="install-group-details-container">
            <div class="ig-details-toolbar df aic">
                <div class="grow">
                    <h1>{{ screen.name }} <small class="muted">{{ screen.targetVersion }}</small></h1>
                </div>
                <button mat-stroked-button color="primary" *ngIf="screen.renameAction"
                    (click)="doAction(screen.renameAction)">
                    {{ screen.renameAction.title }}
                </button>
                <button mat-stroked-button color="warn" *ngIf="screen.deleteAction"
                    (click)="doAction(screen.deleteAction)">
                    {{ screen.deleteAction.title }}
                </button>
            </div>

            <div *ngIf="scheduledUpdates$ | async as updates" class="install-group-details-section">
                <div class="df aic">
                    <h2 id="scheduledUpdatesLabel">{{ screen.scheduledUpdatesLabel }}</h2>
                    <button mat-stroked-button class="section-header-button" *ngIf="screen.scheduleUpdateAction"
                        (click)="doAction(screen.scheduleUpdateAction)">
                        {{ screen.scheduleUpdateAction.title }}
                    </button>
                </div>

                <div class="container df col" [class.center]="updates.length == 0" [ngSwitch]="updates.length">
                    <table *ngSwitchDefault mat-table [dataSource]="updates" aria-labelledby="scheduledUpdatesLabel">
                        <ng-container matColumnDef="icon">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let element">
                                <app-icon iconName="update"></app-icon>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="when">
                            <th mat-header-cell *matHeaderCellDef>{{ screen.scheduledUpdatesEffectiveDateColumnName }}
                            </th>
                            <td mat-cell *matCellDef="let element">{{ element.when | date }}</td>
                        </ng-container>

                        <ng-container matColumnDef="version">
                            <th mat-header-cell *matHeaderCellDef>{{ screen.scheduledUpdatesVersionColumnName }}</th>
                            <td mat-cell *matCellDef="let element">{{ element.targetVersion }}</td>
                        </ng-container>

                        <ng-container matColumnDef="actions" stickyEnd>
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-icon-button *ngIf="element.cancelAction"
                                    (click)="doAction(element.cancelAction.action, element.cancelAction.defaultPayload)">
                                    <app-icon [iconName]="element.cancelAction.icon"></app-icon>
                                </button>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="updateColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: updateColumns;"></tr>
                    </table>

                    <p *ngSwitchCase="0">{{ screen.noScheduledUpdatesMessage }}</p>
                </div>
            </div>

            <div *ngIf="this.screen.membersListProviderKey" class="install-group-details-section">
                <div class="df aic">
                    <h2 id="membersLabel">{{ screen.membersLabel }}</h2>
                    <button mat-flat-button color="primary" class="section-header-button"
                        *ngIf="hasMemberChanges && screen.saveMemberChangesAction" (click)="saveMemberChanges()">
                        {{ screen.saveMemberChangesAction.title }}
                    </button>
                    <div class="grow"></div>
                    <mat-form-field appearance="outline">
                        <input matInput [placeholder]="screen.membersFilterHint" [(ngModel)]="memberData.filter"
                            (keyup)="onFilterKeyUp($event)">
                        <button *ngIf="memberData.filter" matSuffix mat-icon-button (click)="memberData.filter=''">
                            <app-icon iconName="close"></app-icon>
                        </button>
                    </mat-form-field>
                </div>

                <div class="container df col">
                    <table mat-table [dataSource]="memberData" aria-labelledby="membersLabel">
                        <ng-container matColumnDef="checkbox">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let element">
                                <mat-checkbox [checked]="element.member"
                                    (change)="$event ? onBusinessUnitChanged(element, $event.checked) : null">
                                </mat-checkbox>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="id">
                            <th mat-header-cell *matHeaderCellDef>{{ screen.membersIdColumnName }}</th>
                            <td mat-cell *matCellDef="let element">{{ element.businessUnitId }}</td>
                        </ng-container>

                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef>{{ screen.membersNameColumnName }}</th>
                            <td mat-cell *matCellDef="let element">{{ element.businessUnitName }}</td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="storeColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: storeColumns;"></tr>
                    </table>
                </div>
            </div>
        </div>
    </nu-central-local-nav-strip>
</nu-central-bacon-strip>