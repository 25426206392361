import { Component, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import {
    MediaBreakpoints,
    OpenposMediaService,
    PosScreenDirective,
    ScreenComponent
} from '@jumpmind/openpos-client-core-lib';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProductCategory } from './product-category';
import { ProductInquiryInterface } from './product-inquiry.interface';

@ScreenComponent({
    name: 'ProductInquiry'
})
@Component({
    selector: 'nu-product-inquiry',
    templateUrl: './product-inquiry.component.html',
    styleUrls: ['./product-inquiry.component.scss']
})
export class ProductInquiryComponent extends PosScreenDirective<ProductInquiryInterface> {

    isMobile$: Observable<boolean>;
    filtersForm: FormGroup;
    expandedFilters: { [x: string]: boolean } = {};

    constructor(injector: Injector, private media: OpenposMediaService, private formBuilder: FormBuilder) {
        super(injector);

        this.isMobile$ = this.media.observe(new Map([
            [MediaBreakpoints.MOBILE_PORTRAIT, true],
            [MediaBreakpoints.MOBILE_LANDSCAPE, true],
            [MediaBreakpoints.TABLET_PORTRAIT, true],
            [MediaBreakpoints.TABLET_LANDSCAPE, false],
            [MediaBreakpoints.DESKTOP_PORTRAIT, false],
            [MediaBreakpoints.DESKTOP_LANDSCAPE, false]
        ]));
    }

    buildScreen(): void {
        if (this.screen.filters) {
            this.buildFiltersForm();
        } else {
            this.expandedFilters = {};
        }
    }

    buildFiltersForm(): void {
        this.filtersForm = new FormGroup({});
        for (const filter of this.screen.filters) {
            const formArray = [];
            let hasSelectedOption = false;
            for (const option of filter.values) {
                if (option.selected) {
                    hasSelectedOption = true;
                }
                formArray.push(this.formBuilder.control(option.selected));
            }
            this.filtersForm.addControl(filter.fieldName, this.formBuilder.array(formArray));
            if (this.expandedFilters[filter.fieldName] == null) {
                this.expandedFilters[filter.fieldName] = false;
            }
        }
    }

    categorySelected(category: ProductCategory): void {
        this.doAction(this.screen.categorySelectedAction, category);
    }

    productSelected(product): void {
        this.doAction(this.screen.productSelectedAction, product);
    }

    filterSelected(): void {
        const filters = this.screen.filters;
        for (const key in this.filtersForm.value) {
            if (this.filtersForm.value.hasOwnProperty(key)) {
                const formValues = this.filtersForm.value[key];
                const filterValues = filters.find(filter => filter.fieldName === key)?.values;
                for (let i = 0; i < formValues.length; i++) {
                    filterValues[i].selected = formValues[i];
                }
            }
        }
        this.doAction(this.screen.filterSelectedAction, filters);
    }

    filterPanelToggled(isExpanded: boolean, fieldName: string): void {
        this.expandedFilters[fieldName] = !!isExpanded;
    }
}
