import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { IReportCategory } from '../../reports-dashboard/report-category.interface';
import { IActionItem } from '@jumpmind/openpos-client-core-lib';

@Component({
    selector: 'nu-report-list',
    templateUrl: './report-list.component.html',
    styleUrls: ['./report-list.component.scss']
})
export class ReportListComponent implements OnInit {
    @Input()
    categories: IReportCategory[];
    @Output()
    selected = new EventEmitter<string>();
    @Output()
    selectedTitle = new EventEmitter<string>();

    @Input()
    selectedAction: string;
    expandedState = {};

    ngOnInit() {
        // If there is only 1 report category, automatically expand that section
        if (this.categories.length === 1) {
            this.expandedState[this.categories[0].id] = true;
        } else {
            // For mobile UX - if a category has already been expanded, automatically re-expand that category
            this.categories.forEach((reportCategory) => {
                const expandedCategories = reportCategory.buttons.filter(action => action.defaultPayload === this.selectedAction);
                this.expandedState[reportCategory.id] = expandedCategories.length > 0;
            });
        }
    }

    selectAction(button: IActionItem): void {
        this.selectedAction = button.defaultPayload;
        this.selected.emit(button.defaultPayload);
        this.selectedTitle.emit(button.defaultPayload);
    }
}
