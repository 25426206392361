<app-bacon-strip></app-bacon-strip>
  <div class="clienteling-container">
    <div class="left-outer-pane">
      <div class="left-pane">
        <div class="header-container">
          <img
            #img
            class="client-profile-pic"
            [src]="replaceTokens(screen.data.clientImageUrl)"
          />
          <div class="client-contact">
            <div class="client-name">{{ screen.data.clientName }}</div>
            <div class="client-contact-details">
            <app-icon [iconName]="'mail_outline'" [iconClass]="'material-icons mat-18 muted-grey'"></app-icon>
                {{ screen.data.primaryEmail }}
            <app-icon [iconName]="'account_heart'" [iconClass]="'material-icons mat-18 muted-grey'"></app-icon>
                {{ screen.data.primaryLoyaltyId }}
            <app-icon [iconName]="'phone'" [iconClass]="' terial-icons mat-18 muted-grey'"></app-icon>
                {{ screen.data.primaryPhone }}
            <app-icon [iconName]="'place'" [iconClass]="'material-icons mat-18 muted-grey'"></app-icon>
                {{ screen.data.primaryAddress }}
            </div>
          </div>
        </div>
        <div class="home-row-container">
          <app-icon [iconName]="'account_home'" [iconClass]="'material-icons mat-18 muted-grey'"></app-icon>
          <div>Assigned Associate <strong>{{ screen.data.assignedStylist }}</strong></div>
          <app-icon [iconName]="'storefront_outline'" [iconClass]="'material-icons mat-18 muted-grey'"></app-icon>
          <div>Home Store <strong>{{ screen.data.homeStore }}</strong></div>
        </div>
        <div class="actions-row-container">
          <app-secondary-button responsive-class
          (actionClick)="doAction('todo')"
          (click)="doAction('todo')">
          <div class="action-button">
          <div>{{screen.data.averageOnlineOrderAmount}}</div>
          <div class="action-button-subtext">average online order</div>
          </div>
          </app-secondary-button>
          <app-secondary-button responsive-class
          (actionClick)="doAction('todo')"
          (click)="doAction('todo')">
          <div class="action-button">
          <div>{{screen.data.averageInStoreOrderAmount}}</div>
          <div class="action-button-subtext">avg in-store order</div>
          </div>
          </app-secondary-button>          

          <app-secondary-button responsive-class
          (actionClick)="doAction('todo')"
          (click)="doAction('todo')">
            <span>Parties</span>
            <app-icon [iconName]="'chevron_right'" [iconClass]="'material-icons mat-18 muted-grey'"></app-icon>
          </app-secondary-button>

          <app-secondary-button responsive-class
          (actionClick)="doAction('todo')"
          (click)="doAction('todo')">
          <span>Messaging</span>
          <app-icon [iconName]="'chevron_right'" [iconClass]="'material-icons mat-18 muted-grey'"></app-icon>
          </app-secondary-button>          

        </div> 

        <div class="feed-container">
          <span class="feed-title">Feed</span>
          <div class="list-container">
            <div class="selectable selection-list selection-list-item" responsive-class *ngFor="let item of screen.data.clientelingFeedItems">

          
            <div class="feed-item">
              <div class="feed-item-date">
              {{item.dateDescription}}
              </div>
              <div class="feed-item-icon">
                <app-icon *ngIf="item.feedItemType == 'STORE_CHECKIN'" [iconName]="'map-marker-check-outline'" [iconClass]="'material-icons mat-18 blue-icon'"></app-icon>
                <app-icon *ngIf="item.feedItemType == 'FACEBOOK_INTERACTION'" [iconName]="'facebook'" [iconClass]="'material-icons mat-18 blue-icon'"></app-icon>                
                <app-icon *ngIf="item.feedItemType == 'STORE_PURCHASE'" [iconName]="'tag-outline'" [iconClass]="'material-icons mat-18 blue-icon'"></app-icon>                
                <app-icon *ngIf="item.feedItemType == 'STORE_RETURN'" [iconName]="'tag-outline'" [iconClass]="'material-icons mat-18 blue-icon'"></app-icon>                
                <app-icon *ngIf="item.feedItemType == 'TWITTER_INTERACTION'" [iconName]="'twitter'" [iconClass]="'material-icons mat-18 blue-icon'"></app-icon>                                
                <app-icon *ngIf="item.feedItemType == 'ONLINE_PURCHASE'" [iconName]="'web'" [iconClass]="'material-icons mat-18 blue-icon'"></app-icon>                                                
              </div>
              <div class="feed-item-title-container">
                <div class="feed-item-title">{{item.feedItemTitle}}</div>
                <div class="feed-item-subtitle" [innerHtml]="item.feedItemSubtitle | markdownFormatter"></div>
              </div>
            </div>

        </div>
        </div>
        </div>        
      </div>
    </div>
    <div class="right-pane">

      <mat-card class="clienteling-card" [ngClass]="'mat-elevation-z1'">
        <div class="card-title">Preferences</div>
          <div class="preferences-row">
            <div class="preference-category">colors</div>
            <div class="selectable selection-list selection-list-item preference-item" responsive-class *ngFor="let item of screen.data.colorPreferenceItems">
            <img
            #img
            class="color-swatch"
            [src]="replaceTokens(item.preferenceSwatchUrl)"
          />
            <div *ngIf="! item.dislikeFlag" class="preference-subtitle">{{item.preferenceSubtitle}}</div>
            <div *ngIf="item.dislikeFlag" class="preference-subtitle preference-dislike">{{item.preferenceSubtitle}}</div>
            </div>
        </div>
          <div class="preferences-size-row">
          <div class="preference-category">collections</div>
          <div class="selectable selection-list selection-list-item preference-item" responsive-class *ngFor="let item of screen.data.sizePreferenceItems">
            <div>{{item.preferenceTitle}}</div>
            <!-- <div class="preference-subtitle">{{item.preferenceSubtitle}}</div> -->
          </div>
        </div>        
        </mat-card>

      <mat-card class="clienteling-card" [ngClass]="'mat-elevation-z1'">
        <div class="card-title">Key Dates</div>
         <div class="selectable selection-list selection-list-item key-date-row" responsive-class *ngFor="let item of screen.data.clientelingKeyDateItems">
          <app-icon [iconName]="item.iconType" [iconClass]="'material-icons mat-18 muted-grey'"></app-icon>
          <div class="key-date-column">
            <div class="key-date-subtitle">{{item.keyDateType}}</div>
            <div>{{item.keyDateTitle}}</div>
            <div class="key-date-subtitle">{{item.keyDateSubtitle}}</div>
          </div>
        </div>

      </mat-card>

      <mat-card class="clienteling-card" [ngClass]="'mat-elevation-z1'">
        <div class="card-title">Relationships</div>
         <div class="selectable selection-list selection-list-item key-date-row" responsive-class *ngFor="let item of screen.data.clientelingRelationshipItems">
          <app-icon [iconName]="item.iconType" [iconClass]="'material-icons mat-18 muted-grey'"></app-icon>
          <div class="key-date-column">
            <div class="key-date-subtitle">{{item.relationshipType}}</div>
            <div>{{item.relationshipTitle}}</div>
            <div class="key-date-subtitle">{{item.relationshipSubtitle}}</div>
          </div>   
        </div>     
      </mat-card>  

      <mat-card class="clienteling-card clienteling-notes" [ngClass]="'mat-elevation-z1'">
        <div class="card-title">Notes</div>
        <div class="selectable selection-list selection-list-item" responsive-class *ngFor="let item of screen.data.clientelingNotes">
            <div class="note-text">{{item.noteText}}</div>
        </div>          
      </mat-card>   
      
      
      <div class="full-width-card">
      <mat-card class="clienteling-card suggestion-card" [ngClass]="'mat-elevation-z1'">
        <div class="card-title">{{screen.data.clientFirstName}} also might like...</div>
        <div class="suggestions-row">
        <div class="selectable selection-list selection-list-item" responsive-class *ngFor="let item of screen.data.suggestionItems"
        (click)="onSuggestionItemClick(item, $event)">
        <div class="clienteling-suggestion-item">
          <div class="suggested-item-image-container">
        <img
        #img
        class="suggested-item-image"
        [src]="item.imageUrl | imageUrl"
      />
       </div>
            {{item.description}}
          </div>
        </div>            
      </div>
      </mat-card>                                    
      </div>
    </div>

  </div>

