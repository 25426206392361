import { Component, Input } from '@angular/core';
import { IOrderCustomer } from '@jumpmind/openpos-client-core-lib';

@Component({
    selector: 'nu-order-header',
    templateUrl: './order-header.component.html',
    styleUrls: ['./order-header.component.scss']
})
export class OrderHeaderComponent {
    @Input()
    iconName: string;

    @Input()
    orderType: string;

    @Input()
    orderNumber: string;

    @Input()
    customer: IOrderCustomer;
}
