<app-dynamic-bacon-strip class="header" responsive-class [actionOverrideFlag]="isMobile && mobileStep > 0" (actionOverride)="mobileStepBack()">
    <div class="report-name" *ngIf="isMobile">{{selectedReportName ? selectedReportName : 'Select a Report'}}</div>
</app-dynamic-bacon-strip>
<div class="content-container" responsive-class>
    <mat-card class="report-list-card" responsive-class *ngIf="!isMobile || (isMobile && mobileStep === 0)">
        <h2 class="section-title">
            <app-icon iconClass="section-title-icon md" iconName="Reports"></app-icon>
            <span>{{screen.actionsTitle}}</span>
        </h2>
        <nu-report-list [selectedAction]="selectedReport" [categories]="screen.categories" (selected)="selectReport($event)"></nu-report-list>
        <br/>
    </mat-card>
    <div #content class="content" responsive-class *ngIf="(isMobile && mobileStep === 2) || !isMobile">
        <h3 class="muted-color report-help"
            *ngIf="helpSelectReportVisible"
            [@slideLeft]
            (@slideLeft.done)="onHelpSelectReportAnimationDone($event)">
            {{screen.helpSelectReport}}
        </h3>
        <h3 class="muted-color report-help"
            *ngIf="helpApplyFiltersVisible"
            [@slideLeft]
            (@slideLeft.done)="onHelpApplyFiltersAnimationDone($event)">
            {{screen.helpApplyFilters}}
        </h3>
        <mat-card class="report-card" responsive-class
                  *ngIf="screen.renderedReport"
                  [@slideDownUp]
                  (@slideDownUp.done)="onReportSlideAnimationDone($event)">
            <nu-report-view [report]="screen.renderedReport" [showSmallScreenReport]="true"></nu-report-view>
            <mat-card-actions align="end">
                <app-primary-button class="done-button" *ngIf="isMobile && screen.doneButton" (buttonClick)="doDoneAction()">
                    {{screen.doneButton.title}}
                </app-primary-button>
                <app-secondary-button class="print-button" *ngIf="screen.printReportButton" (buttonClick)="printReport()">
                    {{screen.printReportButton.title}}
                </app-secondary-button>
            </mat-card-actions>
        </mat-card>
    </div>
    <mat-card class="filters" responsive-class
              *ngIf="(isMobile && mobileStep === 1) || (!isMobile && paramsPanelVisible)"
              [@slideLeftRight]
              (@slideLeftRight.done)="onParamsPanelSlideAnimationDone($event)">
        <h3 *ngIf="!isMobile" class="report-name-header">{{selectedReportName}}</h3>
        <h3 class="section-title">
            <app-icon iconClass="section-title-icon md" iconName="Filter_List"></app-icon>
            <span>{{screen.filtersTitle}}</span>
        </h3>
        <app-dynamic-form-part (formInit)="onFilterFormInit($event)"
                               (formChanges)="onFilterFormChanges($event)"
                               formName="paramsForm"></app-dynamic-form-part>
        <app-primary-button (buttonClick)="onRunReportClick()" [disabled]="isParamsFormInvalid">
            {{screen.runReportButton.title}}
        </app-primary-button>
    </mat-card>
</div>