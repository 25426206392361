<app-bacon-strip>
<main responsive-class>
    <app-scan-or-search class="mat-elevation-z2"></app-scan-or-search>

    <div class="item-results-card" [class.mat-elevation-z2]="screen.expectResults" [class.with-results]="screen.expectResults">
        <div class="results-summary">{{screen.expectResults ? screen.successfulInquiryMessage : ''}}</div>

        <app-infinite-scroll
                [dataKey]="screen.searchResultsDataKey"
                [itemTemplate]="itemTemplate"
                [noItemsTemplate]="noItemTemplate"
                virtualScrollMinBufferPx="400"
                virtualScrollMaxBufferPx="800"
                [itemHeightPx]="(isMobile | async) ? 150 : 183">
            <ng-template #itemTemplate let-item>
                <section responsive-class class="item-template">
                    <app-image class="item-image" [imageUrl]="item.imageUrl | imageUrl" [altImageUrl]="screen.altItemImageUrl" [altText]="'Image NotFound'"></app-image>
                    <div class="item-content">
                        <div responsive-class class="description">{{item.title}}</div>
                        <ul responsive-class class="properties">
                            <li *ngFor="let prop of item.properties" class="muted-color">
                                {{prop.label}}: {{prop.value | valueFormatter: prop.valueFormatter}}
                            </li>
                        </ul>
                    </div>
                    <div responsive-class class="actions primary">
                        <button  mat-button *ngFor="let button of item.itemActions" class="action-button" (click)="doAction(button, item.index)">
                            <span [ngClass]="{'muted-color': ! button.enabled}">{{button.title}}</span>
                            <app-icon *ngIf="button.icon" [iconName]="button.icon"></app-icon>
                        </button>
                    </div>
                    <app-currency-text *ngIf="item.price" responsive-class class="price" [amountText]="item.price"></app-currency-text>
                </section>
            </ng-template>
            <ng-template #noItemTemplate>
                <section class="no-item-template">
                    <app-instructions *ngIf="screen.noResultsMessage" class="message" [instructions]="screen.noResultsMessage"></app-instructions>
                    <app-options-list class="options-list" optionListSizeClass="md"></app-options-list>
                </section>
            </ng-template>
        </app-infinite-scroll>
    </div>
</main>
</app-bacon-strip>
