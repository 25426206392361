import { Component, ElementRef, HostListener, Injector, ViewChild } from '@angular/core';
import {
    DialogComponent,
    FormBuilder,
    IFormElement,
    MediaBreakpoints,
    OpenposMediaService,
    PosScreenDirective
} from '@jumpmind/openpos-client-core-lib';
import { AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { SsnEntryMessage } from './ssn-entry.message';
import { SsnEntryErrorStateMatcher } from './ssn-entry.error-state-matcher';

@DialogComponent({
    name: 'SsnEntry'
})
@Component({
    templateUrl: './ssn-entry.component.html',
    styleUrls: ['./ssn-entry.component.scss']
})
export class SsnEntryComponent extends PosScreenDirective<SsnEntryMessage> {
    @ViewChild('ssn1') ssn1Input: ElementRef;
    ssn1Model = '';
    ssn2Model = '';
    ssn3Model = '';
    repeatSsn1Model = '';
    repeatSsn2Model = '';
    repeatSsn3Model = '';
    validSsnLength = 9;
    handledFormFields: string[] = [];
    phoneNumberField: IFormElement;
    dateOfBirthField: IFormElement;
    annualIncomeField: IFormElement;
    isMobile: Observable<boolean>;
    errorMatcher = new SsnEntryErrorStateMatcher();
    public controlName: string;
    control: AbstractControl;

    constructor(injector: Injector, private media: OpenposMediaService, private formBuilder: FormBuilder) {
        super(injector);
        this.initIsMobile();
    }

    @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent): void {
        e.preventDefault();
    }

    @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent): void {
        e.preventDefault();
    }

    @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent): void {
        e.preventDefault();
    }

    initIsMobile(): void {
        this.isMobile = this.media.observe(new Map([
            [MediaBreakpoints.MOBILE_PORTRAIT, true],
            [MediaBreakpoints.MOBILE_LANDSCAPE, true],
            [MediaBreakpoints.TABLET_PORTRAIT, true],
            [MediaBreakpoints.TABLET_LANDSCAPE, true],
            [MediaBreakpoints.DESKTOP_PORTRAIT, false],
            [MediaBreakpoints.DESKTOP_LANDSCAPE, false]
        ]));
    }

    getFormElementById(formElementId: string): IFormElement {
        return this.screen.form?.formElements.filter(element => element.id === formElementId)[0];
    }

    buildScreen(): void {
        this.buildStructuredForm();
        this.screen.formGroup = this.formBuilder.group(this.screen.form);
    }

    buildStructuredForm(): void {
        this.handledFormFields = [
            'phone',
            'birthday',
            'income'
        ];
        this.phoneNumberField = this.getFormElementById('phone');
        this.dateOfBirthField = this.getFormElementById('birthday');
        this.annualIncomeField = this.getFormElementById('income');
    }

    validateKey(event: KeyboardEvent): boolean {
        const enteredSymbol = event.key;
        const numbersOnlyPattern = /^\d$/;
        return numbersOnlyPattern.test(enteredSymbol);
    }

    onFieldChanged(formElement: IFormElement): void {
        if (formElement.valueChangedAction) {
            const form = this.formBuilder.buildFormPayload(this.screen.formGroup, this.screen.form);
        }
    }

    inputValueChanged(event): void {
        if (event.target.id === 'income') {
            this.annualIncomeField.value = event.target.value;
        }
    }

    submit(): void {
        if (this.isSubmitEnabled()) {
            if (this.screen.form.formElements.length > 0) {
                const additionalPayload: AdditionalPayload = {
                    ssn: this.concatSsnResultValue(),
                    ssnRepeat: this.concatRepeatSsnResultValue(),
                    phone: this.phoneNumberField.value,
                    birthday: this.dateOfBirthField.value,
                    income: this.annualIncomeField.value
                };
                this.doAction(this.screen.nextAction, additionalPayload);
            } else {
                const ssnPayload: SsnPayload = {
                    ssn: this.concatSsnResultValue(),
                    ssnRepeat: this.concatRepeatSsnResultValue(),
                };
                this.doAction(this.screen.nextAction, ssnPayload);
            }
        }
    }

    isSubmitEnabled(): boolean {
        return this.isAllFormElementsContainDefinedValue()
                && !this.isAnyFormElementInErrorState()
                && this.isEnteredSsnSizeCorrect();
    }

    isAllFormElementsContainDefinedValue(): boolean {
        for (const element of this.screen.form.formElements) {
            if (element.value === null || element.value === undefined) {
                return false;
            }
        }
        return true;
    }

    isAnyFormElementInErrorState(): boolean {
        for (const element of this.screen.form.formElements) {
            this.control = this.screen.formGroup.controls[element.id];
            if (this.errorMatcher.isErrorState(this.control, null)) {
                return true;
            }
        }
        return false;
    }

    isEnteredSsnSizeCorrect(): boolean {
        return this.concatSsnResultValue().length === this.validSsnLength
                && this.concatRepeatSsnResultValue().length === this.validSsnLength;
    }

    concatSsnResultValue(): string {
        return this.ssn1Model + this.ssn2Model + this.ssn3Model;
    }

    concatRepeatSsnResultValue(): string {
        return this.repeatSsn1Model + this.repeatSsn2Model + this.repeatSsn3Model;
    }

    clearFields(): void {
        if (this.screen.error) {
            this.screen.error = '';
            this.ssn1Model = '';
            this.ssn2Model = '';
            this.ssn3Model = '';
            this.repeatSsn1Model = '';
            this.repeatSsn2Model = '';
            this.repeatSsn3Model = '';
        }
    }
}

export interface SsnPayload {
    ssn: string;
    ssnRepeat: string;
}

export interface AdditionalPayload {
    ssn: string;
    ssnRepeat: string;
    phone: string;
    birthday: string;
    income: string;
}

