<app-dialog-header></app-dialog-header>

<mat-dialog-content fxLayout="column">
    <ng-container>
        <div class="ssn-entry">
            <div class="text-sm">{{screen.enterCodeDescription}}</div>
            <div class="input-container">
                <mat-form-field class="input-ssn" responsive-class>
                    <input matInput cdkFocusInitial (keyup)="(ssn1.value.length == 3) ? ssn2.focus() : ''"
                           [(ngModel)]="ssn1Model"
                           (keypress)="validateKey($event)"
                           (click)="clearFields()"
                           maxlength="3"
                           type="password"
                           inputmode="numeric"
                           #ssn1>
                </mat-form-field>
                <label>-</label>
                <mat-form-field class="input-ssn" responsive-class>
                    <input matInput (keyup)="(ssn2.value.length == 2) ? ssn3.focus() : ''"
                           [(ngModel)]="ssn2Model"
                           (keypress)="validateKey($event)"
                           (click)="clearFields()"
                           maxlength="2"
                           type="password"
                           inputmode="numeric"
                           #ssn2>
                </mat-form-field>
                <label>-</label>
                <mat-form-field class="input-ssn" responsive-class>
                    <input matInput (keyup)="(ssn3.value.length == 4) ? repeatSsn1.focus() : ''"
                           [(ngModel)]="ssn3Model"
                           (keypress)="validateKey($event)"
                           (click)="clearFields()"
                           maxlength="4"
                           type="password"
                           inputmode="numeric"
                           #ssn3>
                </mat-form-field>
            </div>
            <div class="text-sm">{{screen.reenterCodeDescription}}</div>
            <div class="input-container">
                <mat-form-field class="input-ssn" responsive-class>
                    <input matInput (keyup)="(repeatSsn1.value.length == 3) ? repeatSsn2.focus() : ''"
                           [(ngModel)]="repeatSsn1Model"
                           (keypress)="validateKey($event)"
                           (click)="clearFields()"
                           maxlength="3"
                           type="password"
                           inputmode="numeric"
                           #repeatSsn1>
                </mat-form-field>
                <label>-</label>
                <mat-form-field class="input-ssn" responsive-class>
                    <input matInput (keyup)="(repeatSsn2.value.length == 2) ? repeatSsn3.focus() : ''"
                           [(ngModel)]="repeatSsn2Model"
                           (keypress)="validateKey($event)"
                           (click)="clearFields()"
                           maxlength="2"
                           type="password"
                           inputmode="numeric"
                           #repeatSsn2>
                </mat-form-field>
                <label>-</label>
                <mat-form-field class="input-ssn" responsive-class>
                    <input matInput
                           [(ngModel)]="repeatSsn3Model"
                           (keypress)="validateKey($event)"
                           (click)="clearFields()"
                           maxlength="4"
                           type="password"
                           inputmode="numeric"
                           #repeatSsn3>
                </mat-form-field>
            </div>
            <div class="warn" *ngIf="screen.error">{{screen.error}}</div>
            <app-dynamic-form-field *ngFor="let field of screen.form.formElements" [formGroup]="screen.formGroup" [formField]="field" (changed)="onFieldChanged($event)" (input)="inputValueChanged($event)"></app-dynamic-form-field>
        </div>
    </ng-container>
</mat-dialog-content>
<div class="buttons">
    <app-secondary-button (click)="doAction(screen.cancelAction)" [actionItem]="screen.cancelAction">
        {{screen.cancelAction.title}}
    </app-secondary-button>
    <app-primary-button inputType="submit"
                        id="primaryButton"
                        [disabled]="!isSubmitEnabled()"
                        (click)="submit()"
                        [actionItem]="screen.nextAction">
        {{screen.nextAction.title}}
    </app-primary-button>
</div>