import { Component, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { ScreenComponent, PosScreenDirective, UIDataMessageService, OpenposMediaService, MediaBreakpoints } from '@jumpmind/openpos-client-core-lib';
import { MobileMgmtDashboardInterface } from './mobile-mgmt-dashboard.interface';
import { CheckoutSummaryInterface } from '../checkout-summary.interface';
import { DeviceToggleChange } from '../components/checkout-device-toggle-button/checkout-device-toggle-button.component';

@ScreenComponent({
    name: 'MobileMgmtDashboard'
})
@Component({
    selector: 'nu-mobile-mgmt-dashboard',
    templateUrl: './mobile-mgmt-dashboard.component.html',
    styleUrls: ['./mobile-mgmt-dashboard.component.scss']
})
export class MobileMgmtDashboardComponent extends PosScreenDirective<MobileMgmtDashboardInterface>  {
    checkoutSummaryList$: Observable<CheckoutSummaryInterface[]>;
    isMobile$: Observable<boolean>;
    selectedCheckoutSummary: CheckoutSummaryInterface;
    selectedDeviceId: string;

    private currentCheckoutSummaryList: CheckoutSummaryInterface[];

    constructor(injector: Injector, private dataMessageService: UIDataMessageService,
        mediaService: OpenposMediaService) {
        super(injector);
        this.isMobile$ = mediaService.observe(new Map([
            [MediaBreakpoints.MOBILE_PORTRAIT, true],
            [MediaBreakpoints.MOBILE_LANDSCAPE, true],
            [MediaBreakpoints.TABLET_PORTRAIT, false],
            [MediaBreakpoints.TABLET_LANDSCAPE, false],
            [MediaBreakpoints.DESKTOP_PORTRAIT, false],
            [MediaBreakpoints.DESKTOP_LANDSCAPE, false]
        ]));
    }

    doDebug() {
        this.doAction('ViewDevice');
    }

    viewDevice() {
        this.doAction('ViewDevice');
    }
    viewSelectedDevice() {
        this.doAction('ViewSelectedDevice');
    }

    buildScreen() {
        this.checkoutSummaryList$ = this.dataMessageService.getData$(this.screen.checkoutSummaryProviderKey);
        this.checkoutSummaryList$.subscribe(updatedList => {
            this.currentCheckoutSummaryList = updatedList;
            this.updateSelectedCheckoutSummary(updatedList);
        });
    }

    hasTotals(cs: CheckoutSummaryInterface) {
        return cs && cs.transactionSummary &&
            ((cs.transactionSummary.totals && cs.transactionSummary.totals.length > 0) ||
                cs.transactionSummary.grandTotal || cs.transactionSummary.itemCount);
    }

    onSelectedChange(toggleChange: DeviceToggleChange): void {
        this.selectedDeviceId = toggleChange.value.checkoutDevice.id;
        this.selectedCheckoutSummary = this.currentCheckoutSummaryList.find(cs => cs.checkoutDevice.id === this.selectedDeviceId);
        this.doAction('ViewSelectedDevice', toggleChange.value.checkoutDevice);
    }

    onSelectedCheckoutDeviceChange(deviceId: string): void {
        this.selectedCheckoutSummary = this.currentCheckoutSummaryList.find(cs => cs.checkoutDevice.id === deviceId);
        this.selectedDeviceId = deviceId;
    }

    updateSelectedCheckoutSummary(updatedList: CheckoutSummaryInterface[]) {
        if (!!this.selectedDeviceId) {
            this.selectedCheckoutSummary =
                updatedList.find(cs => cs.checkoutDevice.id === this.selectedDeviceId);
        } else {
            this.selectedCheckoutSummary = null; // updatedList.length > 0 ? updatedList[0] : null;
        }
    }
}
