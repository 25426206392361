<div class="notes">
  <div *ngFor="let note of notes">
    <div
            class="note"
            [class.not-customer]="!note.customer">
      {{note.note}}</div>
    <div
            class="name"
            [class.not-customer]="!note.customer">
      <span>{{note.name}} ({{note.userName}})</span>
    </div>
    <div
            class="date"
            [class.not-customer]="!note.customer">
      {{note.date | date: 'M/dd/yyyy HH:mm'}}</div>
  </div>
</div>
<app-secondary-button (buttonClick)="doAction(addNoteButton)">{{addNoteButton.title}}</app-secondary-button>

