import { Component, Injector } from '@angular/core';
import { DialogComponent, PosScreenDirective } from '@jumpmind/openpos-client-core-lib';
import {
    EmployeeDiscountButton,
    EmployeeDiscountItem,
    EmployeeDiscountSelectionChange,
    EmployeeDiscountSelectMessage
} from './employe-discount-select.message';

@DialogComponent({
    name: 'EmployeeDiscountSelect'
})
@Component({
    templateUrl: 'employee-discount-select.component.html',
    styleUrls: ['employee-discount-select.component.scss']
})
export class EmployeeDiscountSelectComponent extends PosScreenDirective<EmployeeDiscountSelectMessage> {
    constructor(injector: Injector) {
        super(injector);
    }

    buildScreen() {
        console.log('[EmployeeDiscountSelectComponent]: Built screen');
    }

    complete() {
        const changes: EmployeeDiscountSelectionChange[] = this.getChanges();
        this.doAction(this.screen.okAction, changes);
    }

    getChanges(): EmployeeDiscountSelectionChange[] {
        return this.screen.items
            .map(item => ({
                newClassifier: item.classifier,
                lineSequenceNumber: item.lineSequenceNumber
            }));
    }

    getDiscountButtons(item: EmployeeDiscountItem): EmployeeDiscountButton[] {
        return item.excludedFromPromo ? item.discountExcludePromoButtons : item.discountButtons;
    }

    getPreviousSelectedIndex(item: EmployeeDiscountItem): number {
        const previousExcludedFromPromo = !item.excludedFromPromo;
        const previousDiscountButtons = previousExcludedFromPromo ? item.discountExcludePromoButtons : item.discountButtons;

        return previousDiscountButtons.findIndex(b => b.classifier === item.classifier);
    }

    onExcludeFromPromoToggle(item: EmployeeDiscountItem): void {
        const previousSelectedIndex = this.getPreviousSelectedIndex(item);
        const newSelectedDiscountButton = this.getDiscountButtons(item)[previousSelectedIndex];

        if (newSelectedDiscountButton) {
            item.classifier = newSelectedDiscountButton.classifier;
        }
    }
}
