
<nu-central-bacon-strip class="transaction-bacon-strip fh">
    <nu-central-local-nav-strip class="local-nav-strip">
    <div *ngIf="installGroups$ | async as groups; else loadingGroups" class="dgrid install-groups-content">
        <div class="install-groups-toolbar">
            <app-primary-button *ngIf="screen.createAction" class="install-groups-toolbar-btn-new"
                [actionItem]="screen.createAction" (click)="doAction(screen.createAction)">
                {{screen.createAction.title}}
            </app-primary-button>
        </div>

        <div *ngIf="groups.length > 0; else noGroups" class="dgrid install-groups-results-pane">
            <app-infinite-scroll [dataKey]="screen.providerKey" [itemTemplate]="itemTemplate"
                virtualScrollMinBufferPx="200" virtualScrollMaxBufferPx="400" [itemHeightPx]="150">

                <ng-template #itemTemplate let-item>
                    <div class="dgrid-item install-groups-item">
                        <div class="name dflex col">
                            {{ item.name }}
                        </div>

                        <div class="version dflex col">
                            <div>{{ item.targetVersion }}</div>
                            <div *ngIf="item.scheduledVersionMessage" class="sub muted">
                                {{ item.scheduledVersionMessage }}
                            </div>
                        </div>

                        <div class="actions dflex col">
                            <button *ngFor="let act of item.actions" mat-button [disabled]="!act.enabled"
                                [actionItem]="act" (click)="doAction(act, act.defaultPayload)">
                                <span class.muted-color="!button.enabled">{{ act.title }}</span>
                                <app-icon *ngIf="act.icon" [iconName]="act.icon"></app-icon>
                            </button>
                        </div>
                    </div>
                </ng-template>

            </app-infinite-scroll>
        </div>

        <ng-template #noGroups>
            <div class="status-message-container">
                <p class="status-message">{{ screen.emptyGroupsLabel }}</p>
            </div>
        </ng-template>
    </div>

    <ng-template #loadingGroups>
        <div class="status-message-container">
            <p class="status-message">Loading...</p>
        </div>
    </ng-template>
    </nu-central-local-nav-strip>
</nu-central-bacon-strip>