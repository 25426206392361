import { Component, Injector } from '@angular/core';
import { PosScreenDirective, ScreenComponent } from '@jumpmind/openpos-client-core-lib';
import { PartyDashboardInterface } from './party-dashboard.interface';

@ScreenComponent({
  name: 'PartyDashboard'
})
@Component({
  selector: 'app-party-dashboard',
  templateUrl: './party-dashboard.component.html',
  styleUrls: ['./party-dashboard.component.scss']
})
export class PartyDashboardComponent extends PosScreenDirective<PartyDashboardInterface> {

  constructor(injector: Injector) {
    super(injector);
  }

  buildScreen() { }

}
