import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IActionItem } from '@jumpmind/openpos-client-core-lib';

@Component({
    selector: 'nu-order-acknowledge-button-group',
    templateUrl: './order-acknowledge-button-group.component.html',
    styleUrls: ['./order-acknowledge-button-group.component.scss']
})
export class OrderAcknowledgeButtonGroupComponent implements OnInit {
    @Input()
    actionItems: IActionItem[] = [];

    @Output()
    acknowledge: EventEmitter<IActionItem> = new EventEmitter<IActionItem>();

    acceptAction: IActionItem;
    declineAction: IActionItem;

    onClick(event, action: IActionItem) {
        this.acknowledge.emit(action);
        event.stopPropagation();
    }

    ngOnInit(): void {
        this.acceptAction = this.actionItems.find(it => it.action === 'Accept');
        this.declineAction = this.actionItems.find(it => it.action === 'Decline');
    }
}

