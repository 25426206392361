import { Component, Input, Output, EventEmitter, } from '@angular/core';
import { CheckoutSummaryInterface } from '../../checkout-summary.interface';
import { trigger, state, style, transition, useAnimation } from '@angular/animations';
import { bounceAnimation } from '../bounce.animation';
import { timer } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'nu-checkout-device-toggle-button',
    templateUrl: './checkout-device-toggle-button.component.html',
    styleUrls: ['./checkout-device-toggle-button.component.scss'],
    animations: [
        trigger('bounce', [
            state('move', style({
                transform: 'translateY(0)'
            })),
            transition('* => move', [
                useAnimation(bounceAnimation, {
                    params: {
                        height: '25px',
                        time: '1s'
                    }
                })
            ])
        ]),
    ]
})
export class CheckoutDeviceToggleButtonComponent {
    @Input()
    checkoutSummary: CheckoutSummaryInterface;

    @Input()
    selected: boolean;

    @Input()
    disabled: boolean;

    @Input()
    deviceIcon: string;

    @Input()
    isMobile = false;

    @Output()
    selectedChange = new EventEmitter();

    @Output()
    change: EventEmitter<DeviceToggleChange> = new EventEmitter<DeviceToggleChange>();

    bounceInterval = timer(0, 1000).pipe(map(i => i % 2 ? 'down' : 'move'));
    transactionTotal: number;

    onClick() {
        this.setSelected(!this.selected);
        this.change.emit(new DeviceToggleChange(this, this.checkoutSummary));
    }

    setSelected(value: boolean) {
        this.selected = value;
        this.selectedChange.emit(this.selected);
    }

    hasIcon(): boolean {
        return (this.checkoutSummary.helpRequested && !!this.checkoutSummary.helpRequestedIcon) ||
            !!this.checkoutSummary.statusIcon;
    }

    displayIcon(): string {
        if (this.checkoutSummary.helpRequested) {
            return this.checkoutSummary.helpRequestedIcon;
        } else {
            return this.checkoutSummary.statusIcon;
        }
    }
}


export class DeviceToggleChange {
    constructor(public source: CheckoutDeviceToggleButtonComponent, public value: CheckoutSummaryInterface) { }
}
