export enum OrderStatus {
    OPEN = 'OPEN',
    CLAIMED = 'CLAIMED',
    PARTIALLY_PICKED = 'PARTIALLY_PICKED',
    PICKED = 'PICKED',
    PARTIALLY_PACKED = 'PARTIALLY_PACKED',
    PACKED = 'PACKED',
    PARTIAL_DELIVERY = 'PARTIAL_DELIVERY',
    PARTIAL_PICKUP = 'PARTIAL_PICKUP',
    COMPLETED = 'COMPLETED',
    CANCELLED = 'CANCELLED',
}
