import { Component, Injector } from '@angular/core';
import {
    MediaBreakpoints,
    OpenposMediaService,
    PosScreenDirective,
    ScreenComponent,
    UIDataMessageService
} from '@jumpmind/openpos-client-core-lib';
import { Observable } from 'rxjs';
import { MobileMgmtDeviceDetailsInterface } from './mobile-mgmt-device-details.interface';
import { CheckoutSummaryInterface } from '../checkout-summary.interface';

@ScreenComponent({
    name: 'MobileMgmtDeviceDetails'
})
@Component({
    selector: 'nu-mobile-mgmt-device-details',
    templateUrl: './mobile-mgmt-device-details.component.html',
    styleUrls: ['./mobile-mgmt-device-details.component.scss']
})
export class MobileMgmtDeviceDetailsComponent extends PosScreenDirective<MobileMgmtDeviceDetailsInterface>  {
    checkoutSummaryList$: Observable<CheckoutSummaryInterface[]>;
    isMobile$: Observable<boolean>;
    selectedCheckoutSummary: CheckoutSummaryInterface;
    selectedDeviceId: string;

    private currentCheckoutSummaryList: CheckoutSummaryInterface[];

    constructor(injector: Injector, private dataMessageService: UIDataMessageService,
        mediaService: OpenposMediaService) {
        super(injector);
        this.isMobile$ = mediaService.observe(new Map([
            [MediaBreakpoints.MOBILE_PORTRAIT, true],
            [MediaBreakpoints.MOBILE_LANDSCAPE, true],
            [MediaBreakpoints.TABLET_PORTRAIT, false],
            [MediaBreakpoints.TABLET_LANDSCAPE, false],
            [MediaBreakpoints.DESKTOP_PORTRAIT, false],
            [MediaBreakpoints.DESKTOP_LANDSCAPE, false]
        ]));
    }

    buildScreen() {
        this.checkoutSummaryList$ = this.dataMessageService.getData$(this.screen.checkoutSummaryProviderKey);
        this.checkoutSummaryList$.subscribe(updatedList => {
            this.currentCheckoutSummaryList = updatedList;
            this.updateSelectedCheckoutSummary(updatedList);
        });
    }

    hasTotals(cs: CheckoutSummaryInterface) {
        return cs && cs.transactionSummary &&
      ((cs.transactionSummary.totals && cs.transactionSummary.totals.length > 0) ||
        cs.transactionSummary.grandTotal || cs.transactionSummary.itemCount);
    }

    updateSelectedCheckoutSummary(updatedList: CheckoutSummaryInterface[]) {
        this.selectedCheckoutSummary = updatedList.find(cs => cs.checkoutDevice.id === this.screen.selectedDeviceId);
    }
}
