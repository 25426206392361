import { Component, Injector, OnDestroy, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { PosScreenDirective, ScreenComponent } from '@jumpmind/openpos-client-core-lib';
import { IStoreStatus } from '../store-status/store-status.interface';
import { DeploymentsInterface } from './deployments.interface';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { strictEqual } from 'assert';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';

@ScreenComponent({
  name: 'Deployments'
})
@Component({
  selector: 'nu-deployments',
  templateUrl: './deployments.component.html',
  styleUrls: ['./deployments.component.scss']
})
export class DeploymentsComponent extends PosScreenDirective<DeploymentsInterface> implements OnInit, OnDestroy {

  buildScreen(): void {
    this.rebuildTheScreen();
  }

  center: google.maps.LatLngLiteral = { lat: 38.12, lng: -98.57 };
  zoom: number = 5;

  @ViewChild('deploymentMap') deploymentMap;

  availableVersions: string[];
  deployedVersions: string[];
  storeVersionList: IStoreStatus[];

  markers: google.maps.Marker[];
  map: google.maps.Map;

  markerMap: Map<string, google.maps.Marker>;
  infoWindowMap: Map<string, google.maps.InfoWindow>;

  currentlyOpenInfoWindow: google.maps.InfoWindow;

  options: google.maps.MapOptions = {
    mapTypeId: 'roadmap',
    zoomControl: true,
    scrollwheel: true,
    disableDoubleClickZoom: false,
    maxZoom: 15,
    minZoom: 4,
    streetViewControl: false,
    mapTypeControl: false,
    fullscreenControl: false,
    styles: [
      {
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#f5f5f5"
          }
        ]
      },
      {
        "elementType": "labels.icon",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#616161"
          }
        ]
      },
      {
        "elementType": "labels.text.stroke",
        "stylers": [
          {
            "color": "#f5f5f5"
          }
        ]
      },
      {
        "featureType": "administrative",
        "elementType": "geometry",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "administrative.land_parcel",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#bdbdbd"
          }
        ]
      },
      {
        "featureType": "poi",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#eeeeee"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#757575"
          }
        ]
      },
      {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#e5e5e5"
          }
        ]
      },
      {
        "featureType": "poi.park",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#9e9e9e"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#ffffff"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "labels.icon",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "road.arterial",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#757575"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#dadada"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#616161"
          }
        ]
      },
      {
        "featureType": "road.local",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#9e9e9e"
          }
        ]
      },
      {
        "featureType": "transit",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "transit.line",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#e5e5e5"
          }
        ]
      },
      {
        "featureType": "transit.station",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#eeeeee"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#c9c9c9"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#9e9e9e"
          }
        ]
      }
    ]
  }

  constructor(injector: Injector) {
    super(injector);
  }

  rebuildTheScreen(): void {
    this.screen.storeVersionList.forEach((storeStatus: IStoreStatus) => {
      var icon = storeStatus.version + "-marker.png";
      if (this.markerMap) {
        this.markerMap.get(storeStatus.storeNumber).setIcon("http://localhost:6140/" + icon);
      }      
      if (this.infoWindowMap) {
        const contentString = '<div><div><b>ID:</b> ' + storeStatus.storeNumber +
        '</div><div><b>Name:</b> ' + storeStatus.storeName +
        '</div><div><b>Brand:</b> ' + storeStatus.brand +
        '</div><div><b>Address:</b> ' + storeStatus.address +
        '</div><div><b>Version:</b> ' + storeStatus.version +
        '</div><div><b>Deploying:</b> ' + storeStatus.deploying +
        '</div></div> <a id="Button' + storeStatus.storeNumber + '" href="javascript:void(0);">Details</a>';        
        this.infoWindowMap.get(storeStatus.storeNumber).setContent(contentString);
      }
    });
  }

  buildTheScreen(): void {
    var infowindow = new google.maps.InfoWindow({
    });

    this.screen.storeVersionList.forEach((storeStatus: IStoreStatus) => {

        google.maps.event.addListener(infowindow, 'domready', () => {
          const someButton = document.getElementById('Button' + storeStatus.storeNumber);
          if (someButton) {
            google.maps.event.addDomListener(someButton, 'click',
              () => {
                this.onDetails(storeStatus.storeNumber);
              })
          }
        });
      var icon = storeStatus.version + "-marker.png";
      var marker = new google.maps.Marker({
        position: { lat: +storeStatus.latitude, lng: +storeStatus.longitude },
        map: this.map,//Map that we need to add
        icon: "http://localhost:6140/" + icon,
        title: storeStatus.storeName,
        draggable: false// If set to true you can drag the marker
      });
      marker.addListener("click", () => {
        if (this.currentlyOpenInfoWindow) {
          this.currentlyOpenInfoWindow.close();
        }
        const contentString = '<div><div><b>ID:</b> ' + storeStatus.storeNumber +
        '</div><div><b>Name:</b> ' + storeStatus.storeName +
        '</div><div><b>Brand:</b> ' + storeStatus.brand +
        '</div><div><b>Address:</b> ' + storeStatus.address +
        '</div><div><b>Version:</b> ' + storeStatus.version +
        '</div><div><b>Deploying:</b> ' + storeStatus.deploying +
        '</div></div> <a id="Button' + storeStatus.storeNumber + '" href="javascript:void(0);">Details</a>';
        infowindow.setContent(contentString);

        this.currentlyOpenInfoWindow = infowindow;
        infowindow.open(this.deploymentMap.googleMap, marker);
      });
      this.infoWindowMap.set(storeStatus.storeNumber, infowindow);
      this.markers.push(marker);
      this.markerMap.set(storeStatus.storeNumber, marker);
    });
  }


  ngOnInit(): void {
    this.deployedVersions = this.screen.deployedVersions;
    this.availableVersions = this.screen.availableVersions;
    this.storeVersionList = this.screen.storeVersionList;
  }

  onUpgradeTo(version: string) {
    this.doAction("UpgradeBusinessUnits", "To:" + version);
  }

  onUpgradeFrom(version: string) {
    this.doAction("UpgradeBusinessUnits", "From:" + version);
  }

  convertToIdString(version: string): string {
    let re = /\./gi;
    let result = version.replace(re, "-");
    result = "version" + result;
    return result;
  }

  ngAfterViewInit(): void {
      console.log("Rebuilding maps...");
      this.markers = [];
      this.markerMap = new Map<string, google.maps.Marker>();
      this.infoWindowMap = new Map<string, google.maps.InfoWindow>();
      if (!this.map) {
        this.map = this.deploymentMap.googleMap
      }
      this.buildTheScreen();
  }

  onStoreSelected(value: IStoreStatus) {
    let map: google.maps.Map = this.deploymentMap.googleMap;
    map.setZoom(15);
    map.panTo({ lat: +value.latitude, lng: +value.longitude })
  }

  onDetails(storeId: string) {
    if (storeId) {
      this.doAction('StoreDetails', storeId);
    } else {
      console.log("Store was null");
    }
    return false;
  }
}
