import { Component, Injector } from '@angular/core';
import { PosScreenDirective, ScreenComponent } from '@jumpmind/openpos-client-core-lib';
import { PromotionSearchInterface } from './promotion-search.interface';

@ScreenComponent({
    name: 'PromotionSearch'
})
@Component({
    selector: 'nu-promotion-search',
    templateUrl: './promotion-search.component.html',
    styleUrls: ['./promotion-search.component.scss']
})
export class PromotionSearchComponent extends PosScreenDirective<PromotionSearchInterface> {

    constructor(injector: Injector) {
        super(injector);
    }

    buildScreen() { }
}
