import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IOrderCustomer } from '@jumpmind/openpos-client-core-lib';

@Component({
    selector: 'nu-order-customer',
    templateUrl: './order-customer.component.html',
    styleUrls: ['./order-customer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderCustomerComponent {
    @Input()
    customer: IOrderCustomer;

    @Input()
    showContact: boolean;

    @Input()
    showAddress: boolean;

    @Input()
    label: string;
}
