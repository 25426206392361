import { Component, Injector } from '@angular/core';
import {
    IAbstractScreen,
    ImageService,
    PosScreenDirective,
    ScreenComponent
} from '@jumpmind/openpos-client-core-lib';

@ScreenComponent({
    name: 'PPT'
})
@Component({
    selector: 'nu-ppt',
    templateUrl: './ppt.component.html',
    styleUrls: ['./ppt.component.scss']
})
export class PptComponent extends PosScreenDirective<IAbstractScreen> {

    constructor(private imageService: ImageService, injector: Injector) {
        super(injector);
    }

    buildScreen() {
    }
}
