import { Component, Injector } from '@angular/core';
import { ImageService, PosScreenDirective, ScreenComponent } from '@jumpmind/openpos-client-core-lib';
import { PriceCheckerHomeInterface } from './price-checker-home.interface';

@ScreenComponent({
    name: 'PriceCheckerHome'
})
@Component({
    selector: 'nu-price-checker-home',
    templateUrl: './price-checker-home.component.html',
    styleUrls: ['./price-checker-home.component.scss']
})
export class PriceCheckerHomeComponent extends PosScreenDirective<PriceCheckerHomeInterface> {

    backgroundStyle = {};


    constructor(private imageService: ImageService, injector: Injector) {
        super(injector);
    }

    buildScreen() {
        const url = this.imageService.replaceImageUrl(this.screen.backgroundImageUrl);
        this.backgroundStyle = {
            'background-image': `url(${url})`,
            'background-repeat': 'no-repeat',
            'background-size': 'cover',
            'background-position': 'center'
        };
    }
}
