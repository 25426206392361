<ng-container *ngIf="orderSummary.statusCode === 'OPEN'">
    <div responsive-class class="open-order-header">
        <app-icon responsive-class [iconName]="orderSummary.orderTypeIcon" [iconClass]="'sm'"></app-icon>
        <div responsive-class>
            <strong><nu-order-due responsive-class
                class="order-due"
                *ngIf="orderSummary.orderDue"
                [label]="orderSummary.orderDueLabel"
                [dueDate]="orderSummary.orderDue"
                [timeLabels]="orderSummary.timeUnitLabels"
            ></nu-order-due></strong>
            <strong class="secondary-text">{{ orderSummary.orderType }} {{orderSummary.number}}</strong>
        </div>
    </div>
    <div responsive-class class="details">
        <strong><nu-order-item-count responsive-class 
            class="item-count"
            [label]="orderSummary.itemCountLabel" 
            [count]="orderSummary.itemCount">
        </nu-order-item-count></strong>
        <nu-order-total responsive-class 
            class="order-total" 
            [total]="orderSummary.orderTotal">
        </nu-order-total>
    </div>
</ng-container>
<ng-container *ngIf="orderSummary.statusCode !== 'OPEN'">
    <nu-order-header
        [orderNumber]="orderSummary.number"
        [orderType]="orderSummary.orderType"
        [iconName]="orderSummary.orderTypeIcon"
        [customer]="orderSummary.customer"
    ></nu-order-header>
    <div responsive-class class="details">
        <strong><nu-order-item-count responsive-class
            class="item-count"
            [label]="orderSummary.itemCountLabel" 
            [count]="orderSummary.itemCount">
        </nu-order-item-count></strong>
        <nu-order-due responsive-class
            *ngIf="orderSummary.orderDue"
            [label]="orderSummary.orderDueLabel"
            [dueDate]="orderSummary.orderDue"
            [timeLabels]="orderSummary.timeUnitLabels"
        ></nu-order-due>
    </div>
</ng-container>
<ng-content></ng-content>