<app-dialog-header></app-dialog-header>
<p class="price-warn muted-color">{{screen.oldPriceWarning}}</p>
<p class="price-warn muted-color">{{screen.excludeFromPromoWarning}}</p>
<div class="content" mat-dialog-content>
    <ng-container *ngIf="screen.items && screen.items.length > 0; else noItemsWarning">
        <mat-card *ngFor="let item of screen.items" class="sale-item">
            <h3 class="item-name">{{item.name}}</h3>
            <div class="sale-item-content">
                <div class="return-info return-color" *ngIf="item.returnItemLabels">
                    <app-icon [iconName]="item.isOrderItem ? 'assignment': 'receipt'"></app-icon>
                    <ul responsive-class class="return-info-text">
                        <li *ngFor="let label of item.returnItemLabels">
                            {{label.label}} {{label.value}}
                        </li>
                    </ul>
                </div>
                <div class="details">
                    <ul class="details-list">
                        <li *ngFor="let label of item.additionalLabels">
                            <span *ngIf="label.label">{{label.label}}: </span>{{label.value}}
                        </li>
                    </ul>
                    <div>{{item.sellingPrice}}</div>
                    <div *ngIf="item.sellingPrice !== item.originalPrice" class="st">{{item.originalPrice}}</div>
                </div>
            </div>
            <mat-button-toggle-group class="sale-discount-group" [(ngModel)]="item.classifier">
                <mat-button-toggle *ngFor="let discount of getDiscountButtons(item)"
                                   [value]="discount.classifier"
                                   class="sale-discount-button">
                    {{discount.label}}
                </mat-button-toggle>
            </mat-button-toggle-group>
            <div class="exclude-promo">
                <mat-checkbox class="exclude-promo-checkbox"
                              *ngIf="screen.excludeFromPromoEnabled"
                              [disabled]="!item.excludedFromPromoCheckboxEnabled"
                              [(ngModel)]="item.excludedFromPromo"
                              (ngModelChange)="onExcludeFromPromoToggle(item)">
                    {{screen.excludeFromPromotionsText}}
                </mat-checkbox>
            </div>
        </mat-card>
    </ng-container>
    <ng-template #noItemsWarning>
        <p>{{screen.noItemsWarning}}</p>
    </ng-template>
</div>
<div mat-dialog-actions class="buttons">
    <app-primary-button *ngIf="screen.okAction" [mat-dialog-close]="true" (buttonClick)="complete()">
        {{screen.okText}}
    </app-primary-button>
</div>
