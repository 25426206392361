import { Component, ElementRef, HostBinding, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'nu-report-view',
    templateUrl: './report-view.component.html',
    // Keep the custom report styles from affecting the rest of the page
    encapsulation: ViewEncapsulation.ShadowDom
})
export class ReportViewComponent implements OnChanges {
    @Input()
    report: string;
    @Input() @HostBinding('attr.small-screen') showSmallScreenReport;
    sanitizedReport: SafeHtml;

    constructor(public elementRef: ElementRef, public domSanitizer: DomSanitizer) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        // Keep the styles in the HTML content
        this.sanitizedReport = this.domSanitizer.bypassSecurityTrustHtml(this.report);
    }
}
