<nu-central-bacon-strip class="bacon-strip">
    <nu-central-local-nav-strip class="local-nav-strip">
        <div class="content-container">
            <mat-card class="params" responsive-class>
                <h3 class="params-title">
                    <app-icon iconClass="params-title-icon md" iconName="Filter_List"></app-icon>
                    <span>{{screen.paramsTitle}}</span>
                </h3>
                <app-dynamic-form-part (formInit)="onFilterFormInit($event)"
                             (formChanges)="onFilterFormChanges($event)"
                             formName="paramsForm">
                </app-dynamic-form-part>
                <div class="buttons" responsive-class>
                    <app-primary-button (buttonClick)="onApplyParams(screen.applyParamsButton)" [disabled]="isParamsFormInvalid">
                        {{screen.applyParamsButton.title}}
                    </app-primary-button>
                    <app-secondary-button (buttonClick)="doAction(screen.clearParamsButton)" [disabled]="!screen.clearParamsButton.enabled">
                        {{screen.clearParamsButton.title}}
                    </app-secondary-button>
                    <app-secondary-button (buttonClick)="doAction(screen.clearBasketButton)" [disabled]="!screen.clearBasketButton.enabled">
                        {{screen.clearBasketButton.title}}
                    </app-secondary-button>
                </div>
            </mat-card>
            <div class="content">
                <ng-container *ngTemplateOutlet='getContentView()'></ng-container>
            </div>
        </div>
    </nu-central-local-nav-strip>
</nu-central-bacon-strip>

<ng-template #viewMessage>
    <h3 class="muted-color params-message"
        *ngIf="screen.paramsMessage"
        [@slideLeft]>
        {{screen.paramsMessage}}
    </h3>
</ng-template>

<ng-template #viewSale>
    <div class="sale-body" responsive-class>
        <div class="sale-items-outer">
            <app-scan-or-search class="scan-or-search mat-elevation-z1" [defaultAction]="screen.checkoutButton" [focusInitial]="true" id="scan-or-search">
            </app-scan-or-search>

            <app-sale-item-card-list [ngClass]="{'is-empty': isEmpty}" (itemsChanged)="onItemsChanged($event)" class="sale-list-background"></app-sale-item-card-list>

            <app-status-strip></app-status-strip>
        </div>
        <app-sale-total-panel class="sale-total-panel"></app-sale-total-panel>
    </div>
</ng-template>