import { Component, Input } from '@angular/core';

@Component({
    selector: 'nu-order-header-label',
    templateUrl: './order-header-label.component.html',
    styleUrls: ['./order-header-label.component.scss']
})
export class OrderHeaderLabelComponent {

    @Input()
    iconName: string;

    @Input()
    headerText: string;

    @Input()
    orderType: string;

    @Input()
    orderNumber: string;

}
