<section class="order-section">
    <div class="order-section-header">
        <h5 responsive-class>{{section.title}}</h5>
        <button *ngIf="section.navigationAction" mat-button class="action-button" (click)="doAction(section.navigationAction)">
            <strong [ngClass]="{'muted-color': ! section.navigationAction.enabled}">{{section.navigationAction.title}}</strong>
            <app-icon *ngIf="section.navigationAction.icon" [iconName]="section.navigationAction.icon"></app-icon>
        </button>
    </div>

    <app-infinite-scroll *ngIf="displayAllOrders" responsive-class
        [dataKey]="section.dataKey" [itemTemplate]="orderTemplate"
        [orientation]="orientation" [ngClass]="orientation"
        [itemClass]="orientation" [listClass]="'no-margin'"
        virtualScrollMinBufferPx="700" virtualScrollMaxBufferPx="700" [itemHeightPx]="90">
        <ng-template #orderTemplate let-item>
            <nu-order-summary responsive-class class="order" [ngClass]="orientation" (click)="doAction(selectOrderAction, item.number)"
                [orderSummary]="item" [actionIcon]="selectOrderAction.icon"></nu-order-summary>
        </ng-template>
    </app-infinite-scroll>
    <div *ngIf="!displayAllOrders" responsive-class class="top-summaries">
        <nu-order-summary *ngFor="let item of section.topSummaries" responsive-class 
        class="order" [ngClass]="orientation" (click)="doAction(selectOrderAction, item.number)"
        [orderSummary]="item" [actionIcon]="selectOrderAction.icon"></nu-order-summary>
    </div>

    <button *ngIf="(isMobile$ | async) && shouldDisplayFilterAction()" mat-button (click)="filterClicked.emit(section.filterAction.action)">
        {{section.filterAction.title}}
    </button>

</section>