<div id="picker"></div>
<nu-central-bacon-strip *ngIf="screen.hasOwnProperty('centralBaconStrip')" class="fill-height">
    <nu-central-local-nav-strip class="local-nav-strip">
        <div class="deployments-outer">
            <div class="deployments-top" responsive-class>
                <div class="deployments-heading">
                    <app-icon [iconName]="'location_on'" iconClass="mat-36"></app-icon>
                    <span class="deployments-heading-text">Deployments</span>
                </div>
                <div class="deployments-versions">
                    <div class="deployments-versions-category">
                        <div class="deployments-versions-heading">
                            <div>Deployed</div>
                        </div>
                        <div class="deployments-versions-list">
                            <app-primary-button *ngFor="let version of screen.deployedVersions" (click)="onUpgradeFrom(version)" class={{convertToIdString(version)}}>{{version}}</app-primary-button>
                        </div>
                    </div>
                    <div class="deployments-versions-category">
                        <div class="deployments-versions-heading">
                            <div>Available</div>
                        </div>
                        <div *ngIf="screen.availableVersions.length > 0" class="deployments-versions-list">
                            <app-primary-button *ngFor="let version of screen.availableVersions" (click)="onUpgradeTo(version)" class={{convertToIdString(version)}}>{{version}}</app-primary-button>
                        </div>
                        <div *ngIf="screen.availableVersions.length === 0" class="deployments-versions-list">
                            <app-primary-button class="up-to-date" [disabled]="true">Up To Date</app-primary-button>
                        </div>
                    </div>
                </div>
                <div class="deployments-map">
                    <div class="search-box">
                        <nu-central-store-map-filter [storeList]="this.storeVersionList"
                            (storeSelected)="onStoreSelected($event)"></nu-central-store-map-filter>
                    </div>
                    <google-map #deploymentMap [options]="options" [center]="center" [zoom]="zoom" width="100%"
                        height="100%">
                    </google-map>
                </div>
            </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="center start">
            <div fxFlex="30">

            </div>
            <div fxFlex="30"></div>
        </div>
    </nu-central-local-nav-strip>
</nu-central-bacon-strip>