import { Component, Injector } from '@angular/core';
import { PosScreenDirective, ScreenComponent } from '@jumpmind/openpos-client-core-lib';
import { PartyInprogressInterface } from './party-inprogress.interface';
import { timer } from 'rxjs';

@ScreenComponent({
  name: 'PartyInprogress'
})
@Component({
  selector: 'app-party-inprogress',
  templateUrl: './party-inprogress.component.html',
  styleUrls: ['./party-inprogress.component.scss']
})
export class PartyInprogressComponent extends PosScreenDirective<PartyInprogressInterface> {

  timeLeft: number = 0;
  interval;
  subscribeTimer: any;

  constructor(injector: Injector) {
    super(injector);
    this.oberserableTimer();
  }

  oberserableTimer() {
    const source = timer(1000, 1000);
    const abc = source.subscribe(val => {
      if (this.timeLeft === 0) {
        this.timeLeft =  this.screen.data.partyTimerStart;
      }
      
      this.timeLeft++;
      let minutes: number = Math.floor(this.timeLeft/60);
      let seconds: number = this.timeLeft%60;
      var minutesString : string = '' + minutes;
      var secondsString : string = '' + seconds;
      if (minutes < 10) {
        minutesString = '0' + minutesString;
      }
      if (seconds < 10) {
        secondsString = '0' + secondsString;
      }


      this.subscribeTimer = minutesString + ':' + secondsString;
    });
  }

  buildScreen() { }

}
