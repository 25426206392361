import { Component, Injector, ViewChild } from '@angular/core';
import { PosScreenDirective, ScreenComponent } from '@jumpmind/openpos-client-core-lib';
import { start } from 'repl';
import { DashboardInterface } from './dashboard.interface';

@ScreenComponent({
    name: 'Dashboard'
})
@Component({
    selector: 'nu-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent extends PosScreenDirective<DashboardInterface> {

  @ViewChild('storestatusmap') storeStatusMap;

  zoom = 4
  center: google.maps.LatLngLiteral
  currentlyOpenInfoWindow: google.maps.InfoWindow;
  options: google.maps.MapOptions = {
    mapTypeId: 'roadmap',
    streetViewControl: false,
    mapTypeControl: false,
    zoomControl: true,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    maxZoom: 20,
    minZoom: 2,
    styles: [
      {
        "featureType": "administrative.province",
        "elementType": "labels",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },     
      {
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#FAFAF8"
          }
        ]
      },
      {
        "elementType": "geometry.stroke",
        "stylers": [
          {"color": "#D4DADC"},
          {"visibility": "on"},
          {"weight": 1 }
        ]
      },      
      {
        "elementType": "labels.icon",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#94A1AA"
          }
        ]
      },
      {
        "elementType": "labels.text.stroke",
        "stylers": [
          {
            "color": "#f5f5f5"
          }
        ]
      },
      {
        "featureType": "administrative",
        "elementType": "geometry",
        "stylers": [
          {
            "visibility": "on"
          }
        ]
      },
      {
        "featureType": "administrative.land_parcel",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#bdbdbd"
          }
        ]
      },
      // {
      //   "featureType": "poi",
      //   "stylers": [
      //     {
      //       "visibility": "off"
      //     }
      //   ]
      // },
      {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#ffffff"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "labels.icon",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "road.arterial",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#757575"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#dadada"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#94A1AA"
          }
        ]
      },
      {
        "featureType": "road.local",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#9e9e9e"
          }
        ]
      },
      {
        "featureType": "transit",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
       {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#D4DADC"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#9e9e9e"
          }
        ]
      }
    ]    
  }

  constructor(injector: Injector) {
    super(injector);
  }

    buildScreen(): any {
    }

  ngOnInit() {
    // var geocoder = new google.maps.Geocoder();

    this.center = { lat: 39.048, lng: -95.673 };

    // geocoder.geocode({'region': 'US'}, function (results, status) {
    //    var ne = results[0].geometry.viewport.getNorthEast();
    //    var sw = results[0].geometry.viewport.getSouthWest();

    //    this.storeStatusMap.fitBounds(results[0].geometry.viewport);               
    // }); 
    // navigator.geolocation.getCurrentPosition((position) => {
    //   this.center = {
    //     lat: position.coords.latitude,
    //     lng: position.coords.longitude,
    //   }
    // })
  }
  ngAfterViewInit(): void {

    let markers = []

    var infowindow = new google.maps.InfoWindow({
      // content: contentString,
    });    

    this.screen.storeStatusList.forEach( (storeStatus: any) => {
      var icon = "";
      if (storeStatus.status === "OFFLINE") {
          icon = "offline-marker.png";
      } else if (storeStatus.status === "ERROR") {
        icon = "error-marker.png";
      } else if (storeStatus.brand === "DT") {
        icon = "dt-marker.png";
      } else  {
        icon = "fd-marker.png";
      }


      let marker = new google.maps.Marker({
        position:{lat: +storeStatus.latitude, lng: +storeStatus.longitude},
        map:this.storeStatusMap.googleMap,//Map that we need to add
        icon: "http://localhost:6140/" + icon,
        title: storeStatus.storeName,
        draggable: false// If set to true you can drag the marker
     });
     marker.addListener("click", () => {
      const contentString = '<div><div><b>Store Number:</b> ' + storeStatus.storeNumber +
      '</div><div><b>Name:</b> ' + storeStatus.storeName +
      '</div><div><b>Brand:</b> ' + storeStatus.brand +
      '</div><div><b>Address:</b> ' + storeStatus.address +
      '</div><div><b>Version:</b> ' + storeStatus.version +
      '</div><div><b>Deploying:</b> ' + storeStatus.deploying +
      '</div><div><b>Status:</b> ' + storeStatus.status +
      '</div>';
      if (this.currentlyOpenInfoWindow) {
        this.currentlyOpenInfoWindow.close();
      }
      infowindow.setContent(contentString);
      this.currentlyOpenInfoWindow = infowindow;
      infowindow.open(this.storeStatusMap.googleMap, marker);
    });
    });

   let map = this.storeStatusMap;
  }
  zoomIn() {
    if (this.zoom < this.options.maxZoom) this.zoom++
  }

  zoomOut() {
    if (this.zoom > this.options.minZoom) this.zoom--
  }  

  onPanelClicked(item: any, event: any): void {
    this.doAction(item, item.itemId);
}    

  
}
