import { Component } from '@angular/core';
import { ScreenPart, ScreenPartComponent } from '@jumpmind/openpos-client-core-lib';
import { CentralBaconStripInterface } from './central-bacon-strip.interface';

@ScreenPart({
    name: 'centralBaconStrip'
})
@Component({
    selector: 'nu-central-bacon-strip',
    templateUrl: './central-bacon-strip.component.html',
    styleUrls: ['./central-bacon-strip.component.scss']
})
export class CentralBaconStripComponent extends ScreenPartComponent<CentralBaconStripInterface> {

    public selectedScreen: string;

    screenDataUpdated() {
        this.selectedScreen = this.screenData.tabs.selectedAction;
    }
}
