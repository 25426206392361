import { Component } from '@angular/core';
import { PosScreenDirective, DialogComponent } from '@jumpmind/openpos-client-core-lib';
import { OrderChooseAddressDialogInterface } from './order-choose-address-dialog.interface';

@DialogComponent({
    name: 'ChooseOrderAddress'
})
@Component({
    selector: 'nu-order-choose-address-dialog',
    templateUrl: './order-choose-address-dialog.component.html',
    styleUrls: ['./order-choose-address-dialog.component.scss']
})
export class OrderChooseAddressDialogComponent extends PosScreenDirective<OrderChooseAddressDialogInterface> {

    buildScreen() { }
}
