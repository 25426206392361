import { Component, ElementRef, Injector, ViewChild } from '@angular/core';
import { DialogComponent, PosScreenDirective } from '@jumpmind/openpos-client-core-lib';
import { GiftReceiptValueCheckMessage } from './gift-receipt-value-check.message';

@DialogComponent({
    name: 'GiftCodeChecker'
})
@Component({
    templateUrl: './gift-receipt-value-check.component.html',
    styleUrls: ['./gift-receipt-value-check.component.scss']
})
export class GiftReceiptValueCheckComponent extends PosScreenDirective<GiftReceiptValueCheckMessage> {
    @ViewChild('inputGiftCode') giftInput: ElementRef;

    constructor(injector: Injector) {
        super(injector);
    }

    buildScreen() {
    }

    onGiftKeyValidator(event: KeyboardEvent): boolean {
        const allowedKeys = new Map<string, string>(Object.entries(JSON.parse(this.screen.unmaskCode)));
        return allowedKeys.has(event.key) || allowedKeys.has(event.key.toUpperCase());
    }

    clickShowValue(): void {
        if (this.screen.showValueAction) {
            this.doAction(this.screen.showValueAction, this.giftInput.nativeElement.value);
        }
    }
}
