<nu-central-bacon-strip class="bacon-strip">
    <nu-central-local-nav-strip class="local-nav-strip">
        <div class="content-container">
            <mat-card class="side-nav-options" responsive-class>
                <button *ngFor="let option of screen.configOptions.actionItems" 
                    (click)="doAction(option)"
                    [ngClass]="{'selected': isSelectedOption(option)}" 
                    class="option" 
                    mat-flat-button>
                    {{option.title}}
                </button>
            </mat-card>
            <div class="content" responsive-class>
                <div class="search-and-session-info">
                    <div class="scan-or-search">
                        <app-scan-or-search class="mat-elevation-z1" [focusInitial]="false"></app-scan-or-search>
                        <div *ngIf="screen.filterString" class="filter">
                            <i>{{screen.filterString}}</i>
                            <app-icon-button *ngIf="screen.clearFilterAction" 
                                [iconName]="screen.clearFilterAction?.icon" 
                                [iconClass]="'mat-18'"
                                (buttonClick)="doAction(screen.clearFilterAction)">
                            </app-icon-button>
                        </div>
                    </div>
                    <div class="session-info">
                        <div *ngIf="screen.configSession" class="muted-color config-session">
                            <app-icon [iconName]="screen.configSession.icon"></app-icon>
                            <div class="config-session-details">
                                <p>{{screen.configSession.configSessionName}}</p>
                                <p>{{screen.configSession.configSessionUpdateTime}}</p>
                                <p>{{screen.configSession.configSessionUpdateFor}}</p>
                            </div>
                        </div>
                        <app-primary-button responsive-class
                            *ngIf="screen.configSessionAction"
                            [disabled]="!screen.configSessionAction.enabled"
                            (actionClick)="doAction(screen.configSessionAction)"
                            (click)="doAction(screen.configSessionAction)">
                            {{screen.configSessionAction.title}}
                        </app-primary-button>
                    </div>
                </div>
                <mat-card>
                    <app-title [title]="screen.title"></app-title>
                    <div class="table">
                        <app-grid-table [shadeAlternating]="true" [rows]="screen.tableRows" [columnHeaders]="screen.tableColumnHeaders"></app-grid-table>
                    </div>
                </mat-card>
            </div>
        </div>
    </nu-central-local-nav-strip>
</nu-central-bacon-strip>