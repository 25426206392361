import { Component, Injector, TemplateRef, ViewChild } from '@angular/core';
import { OpenposMediaService, PosScreenDirective, ScreenComponent } from '@jumpmind/openpos-client-core-lib';
import { Observable } from 'rxjs';
import { IOrderItemKey } from '../interfaces/order-item.interface';
import { OrderBatchPickInterface } from './order-batch-pick.interface';

@ScreenComponent({
    name: 'OrderBatchPick'
})
@Component({
    selector: 'nu-order-batch-pick',
    templateUrl: './order-batch-pick.component.html',
    styleUrls: ['./order-batch-pick.component.scss']
})
export class OrderBatchPickComponent extends PosScreenDirective<OrderBatchPickInterface> {

    @ViewChild('byOrder') byOrderTemplate: TemplateRef<any>;
    @ViewChild('byItem') byItemTemplate: TemplateRef<any>;
    @ViewChild('byItemLocation') byItemLocationTemplate: TemplateRef<any>;

    isMobile$: Observable<boolean>;

    public groupingSelectedVal: string;
    public deliveryMethodSelectedVal: string;

    constructor(injector: Injector, media: OpenposMediaService) {
        super(injector);
        this.isMobile$ = media.mediaObservableFromMap(new Map([
            ['xs', true],
            ['sm', true],
            ['md', false],
            ['lg', false],
            ['xl', false]
        ]));
    }

    buildScreen(): void {
        if (this.screen.deliveryMethodFilter.selectedAction) {
            this.deliveryMethodSelectedVal = this.screen.deliveryMethodFilter.selectedAction;
        }
        if (this.screen.groupingTypeFilter.selectedAction) {
            this.groupingSelectedVal = this.screen.groupingTypeFilter.selectedAction;
        }
    }

    public getGroupingSelectedTemplate(): TemplateRef<any> {
        if (this.groupingSelectedVal === 'GroupingFilterByItem') {
            return this.byItemTemplate;
        } else if (this.groupingSelectedVal === 'GroupingFilterByLocation') {
            return this.byItemLocationTemplate;
        } else {
            return this.byOrderTemplate;
        }
    }

    public onGroupingSelected(val: string): void {
        this.groupingSelectedVal = val;
        this.doAction(val);
    }

    public onDeliveryMethodValChanged(val: string): void {
        this.deliveryMethodSelectedVal = val;
        this.doAction(val);
    }

    onItemClick(orderItemKey: IOrderItemKey): void {
        this.actionService.doAction(this.screen.listChangedAction, orderItemKey);
    }
}
