<app-bacon-strip class="header"></app-bacon-strip>
<div class="content-container" responsive-class>
    <mat-card class="report-card"
              *ngIf="screen.renderedReport">
        <nu-report-view [report]="screen.renderedReport"></nu-report-view>
        <mat-card-actions responsive-class class="action-buttons" align="end">
            <app-secondary-button class="refresh-button" *ngIf="screen.refreshReportButton" (buttonClick)="doAction(screen.refreshReportButton)">
                {{screen.refreshReportButton.title}}
            </app-secondary-button>
            <app-primary-button class="print-button" *ngIf="screen.printReportButton" (buttonClick)="printReport()">
                {{screen.printReportButton.title}}
            </app-primary-button>
        </mat-card-actions>
    </mat-card>
</div>