import { Component } from '@angular/core';
import { ScreenPart, ScreenPartComponent } from '@jumpmind/openpos-client-core-lib';
import { CentralLocalNavStripInterface } from './central-local-nav-strip.interface';

@ScreenPart({
    name: 'centralLocalNavStrip'
})
@Component({
    selector: 'nu-central-local-nav-strip',
    templateUrl: './central-local-nav-strip.component.html',
    styleUrls: ['./central-local-nav-strip.component.scss']
})
export class CentralLocalNavStripComponent extends ScreenPartComponent<CentralLocalNavStripInterface> {

    screenDataUpdated() {
    }

}
