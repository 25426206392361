<form>
    <mat-form-field class="example-full-width form-field" appearance="fill">
      <mat-label>Search</mat-label>
      <input type="text" matInput [formControl]="myControl" [matAutocomplete]="auto">
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onStoreSelected($event.option.value)">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
          {{option.storeName}} ({{option.storeNumber}}) - {{option.address}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>