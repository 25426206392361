import { Component, Injector, TemplateRef, ViewChild } from '@angular/core';
import { OpenposMediaService, PosScreenDirective, ScreenComponent } from '@jumpmind/openpos-client-core-lib';
import { Observable } from 'rxjs';
import { IOrderItemKey } from '../interfaces/order-item.interface';
import { OrderType } from '../interfaces/order-type.enum';
import { OrderPackInterface } from './order-pack.interface';

@ScreenComponent({
    name: 'OrderPack'
})
@Component({
    selector: 'nu-order-pack',
    templateUrl: './order-pack.component.html',
    styleUrls: ['./order-pack.component.scss']
})
export class OrderPackComponent extends PosScreenDirective<OrderPackInterface> {

    @ViewChild('packageView') packageTemplate: TemplateRef<any>;
    @ViewChild('nonPackageView') nonPackageTemplate: TemplateRef<any>;

    isMobile$: Observable<boolean>;

    constructor(injector: Injector, media: OpenposMediaService) {
        super(injector);
        this.isMobile$ = media.mediaObservableFromMap(new Map([
            ['xs', true],
            ['sm', true],
            ['md', false],
            ['lg', false],
            ['xl', false]
        ]));
    }

    buildScreen(): void {
    }

    public getViewTemplate(): TemplateRef<any> {
        const orderType = this.screen.orderSummary.orderType;
        if (OrderType.SFS === orderType) {
            return this.packageTemplate;
        } else {
            return this.nonPackageTemplate;
        }
    }

    public canItemsBeSelected(): boolean {
        const orderType = this.screen.orderSummary.orderType;
        if (OrderType.SFS === orderType) {
            return this.hasPackages();
        }
        return true;
    }

    public hasUnpackedItems(): boolean {
        return this.screen.unpackedItems && this.screen.unpackedItems.length > 0;
    }

    public hasPackages(): boolean {
        return this.screen.packages && this.screen.packages.length > 0;
    }

    public onItemClick(orderItemKey: IOrderItemKey): void {
        this.doAction(this.screen.listChangedAction, orderItemKey);
    }

}
