import { ManageUsersInterface } from './manage-users.interface';
import { ScreenComponent, PosScreenDirective, UIDataMessageService, OpenposMediaService, MediaBreakpoints } from '@jumpmind/openpos-client-core-lib';
import { Component, ViewChild, Injector } from '@angular/core';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { UserResult } from './user-result';
import { Observable } from 'rxjs';

@ScreenComponent({
    name: 'ManageUsers'
})
@Component({
    selector: 'nu-manage-users',
    templateUrl: './manage-users.component.html',
    styleUrls: ['./manage-users.component.scss']
})
export class ManageUsersComponent extends PosScreenDirective<ManageUsersInterface> {

    @ViewChild(CdkVirtualScrollViewport, { static: false })
    scrollViewPort: CdkVirtualScrollViewport;

    results$: Observable<UserResult[]>;

    isMobile: Observable<boolean>;

    constructor(injector: Injector, private resultsService: UIDataMessageService, private media: OpenposMediaService) {
        super(injector);

        this.isMobile = media.observe(new Map([
            [MediaBreakpoints.MOBILE_PORTRAIT, true],
            [MediaBreakpoints.MOBILE_LANDSCAPE, true],
            [MediaBreakpoints.TABLET_PORTRAIT, false],
            [MediaBreakpoints.TABLET_LANDSCAPE, false],
            [MediaBreakpoints.DESKTOP_PORTRAIT, false],
            [MediaBreakpoints.DESKTOP_LANDSCAPE, false]
        ]));
    }

    buildScreen() {
        this.results$ = this.resultsService.getData$(this.screen.resultsDataKey);
    }

    resultsTrackFn(index: number, item: UserResult) {
        return item.id;
    }
}
