<div class="container mat-elevation-z5" responsive-class>
    <div class="logo-section">
        <img *ngIf="screenData?.logo" [src]="screenData.logo | imageUrl" class="screenlogo" alt="screen logo">
    </div>

    <mat-button-toggle-group class="tabs" #group="matButtonToggleGroup" [value]="selectedScreen">
            <mat-button-toggle responsive-class *ngFor="let button of screenData.tabs.actionItems"
                class="text-sm" [value]="button.action" (click)="doAction(button.action, button.defaultPayload)">
                <span>{{button.title}}</span>
            </mat-button-toggle>
    </mat-button-toggle-group>
    <div *ngIf="screenData?.operatorLine1" class="rightside">
        <p class="operator-name">{{screenData.operatorLine1}}</p>
        <p *ngIf="screenData?.operatorLine2" class="operator-id">{{screenData.operatorLine2}}</p>
        <app-kebab-button class="bacon-strip-icon"
                          *ngIf="screenData.operatorMenu"
                          [iconName]="screenData.operatorIcon"
                          [menuItems]="screenData.operatorMenu"
                          (menuItemClick)="doAction($event)"
                          style="width: 32px;padding-right: 12px;">
        </app-kebab-button>
        <app-icon class="bacon-strip-icon"
                  *ngIf="!screenData.operatorMenu"
                  [iconName]="screenData.operatorIcon"
                  
                  style="width: 28px;">
        </app-icon>
    </div>
</div>
<ng-content></ng-content>