import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { IShippingMethod } from '../../order-review/shipping-method.interface';
import { MediaBreakpoints, OpenposMediaService } from '@jumpmind/openpos-client-core-lib';

@Component({
    selector: 'nu-shipping-method',
    templateUrl: './shipping-method.component.html',
    styleUrls: ['./shipping-method.component.scss']
})
export class ShippingMethodComponent {

    @Input()
    item: IShippingMethod;

    @Input()
    classes = '';

    @Input()
    selectable: boolean;

    isMobile$: Observable<boolean>;

    constructor(private media: OpenposMediaService) {
        this.initIsMobile();
    }

    initIsMobile(): void {
        this.isMobile$ = this.media.observe(new Map([
            [MediaBreakpoints.MOBILE_PORTRAIT, true],
            [MediaBreakpoints.MOBILE_LANDSCAPE, true],
            [MediaBreakpoints.TABLET_PORTRAIT, true],
            [MediaBreakpoints.TABLET_LANDSCAPE, false],
            [MediaBreakpoints.DESKTOP_PORTRAIT, false],
            [MediaBreakpoints.DESKTOP_LANDSCAPE, false]
        ]));
    }


    getIconName(): string {
        if (this.item.selected) {
            return 'check_circle';
        } else if (this.selectable) {
            return 'empty_circle';
        }
        return '';
    }

    getIconClass(): string {
        let iconClass = 'sm';
        if (this.item.selected) {
            iconClass += ' inverse';
        }
        return iconClass;
    }
}
