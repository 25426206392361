import {
    DiscoveryService,
    IPlatformPlugin, MessageTypes,
    PersonalizationService,
    SessionService
} from '@jumpmind/openpos-client-core-lib';
import { Injectable } from '@angular/core';
import { IDeployConfig, DeployPluginAPI } from 'auto-deploy/dist/IonicCordova';
import { Observable, of, concat } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AutoDeployPlugin implements IPlatformPlugin {

    deployApi: DeployPluginAPI;

    constructor(
        private personalizationService: PersonalizationService,
        private discoveryService: DiscoveryService,
        private sessionService: SessionService
    ) { }

    name(): string {
        return 'Auto Deploy Plugin';
    }

    pluginPresent(): boolean {
        return window.hasOwnProperty('IonicCordova') && window['IonicCordova'].hasOwnProperty('deploy');
    }

    initialize(): Observable<string> {
        return concat(
            of(this.init()).pipe(map(() => 'Auto Deploy initialized')),
            of(this.configure()).pipe(map(() => 'Auto Deploy Configured')),
            of(this.sync()).pipe(map(() => 'Auto Deploy Syncd'))
        ) as Observable<string>;
    }

    private async init() {
        this.deployApi = window.IonicCordova.deploy;
        this.personalizationService.getPersonalizationSuccessful$().
            pipe(tap(() => console.log('Personalization Updated')), filter(success => success)).
            subscribe(() => this.configure());
        this.sessionService.getMessages(MessageTypes.CONNECTED).
            pipe(tap(() => console.log('Deploy: Server Connected'))).
            subscribe(() => this.sync());
    }

    private async configure() {
        const config = await this.deployApi.getConfiguration();
        const newConfig = {} as IDeployConfig;
        newConfig.channel = config.channel;
        newConfig.appId = config.appId;
        newConfig.host = this.discoveryService.getServerBaseURL();
        newConfig.maxVersions = config.maxVersions;
        newConfig.minBackgroundDuration = config.minBackgroundDuration;
        newConfig.updateMethod = 'auto';

        await this.deployApi.configure(newConfig);
    }

    private async sync() {
        try {
            await this.deployApi.sync({ updateMethod: 'auto' });
        } catch (e) {
            console.warn(e);
            console.warn('Sync failed. Defaulting to last available version.');
        }
    }

}
