import { ScreenComponent, PosScreenDirective } from '@jumpmind/openpos-client-core-lib';
import { Component } from '@angular/core';
import { ManageTillsInterface } from './manage-tills.interface';

@ScreenComponent({
    name: 'ManageTills'
})
@Component({
    selector: 'nu-manage-tills',
    templateUrl: './manage-tills.component.html',
    styleUrls: ['./manage-tills.component.scss']
})
export class ManageTillsComponent extends PosScreenDirective<ManageTillsInterface> {
    buildScreen() {
    }
}
