import { Component, Injector } from '@angular/core';
import { PosScreenDirective, ScreenComponent } from '@jumpmind/openpos-client-core-lib';
import { PriceCheckerItemDetailInterface } from './price-checker-item-detail.interface';

@ScreenComponent({
    name: 'PriceCheckerItemDetail'
})
@Component({
    selector: 'nu-price-checker-item-detail',
    templateUrl: 'price-checker-item-detail.component.html',
    styleUrls: ['./price-checker-item-detail.component.scss']
})
export class PriceCheckerItemDetailComponent extends PosScreenDirective<PriceCheckerItemDetailInterface> {

    constructor(injector: Injector) {
        super(injector);
    }

    buildScreen() {
    }

    onPrint() {
        this.doAction(this.screen.printButton);
    }

}
