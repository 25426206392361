import { PosScreenDirective, DialogComponent, IActionItem } from '@jumpmind/openpos-client-core-lib';
import { Component } from '@angular/core';
import { OrderNoteScreenInterface } from './order-note-screen.interface';
import { IOrderNote } from '../components/order-notes/order-note.interface';

@DialogComponent({
    name: 'OrderNote'
})
@Component({
    selector: 'nu-order-note-dialog',
    templateUrl: './order-note-dialog.component.html',
    styleUrls: ['./order-note-dialog.component.scss']
})
export class OrderNoteDialogComponent extends PosScreenDirective<OrderNoteScreenInterface> {

    public selectedOption: string;
    note: string;

    buildScreen() {
        if (this.screen.noteFromOptions && this.screen.noteFromOptions.length > 0) {
            this.selectedOption = this.screen.noteFromOptions[0];
        }
    }

    onAddNote(actionButton: IActionItem) {
        const orderNote = {
            note: this.note,
            name: this.selectedOption,
        } as IOrderNote;
        this.doAction(actionButton, orderNote);
    }

}
