import { Component, Input } from '@angular/core';

@Component({
    selector: 'nu-order-item-count',
    templateUrl: './order-item-count.component.html',
    styleUrls: ['./order-item-count.component.scss']
})
export class OrderItemCountComponent {

    @Input()
    iconName: string;

    @Input()
    label: string;

    @Input()
    count: string;

}
