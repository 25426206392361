import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { IStoreStatus } from '../../store-status/store-status.interface';

@Component({
  selector: 'nu-central-store-map-filter',
  templateUrl: './central-store-map-filter.component.html',
  styleUrls: ['./central-store-map-filter.component.scss']
})
export class CentralStoreMapFilterComponent implements OnInit {


  @Input()
  storeList: IStoreStatus[];

  @Output()
  storeSelected = new EventEmitter<IStoreStatus>();

  myControl = new FormControl();
  filteredOptions: Observable<IStoreStatus[]>;

  constructor() { }

  ngOnInit(): void {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.name)),
      map(name => (name ? this.filter(name) : this.storeList.slice(0, 10))),
    );
  }

  displayFn(store: IStoreStatus): string {
    if (store && store.address && store.storeNumber) {
      return store.storeName + " (" + store.storeNumber + ") - " + store.address;
    } else if (store && store.storeNumber) {
      return store.storeName + " (" + store.storeNumber + ")";
    } else {
      return "";
    }
  }

  filter(name: string): IStoreStatus[] {
    const filterValue = name.toLowerCase();

    return this.storeList.filter(option => option.storeName.toLowerCase().includes(filterValue) 
    || option.address.toLowerCase().includes(filterValue)
    || option.storeNumber.toLowerCase().includes(filterValue)).slice(0, 10);
  }

  onStoreSelected(value: IStoreStatus) {
    this.storeSelected.emit(value);
  }
}

