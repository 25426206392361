<div id="picker"></div>
<nu-central-bacon-strip *ngIf="screen.hasOwnProperty('centralBaconStrip')" class="fill-height">

    <div style="height: 100%" class="dashboard-wrapper">
        <div class="server-metrics">
            <div class="server-metric1">
                <!-- <iframe src="http://localhost:3000/d-solo/Pb_MJR9nz/dt_dashboard?orgId=1&from=now-2d&to=now&refresh=5s&panelId=3" width="100%" height="90px" frameborder="0"></iframe> -->
                <iframe src="http://localhost:3000/d-solo/Pb_MJR9nz/dt_dashboard?orgId=1&from=now-2d&to=now&panelId=20refresh=5s" width="100%" height="90px" frameborder="0"></iframe>
                <!-- <iframe src="http://localhost:3000/d-solo/Pb_MJR9nz/dt_dashboard?orgId=1&from=now-2&to=now&refresh=5s&panelId=2" width="100%" height="90px" frameborder="0"></iframe> -->
            </div>        
            <div class="server-metric2">
                <iframe src="http://localhost:3000/d-solo/Pb_MJR9nz/dt_dashboard?orgId=1&from=now-2d&to=now&refresh=5s&panelId=3" width="100%" height="90px" frameborder="0"></iframe>
            </div>        
            <div class="server-metric3">
                <iframe src="http://localhost:3000/d-solo/Pb_MJR9nz/dt_dashboard?orgId=1&from=now-2d&to=now&refresh=5s&panelId=16" width="100%" height="90px" frameborder="0"></iframe>
            </div>        
            <div class="server-metric4">
                <iframe src="http://localhost:3000/d-solo/Pb_MJR9nz/dt_dashboard?orgId=1&from=now-2d&to=now&refresh=5s&panelId=4" width="100%" height="90px" frameborder="0"></iframe>
            </div>        
            <div class="server-metric5">
                <iframe src="http://localhost:3000/d-solo/Pb_MJR9nz/dt_dashboard?orgId=1&from=now-2d&to=now&refresh=5s&panelId=5" width="100%" height="90px" frameborder="0"></iframe>
            </div>              
            <div class="server-metric6">
                <iframe src="http://localhost:3000/d-solo/Pb_MJR9nz/dt_dashboard?orgId=1&from=now-2d&to=now&refresh=5s&panelId=6" width="100%" height="90px" frameborder="0"></iframe>
            </div>              
            
                       
        </div>                 

        <div class="server-levels">
            <iframe src="http://localhost:3000/d-solo/Pb_MJR9nz/dt_dashboard?orgId=1&from=now-2d&to=now&refresh=5s&panelId=18" width="100%" height="100%" frameborder="0"></iframe>
        </div>   
        
        <div class="store-status-map">
        <div class="panel-header">Chain Status</div>
        <google-map #storestatusmap
        height="400px"
        width="100%"
        [zoom]="zoom"
        [center]="center"
        [options]="options"></google-map>
        </div>
        
        <div class="store-operations-performance">
            <iframe name="store-operations-performance" src="http://localhost:3000/d-solo/Pb_MJR9nz/new-dashboard?orgId=1&refresh=5s&from=now-13d&to=now&panelId=19" width="100%" height="100%" frameborder="0"></iframe>
            <a class="reset-link" target="store-operations-performance" href="http://localhost:3000/d-solo/Pb_MJR9nz/new-dashboard?orgId=1&refresh=5s&from=now-13d&to=now&panelId=19" >Reset</a>
        </div>

        <div class="store-status-stats">
            <iframe src="http://localhost:3000/d-solo/Pb_MJR9nz/new-dashboard?orgId=1&from=now-2d&to=now&panelId=10" width="100%" height="100%" frameborder="0"></iframe>
        </div>        

         <div  class="deployments">
            <iframe src="http://localhost:3000/d-solo/Pb_MJR9nz/new-dashboard?orgId=1&from=1583391579000&to=1583435726000&panelId=11" width="100%" height="100%" frameborder="0"></iframe>
            <div class="deployment-overlay" (click)="onPanelClicked('Deployments', $event)">Link</div>
        </div>               
        <div class="alerts">
            <iframe src="http://localhost:3000/d-solo/Pb_MJR9nz/new-dashboard?orgId=1&from=1583408572267&to=1583428138927&panelId=13" width="100%" height="100%" frameborder="0"></iframe>
            <div class="alerts-overlay" (click)="onPanelClicked('Alerts', $event)">Link</div>
        </div>            

    
    </div>
    <div class="status-strip-container">
        <app-status-strip *ngIf="screen.statusStrip"></app-status-strip>
    </div>    
</nu-central-bacon-strip>