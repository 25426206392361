<app-bacon-strip class="promotion-bacon-strip">
    <div class="promotion-search-outer">

        <div *ngIf="screen.customer" responsive-class class="promotion-customer-outer">
            <img *ngIf="screen.customer.icon" [src]="screen.customer.icon | imageUrl" class="linked-loyalty-icon"
                responsive-class>
            <div class="promotion-customer-details">
                <div *ngIf="screen.customer.name" class="muted-color">{{screen.customer.name}}</div>
                <div *ngIf="screen.customer.label && screen.customer.id" class="muted-color">
                    {{screen.customer.label}}: {{screen.customer.id}}
                </div>
            </div>
        </div>

        <app-infinite-scroll [dataKey]="screen.providerKey" [itemTemplate]="itemTemplate"
            [noItemsTemplate]="noItemTemplate" virtualScrollMinBufferPx="200" virtualScrollMaxBufferPx="400"
            [itemHeightPx]="150">

            <ng-template #itemTemplate let-item>
                <nu-promotion-summary [promotionSummary]="item" class="promotion-summary-list">
                </nu-promotion-summary>
            </ng-template>

            <ng-template #noItemTemplate>
                <div responsive-class class="no-promos muted-color">
                    {{screen.noPromotionsText}}
                </div>
            </ng-template>

        </app-infinite-scroll>
    </div>
</app-bacon-strip>