<div class="mgmt-container">
  <app-dynamic-bacon-strip>
    <div style="display:flex;align-items:center;">
      <div style="padding-right:0.25rem;">
        <app-icon *ngIf="selectedCheckoutSummary?.helpRequested"
                  [iconName]="screen.helpNeededIcon"
                  [iconClass]="'mat-24'">
        </app-icon>
        <app-icon *ngIf="!selectedCheckoutSummary?.helpRequested && selectedCheckoutSummary?.statusIcon"
                  [iconName]="selectedCheckoutSummary?.statusIcon"
                  [iconClass]="'mat-24'">
        </app-icon>
      </div>
      <div class="header-text">{{selectedCheckoutSummary?.checkoutDevice?.name}} - {{selectedCheckoutSummary?.checkoutStatusText}}</div>
    </div>
  </app-dynamic-bacon-strip>
  <div class="mobile-checkout">
    <div *ngIf="selectedCheckoutSummary?.transactionSummary?.sellItems && !selectedCheckoutSummary?.helpRequested" class="checkout-items">
      <div class="items-outer" *ngIf="selectedCheckoutSummary?.transactionSummary?.sellItems">
        <app-item-card *ngFor="let item of selectedCheckoutSummary.transactionSummary.sellItems; index as i"
                       #items
                       [item]="item"
                       [isReadOnly]="true"
                       [expanded]="false"
                       [enableHover]="false"
                       class="sellitem-card">
        </app-item-card>
      </div>
    </div>

    <div *ngIf="selectedCheckoutSummary?.helpRequested">
      <nu-icon-instructions [instructions]="screen.helpNeededInstructions"
                            [instructionsSize]="'text-xl'" [iconName]="screen?.helpNeededIcon" iconClass="primary mat-180">
      </nu-icon-instructions>
    </div>
    <div *ngIf="!selectedCheckoutSummary?.helpRequested && (screen.noItems || !selectedCheckoutSummary?.transactionSummary?.sellItems)">
      <nu-icon-instructions [instructions]="screen.noTransactionInstructions"
                            [instructionsSize]="'text-xl'" [iconName]="screen?.noTransactionIcon" iconClass="primary mat-180">
      </nu-icon-instructions>
    </div>
  </div>

  <div class="totals">
    <app-mobile-totals-part></app-mobile-totals-part>
  </div>
</div>
