import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { IOrderItem } from '../../interfaces/order-item.interface';
import { MediaBreakpoints, OpenposMediaService } from '@jumpmind/openpos-client-core-lib';

@Component({
    selector: 'nu-order-item',
    templateUrl: './order-item.component.html',
    styleUrls: ['./order-item.component.scss']
})
export class OrderItemComponent {

    @Input()
    item: IOrderItem;

    @Input()
    classes = '';

    @Input()
    showOrderDetails = false;

    @Input()
    selectable: boolean;

    isMobile$: Observable<boolean>;

    constructor(private media: OpenposMediaService) {
        this.initIsMobile();
    }

    initIsMobile(): void {
        this.isMobile$ = this.media.observe(new Map([
            [MediaBreakpoints.MOBILE_PORTRAIT, true],
            [MediaBreakpoints.MOBILE_LANDSCAPE, true],
            [MediaBreakpoints.TABLET_PORTRAIT, true],
            [MediaBreakpoints.TABLET_LANDSCAPE, false],
            [MediaBreakpoints.DESKTOP_PORTRAIT, false],
            [MediaBreakpoints.DESKTOP_LANDSCAPE, false]
        ]));
    }


    getIconName(): string {
        if (this.item.checked) {
            return 'check_circle';
        } else if (this.selectable) {
            return 'empty_circle';
        }
        return '';
    }

    getIconClass(): string {
        let iconClass = 'sm';
        if (this.item.checked) {
            iconClass += ' inverse';
        }
        return iconClass;
    }
}
