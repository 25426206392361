<app-bacon-strip>
    <div class="page-container">
        <div *ngIf="!(isMobile$ | async) && screen.filters" class="filters-container">
            <ng-container [ngTemplateOutlet]="filtersTemplate"></ng-container>
        </div>

        <div class="main-content-container" responsive-class>
            <app-scan-or-search class="scan-search mat-elevation-z1" [focusInitial]="false" responsive-class></app-scan-or-search>
            <h3 *ngIf="!(isMobile$ | async)">{{screen.categorySelectorTitle}}</h3>
            <app-bread-crumbs *ngIf="!(isMobile$ | async)" [crumbs]="screen.breadCrumbs" class="crumbs"></app-bread-crumbs>
            <app-bread-crumbs-mobile *ngIf="isMobile$ | async" [crumbs]="screen.breadCrumbs" class="crumbs"></app-bread-crumbs-mobile>

            <div class="carousel-container mat-elevation-z1"
                *ngIf="screen.productCategories && screen.numberOfResults > 0">

                <app-carousel [itemsPerSlide]="(isMobile$ | async) ? 3 : 6" [navigationArrowsSize]="'sm'">
                    <ng-template *ngFor="let category of screen.productCategories" #carouselItem>
                        <div class="image carousel-image" (click)="categorySelected(category)">
                            <app-image [imageUrl]="category.imageUrl" [altText]="category.categoryName"
                                [altImageUrl]="screen.alternateImageUrl">
                            </app-image>
                        </div>
                        <div class="description" responsive-class>
                            {{category.categoryName}}
                        </div>
                    </ng-template>
                </app-carousel>
            </div>

            <div class="category-product-card mat-elevation-z1" responsive-class>
                <section *ngIf="screen.showResultsMessage && screen.resultsMessage" class="message">
                    <app-instructions [instructions]="screen.resultsMessage"></app-instructions>
                </section>

                <div class="category-container row" *ngIf="screen.productCategories && screen.numberOfResults === 0"
                    responsive-class>
                    <ng-container [ngTemplateOutlet]="categoriesTemplate"></ng-container>
                </div>

                <app-infinite-scroll *ngIf="screen.numberOfResults > 0"
                    [dataKey]="screen.searchResultsDataKey" [itemTemplate]="itemTemplate"
                    [listClass]="'product-container row'"
                    virtualScrollMinBufferPx="400" virtualScrollMaxBufferPx="800"
                    [itemHeightPx]="isMobile$ ? 250/2 : 200/3">

                    <ng-template #itemTemplate let-item>
                        <div class="product" (click)="productSelected(item)" responsive-class id="product{{item.id}}">
                            <div class="product-image">
                                <app-image [imageUrl]="item.imageUrl" [altText]="item.title"
                                    [altImageUrl]="screen.alternateImageUrl"></app-image>
                            </div>
                            <div class="product-info">
                                <strong class="product-title" responsive-class>{{item.title}}</strong>
                                <div class="product-id">{{screen.productIdLabel}} {{item.id}}</div>
                                <div class="product-price">{{item.price}}</div>
                            </div>
                        </div>
                    </ng-template>
                </app-infinite-scroll>
            </div>

            <app-bottom-sheet *ngIf="screen.filters && (isMobile$ | async)" [label]="screen.filtersTitle"
                class="filters-sheet" responsive-class>
                <ng-container [ngTemplateOutlet]="filtersTemplate"></ng-container>
            </app-bottom-sheet>
        </div>
    </div>
</app-bacon-strip>

<ng-template #filtersTemplate>
    <div *ngIf="!(isMobile$ | async)" class="filters-title filter-button">
        <app-icon class="filter-arrow-icon" [iconName]="'Tune'" [iconClass]="'md'"></app-icon> {{screen.filtersTitle}}
    </div>

    <hr *ngIf="!(isMobile$ | async)">

    <div *ngFor="let filter of screen.filters; index as i;" [formGroup]="filtersForm" class="filter-panel" responsive-class>
        <app-expansion-panel [expanded]="expandedFilters && expandedFilters[filter.fieldName]" id="filterPanel{{i}}"
            class="filter-expansion-panel" (expansionToggled)="filterPanelToggled($event, filter.fieldName)">

            <ng-container header>
                <span mat-flat-button class="filter-button">{{filter.displayName}}</span>
            </ng-container>

            <ng-container content>
                <div *ngFor="let option of filtersForm.get(filter.fieldName)['controls']; let i=index" id="filterOption{{filter.fieldName + filter.values[i].displayName}}">
                    <mat-checkbox (change)="filterSelected()" [formControl]="option" [checked]="option.value">
                        {{filter.values[i].displayName}}</mat-checkbox>
                </div>
            </ng-container>
        </app-expansion-panel>
        <hr>
    </div>
</ng-template>

<ng-template #categoriesTemplate>
    <ng-container *ngFor="let category of screen.productCategories; last as isLast">
        <button mat-flat-button class="category-button" id="categoryButton{{category.categoryId}}" (click)="categorySelected(category)" responsive-class>
            <span class="category-content" responsive-class>
                <div class="image" responsive-class>
                    <app-image [imageUrl]="category.imageUrl" [altText]="category.categoryName"
                        [altImageUrl]="screen.alternateImageUrl">
                    </app-image>
                </div>
                <div class="description template-description" responsive-class>{{category.categoryName}}</div>
            </span>

            <app-icon *ngIf="isMobile$ | async" [iconName]="'Forward'" class="filter-arrow-icon" [iconClass]="'md'"></app-icon>
        </button>
        <hr *ngIf="(isMobile$ | async) && !isLast">
    </ng-container>
</ng-template>
