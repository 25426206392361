<app-bacon-strip class="order-bacon-strip">
    <div class="body-container">
        <div class="header" responsive-class>
            <nu-order-header-label class="header-label" 
                [iconName]="screen.headerIcon"
                [headerText]="screen.headerLabel">
            </nu-order-header-label>
        </div>
        <div responsive-class *ngIf="screen.selectedOrder as order">
            <ng-container *ngTemplateOutlet="orderPanel; context:{order:order}"></ng-container>
        </div>
        <app-infinite-scroll *ngIf="!screen.selectedOrder" [dataKey]="screen.providerKey" [itemTemplate]="itemTemplate"
                listClass="infinite-list-with-row-gap" virtualScrollMinBufferPx="200" virtualScrollMaxBufferPx="400" [itemHeightPx]="300">
            <ng-template #itemTemplate let-order>
                <ng-container *ngTemplateOutlet="orderPanel; context:{order:order}"></ng-container>
            </ng-template>
        </app-infinite-scroll>
    </div>
</app-bacon-strip>
<app-status-strip *ngIf="!(isMobile$ | async)"></app-status-strip>

<ng-template #orderPanel let-order="order">
    <app-expansion-panel>
        <ng-container header>
            <nu-order-item-header [orderSummary]="order.orderSummary">
                <nu-order-acknowledge-button-group responsive-class 
                                                   *ngIf="!(isMobile$ | async)" 
                                                   [actionItems]="order.actionItems"
                                                   (acknowledge)="doAction($event)"></nu-order-acknowledge-button-group>
            </nu-order-item-header>
        </ng-container>
        <ng-container content>
            <nu-order-acknowledge-button-group responsive-class 
                                               *ngIf="(isMobile$ | async)" 
                                               [actionItems]="order.actionItems"
                                               (acknowledge)="doAction($event)"></nu-order-acknowledge-button-group>
            <nu-order-item-list [items]="order.orderItems" [selectable]="false"></nu-order-item-list>
        </ng-container>
    </app-expansion-panel>
</ng-template>
