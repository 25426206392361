import { Component, Injector } from '@angular/core';
import { CentralTransactionSearchInterface } from './central-transaction-search.interface';
import { takeUntil } from 'rxjs/operators';
import { merge } from 'rxjs';
import { IActionItem, IDynamicFormPartEventArg, IForm, PosScreenDirective, ScreenComponent, UIDataMessageService } from '@jumpmind/openpos-client-core-lib';
import { CentralTransactionSearchMode } from './central-transaction-search-mode.enum';

@ScreenComponent({
    name: 'CentralTransactionSearch'
})
@Component({
    selector: 'nu-central-transaction-search',
    templateUrl: './central-transaction-search.component.html',
    styleUrls: ['./central-transaction-search.component.scss']
})
export class CentralTransactionSearchComponent extends PosScreenDirective<CentralTransactionSearchInterface> {
    advancedSearchForm: IForm;
    resultsCount: number;
    changeSearchModeDisabled: boolean;
    CentralTransactionSearchMode = CentralTransactionSearchMode;

    constructor(injector: Injector, private dataMessageService: UIDataMessageService) {
        super(injector);
    }

    buildScreen() {
        this.dataMessageService.getData$(this.screen.providerKey)
            .pipe(
                takeUntil(merge(this.destroyed$, this.beforeBuildScreen$))
            )
            .subscribe(results => this.resultsCount = results.length);
        this.changeSearchModeDisabled = !this.screen.changeSearchModeButton;
    }

    showAdvancedSearchDialogButton() {
        return this.screen.advancedSearchForm && this.screen.advancedSearchForm.formElements.length > 2;
    }

    onAdvancedSearchFormChanges(event: IDynamicFormPartEventArg): void {
        this.advancedSearchForm = event.form;
    }

    filterChanged(filter: IActionItem): void {
        this.doAction(filter.action);
    }
}
