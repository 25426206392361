<div id="picker"></div>
<nu-central-bacon-strip *ngIf="screen.hasOwnProperty('centralBaconStrip')" class="fill-height">

    <div style="height: 100%" class="alerts-wrapper">

        <div class="alerts-over-time">
            <iframe src="http://localhost:3000/d-solo/vVl4Ftrnk/dt_alerts?orgId=1&from=now-2d&to=now&panelId=25&refresh=5s"  width="100%" height="100%" frameborder="0"></iframe>
        </div>
        <div class="alert-pies">
            <iframe src="http://localhost:3000/d-solo/vVl4Ftrnk/dt_alerts?orgId=1&from=now-2d&to=now&panelId=22&refresh=5s"  width="100%" height="100%" frameborder="0"></iframe>
            <iframe src="http://localhost:3000/d-solo/vVl4Ftrnk/dt_alerts?orgId=1&from=now-2d&to=now&panelId=23&refresh=5s"  width="100%" height="100%" frameborder="0"></iframe>
        </div>
        <div class="alerts-map">
            <div class="store-status-map">
                <div class="panel-header">Alerts</div>
                <google-map #storestatusmap
                height="400px"
                width="100%"
                [zoom]="zoom"
                [center]="center"
                [options]="options"></google-map>
                </div>
        </div>        
        <div class="alert-table-container">
            <table class="alert-table">
                <thead>
                  <tr>
                    <th>Time</th>
                    <th>Device</th>
                    <th>App</th>
                    <th>Category</th>
                    <th>Summary</th>
                    <th>Version</th>
                    <th>Review</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let alert of screen.alertList">
                    <th>{{ alert.alertTimestamp }}</th>
                    <td>{{ alert.deviceId }}</td>
                    <td>{{ alert.appId }}</td>                                                         
                    <td>{{ alert.alertCategory }}</td>
                    <td>{{ alert.alertSummary }}</td>
                    <td>{{ alert.softwareVersion }}</td>
                    <td>{{ alert.alertReviewStatus }}</td>
                  </tr>
                </tbody>
              </table>
        </div>            
        <div class="store-status">
            <iframe src="http://localhost:3000/d-solo/vVl4Ftrnk/dt_alerts?orgId=1&from=now-2d&to=now&panelId=10&refresh=5s"  width="100%" height="100%" frameborder="0"></iframe>
        </div>                                          
        <div class="alert-counts">
            <iframe src="http://localhost:3000/d-solo/Pb_MJR9nz/new-dashboard?orgId=1&from=1583408572267&to=1583428138927&panelId=13" width="100%" height="100%" frameborder="0"></iframe>
            <!-- <iframe src="http://localhost:3000/d-solo/vVl4Ftrnk/dt_alerts?orgId=1&from=now-2d&to=now&panelId=29&refresh=5s"  width="100%" height="100%" frameborder="0"></iframe> -->
        </div>
    </div>
    <div class="status-strip-container">
        <app-status-strip *ngIf="screen.statusStrip"></app-status-strip>
    </div>    
</nu-central-bacon-strip>