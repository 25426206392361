import { Component, Injector } from '@angular/core';
import { PosScreenDirective, ScreenComponent } from '@jumpmind/openpos-client-core-lib';
import { PartyDetailsInterface } from './party-details.interface';


@ScreenComponent({
  name: 'PartyDetails'
})
@Component({
  selector: 'app-party-details',
  templateUrl: './party-details.component.html',
  styleUrls: ['./party-details.component.scss']
})
export class PartyDetailsComponent extends PosScreenDirective<PartyDetailsInterface> {

  constructor(injector: Injector) {
    super(injector);
  }

  buildScreen() { }


}
