<mat-accordion [multi]="true" responsive-class>
    <mat-expansion-panel *ngFor="let category of categories" [(expanded)]="expandedState[category.id]">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <app-icon [iconName]="category.icon" class="muted-color expansion-icon"></app-icon>
                {{category.name}}
            </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-action-list class="action-list">
            <ng-container *ngFor="let button of category.buttons;let i = index">
                <button mat-list-item (click)="selectAction(button)" class="category-button"
                        [ngClass]="{'selected-action': button.action === selectedAction}">
                    <app-icon class="category-icon" [iconName]="button.icon"></app-icon>
                    {{button.title}}
                </button>
                <mat-divider *ngIf="i < category.buttons.length - 1"></mat-divider>
            </ng-container>
        </mat-action-list>
    </mat-expansion-panel>
</mat-accordion>