import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'nu-order-total',
    templateUrl: './order-total.component.html',
    styleUrls: ['./order-total.component.scss']
})
export class OrderTotalComponent {

    @Input()
    label: string;

    @Input()
    total: string;

}
