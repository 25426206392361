<nu-central-bacon-strip class="transaction-bacon-strip">
    <nu-central-local-nav-strip class="local-nav-strip">
        <div class="transaction-search-outer">
            <div class="transaction-search-top"
                 [ngClass]="{'change-search-mode-disabled': this.changeSearchModeDisabled}" responsive-class>
                <div class="transaction-search-mode">
                    <app-icon [iconName]="screen.transSearchModeIcon" iconClass="mat-36"></app-icon>
                    <span class="transaction-search-mode-text">{{screen.transSearchModeText}}</span>
                </div>
                <div [ngClass]="{'change-search-mode-disabled': this.changeSearchModeDisabled}"
                     class="transaction-search-options">
                    <app-scan-or-search class="scan-or-search mat-elevation-z1"
                        [focusInitial]="false"></app-scan-or-search>
                    <app-secondary-button *ngIf="screen.clearButton" inputType="submit"
                       (click)="doAction(screen.clearButton)">
                        <span>{{screen.clearButton.title}}</span>
                        <app-icon *ngIf="screen.clearButton.icon" [iconName]="screen.clearButton.icon"></app-icon>
                    </app-secondary-button>
                </div>
                <button *ngIf="screen.changeSearchModeButton" (click)="doAction(screen.changeSearchModeButton)"
                        class="transaction-change-search-mode-button" mat-button color="primary" responsive-class>
                    <app-icon *ngIf="screen.changeSearchModeButton.icon"
                              [iconName]="screen.changeSearchModeButton.icon" iconClass="mat-24"></app-icon>
                    <span class="transaction-change-search-mode-button-text">{{screen.changeSearchModeButton.title}}</span>
                    <app-icon iconName="Forward" iconClass="mat-24"></app-icon>
                </button>
                <div class="transaction-advanced-search-options">
                    <app-dynamic-form-part *ngIf="!showAdvancedSearchDialogButton()"
                            (formInit)="onAdvancedSearchFormChanges($event)"
                            (formChanges)="onAdvancedSearchFormChanges($event)"
                            formName="advancedSearchForm"
                            [submitButton]="screen.advancedSearchButton"></app-dynamic-form-part>
                    <app-primary-button *ngIf="showAdvancedSearchDialogButton() && screen.advancedSearchDialogButton" inputType="submit" 
                            (click)="doAction(screen.advancedSearchDialogButton)">
                        <span>{{screen.advancedSearchDialogButton.title}}</span>
                        <app-icon *ngIf="screen.advancedSearchDialogButton.icon" [iconName]="screen.advancedSearchDialogButton.icon"></app-icon>
                    </app-primary-button>
                    <app-secondary-button *ngIf="screen.clearAdvancedSearchButton" inputType="submit"
                            (click)="doAction(screen.clearAdvancedSearchButton)">
                        <span>{{screen.clearAdvancedSearchButton.title}}</span>
                        <app-icon *ngIf="screen.clearAdvancedSearchButton.icon" [iconName]="screen.clearAdvancedSearchButton.icon"></app-icon>
                    </app-secondary-button>
                </div>
            </div>
            <app-instructions *ngIf="resultsCount === 0" [instructions]="screen.noResultsMessage" class="no-results"></app-instructions>
            <app-infinite-scroll *ngIf="resultsCount !== 0" [dataKey]="screen.providerKey" [itemTemplate]="itemTemplate"
                                 virtualScrollMinBufferPx="200" virtualScrollMaxBufferPx="400" [itemHeightPx]="150">
                <ng-template #itemTemplate let-item>
                    <app-transaction-summary [transactionSummary]="item" class="transaction-summary" responsive-class>
                    </app-transaction-summary>
                </ng-template>
            </app-infinite-scroll>
        </div>
    </nu-central-local-nav-strip>
</nu-central-bacon-strip>