import { Component, Injector } from '@angular/core';
import { OpenposMediaService, PosScreenDirective, ScreenComponent } from '@jumpmind/openpos-client-core-lib';
import { Observable } from 'rxjs';
import { OpenOrdersInterface } from './open-orders.interface';

@ScreenComponent({ name: 'OpenOrders' })
@Component({
    selector: 'nu-open-orders',
    templateUrl: './open-orders.component.html',
    styleUrls: ['./open-orders.component.scss']
})
export class OpenOrdersComponent extends PosScreenDirective<OpenOrdersInterface> {
    isMobile$: Observable<boolean>;

    constructor(injector: Injector, media: OpenposMediaService) {
        super(injector);
        this.isMobile$ = media.mediaObservableFromMap(new Map([
            ['xs', true],
            ['sm', true],
            ['md', false],
            ['lg', false],
            ['xl', false]
        ]));
    }

    buildScreen(): void {
    }

}
