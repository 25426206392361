import { Component, Input } from '@angular/core';
import { IPromotionSummary } from './promotion-summary.interface';
import { ActionService, IActionItem } from '@jumpmind/openpos-client-core-lib';

@Component({
    selector: 'nu-promotion-summary',
    templateUrl: './promotion-summary.component.html',
    styleUrls: ['./promotion-summary.component.scss']
})
export class PromotionSummaryComponent {

    @Input()
    promotionSummary: IPromotionSummary;

    constructor(private actionService: ActionService) { }

    onClick(actionItem: IActionItem): void {
        this.actionService.doAction(actionItem, this.promotionSummary.promotionId.values[0]);
    }

}
