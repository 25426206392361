import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { DialogComponent, PosScreenDirective } from '@jumpmind/openpos-client-core-lib';
import { IStoreStatus } from '../store-status/store-status.interface';
import { CentralStoreDetailsInterface } from './central-store-details.interface';

@DialogComponent({
  name: 'StoreDetails'
})
@Component({
  selector: 'nu-store-details',
  templateUrl: './central-store-details.component.html',
  styleUrls: ['./central-store-details.component.scss']
})
export class CentralStoreDetailsComponent extends PosScreenDirective<CentralStoreDetailsInterface> {

  center: google.maps.LatLngLiteral;
  zoom: number = 5;
  marker: google.maps.Marker;

  @ViewChild('storeDetailsMap') storeDetailsMap;

  options: google.maps.MapOptions = {
    mapTypeId: 'roadmap',
    zoomControl: true,
    scrollwheel: true,
    disableDoubleClickZoom: false,
    maxZoom: 15,
    minZoom: 4,
    streetViewControl: false,
    mapTypeControl: false,
    fullscreenControl: false,
    styles: [
      {
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#f5f5f5"
          }
        ]
      },
      {
        "elementType": "labels.icon",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#616161"
          }
        ]
      },
      {
        "elementType": "labels.text.stroke",
        "stylers": [
          {
            "color": "#f5f5f5"
          }
        ]
      },
      {
        "featureType": "administrative",
        "elementType": "geometry",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "administrative.land_parcel",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#bdbdbd"
          }
        ]
      },
      {
        "featureType": "poi",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#eeeeee"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#757575"
          }
        ]
      },
      {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#e5e5e5"
          }
        ]
      },
      {
        "featureType": "poi.park",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#9e9e9e"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#ffffff"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "labels.icon",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "road.arterial",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#757575"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#dadada"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#616161"
          }
        ]
      },
      {
        "featureType": "road.local",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#9e9e9e"
          }
        ]
      },
      {
        "featureType": "transit",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "transit.line",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#e5e5e5"
          }
        ]
      },
      {
        "featureType": "transit.station",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#eeeeee"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#c9c9c9"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#9e9e9e"
          }
        ]
      }
    ]
  }

  buildScreen(): void {
  }

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.center = { lat: +this.screen.store.latitude, lng: +this.screen.store.longitude };
  }

  ngAfterViewInit(): void {
    var icon = this.screen.store.version + "-marker.png";
    let marker = new google.maps.Marker({
      position: { lat: +this.screen.store.latitude, lng: +this.screen.store.longitude },
      map: this.storeDetailsMap.googleMap,//Map that we need to add
      icon: "http://localhost:6140/" + icon,
      title: this.screen.store.storeName,
      draggable: false// If set to true you can drag the marker
    });
    this.marker = marker;
  }

  convertToIdString(version: string): string {
    let re = /\./gi;
    let result = version.replace(re, "-");
    result = "version" + result;
    return result;
  }

  onUpgradeTo(version: String) {
    this.doAction("Upgrade", version)
  }

}
