import { AfterViewInit, Component, ElementRef, Injector, QueryList, ViewChildren } from '@angular/core';
import { OpenposMediaService, PosScreenDirective, ScreenComponent } from '@jumpmind/openpos-client-core-lib';
import { OrderReviewInterface } from './order-review.interface';
import { IShippingMethod } from './shipping-method.interface';
import { IOrderReviewGroup } from './order-review-group.interface';
import { Observable } from 'rxjs';

@ScreenComponent({
    name: 'OrderReview'
})
@Component({
    selector: 'nu-order-review',
    templateUrl: './order-review.component.html',
    styleUrls: ['./order-review.component.scss']
})
export class OrderReviewComponent extends PosScreenDirective<OrderReviewInterface> implements AfterViewInit {
    @ViewChildren('items', { read: ElementRef }) private itemsRef: QueryList<ElementRef>;
    isMobile$: Observable<boolean>;

    constructor(injector: Injector, private mediaService: OpenposMediaService) {
        super(injector);
        this.isMobile$ = mediaService.mediaObservableFromMap(new Map([
            ['xs', true],
            ['sm', true],
            ['md', false],
            ['lg', false],
            ['xl', false]
        ]));
    }

    buildScreen() {
    }

    onItemClick(item: IShippingMethod, orderReviewGroup: IOrderReviewGroup): void {
        this.actionService.doAction(item.chooseMethodAction, { title: item.chooseMethodAction.title, name: orderReviewGroup.name });
    }

    scrollToView(index: number): void {
        if (this.itemsRef) {
            const itemsRefArray = this.itemsRef.toArray();
            if (itemsRefArray && index >= 0 && index < itemsRefArray.length) {
                itemsRefArray[index].nativeElement.scrollIntoView({ block: 'start' });
            }
        }
    }

    ngAfterViewInit() {
        this.scrollToView(this.screen.lastInteractedGroupIndex);
    }
}
