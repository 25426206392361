import { Component, EventEmitter, Injector, Output } from '@angular/core';
import { OpenposMediaService, ScreenPartComponent } from '@jumpmind/openpos-client-core-lib';
import { Observable } from 'rxjs';
import { OrderListInterface } from '../../order-list/order-list.interface';

@Component({
    selector: 'nu-order-list-header',
    templateUrl: './order-list-header.component.html',
    styleUrls: ['./order-list-header.component.scss']
})
export class OrderListHeaderComponent extends ScreenPartComponent<OrderListInterface> {
    @Output()
    searchValueChanged = new EventEmitter<string>();

    isMobile$: Observable<boolean>;

    constructor(injector: Injector, private media: OpenposMediaService) {
        super(injector);
        this.isMobile$ = this.media.mediaObservableFromMap(new Map([
            ['xs', true],
            ['sm', true],
            ['md', false],
            ['lg', false],
            ['xl', false]
        ]));
    }

    onSearchValueChanged($event: string): void {
        this.searchValueChanged.emit($event);
    }

    screenDataUpdated() {
        // Prevent Sonar error from empty method
    }
}
