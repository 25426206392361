import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IOrderItem, IOrderItemKey } from '../../interfaces/order-item.interface';

@Component({
    selector: 'nu-order-item-list',
    templateUrl: './order-item-list.component.html'
})
export class OrderItemListComponent {

    @Input()
    items: IOrderItem[];

    @Input()
    selectable = false;

    @Input()
    showOrderDetails = false;

    @Output()
    itemClick: EventEmitter<any> = new EventEmitter<any>();

    onItemClick(orderItemKey: IOrderItemKey): void {
        if (this.selectable) {
            this.itemClick.emit(orderItemKey);
        }
    }

    getItemClasses(item: IOrderItem): string {
        let classString = '';
        if (item.disabled) {
            classString = classString + ' disabled';
        }
        if (this.selectable) {
            classString = classString + ' select';
        }
        return classString;
    }
}
