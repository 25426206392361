import { Component, Input } from '@angular/core';
import { OrderStatus } from '../../interfaces/order-status.enum';

@Component({
    selector: 'nu-order-status',
    templateUrl: './order-status.component.html',
    styleUrls: ['./order-status.component.scss']
})
export class OrderStatusComponent {

    @Input()
    iconName: string;

    @Input()
    label: string;

    @Input()
    status: string;

    orderStatus = OrderStatus;

}
