<app-bacon-strip class="order-bacon-strip" [searchEnabled]="true">
    <div class="body-container" responsive-class>
        <div class="header" responsive-class>
            <div class="header-labels" responsive-class>
                <nu-order-header-label [iconName]="screen.headerIcon" 
                                       [headerText]="screen.headerLabel"
                                       [orderType]="screen.orderSummary.orderType" 
                                       [orderNumber]="screen.orderSummary.number">
                </nu-order-header-label>
                <nu-order-customer *ngIf="screen.shipToLabel" 
                                   [customer]="screen.orderSummary.customer"
                                   [label]="screen.shipToLabel" 
                                   [showAddress]="true">
                </nu-order-customer>
            </div>
            <div *ngIf="!(isMobile$ | async)">
                <app-scan-or-search class="mat-elevation-z1" [focusInitial]="false"></app-scan-or-search>
            </div>
        </div>
        <div class="content" responsive-class>
            <div class="unpacked">
                <app-instructions [instructions]="screen.packingListMessage" [instructionsSize]="'text-sm'"></app-instructions>
                <mat-card *ngIf="hasUnpackedItems()">
                    <nu-order-item-list [items]="screen.unpackedItems" 
                        [selectable]="canItemsBeSelected()"
                        (itemClick)="onItemClick($event)">
                    </nu-order-item-list>
                </mat-card>
                <div *ngIf="!hasUnpackedItems()" class="large-icon-and-message">
                    <app-icon *ngIf="screen.allPackedIcon" responsive-class
                        [iconName]="screen.allPackedIcon" [iconClass]="'xxl success'"></app-icon>
                    <app-instructions responsive-class class="message" 
                        [instructions]="screen.allPackedMessage"
                        [instructionsSize]="'text-lg'"></app-instructions>
                </div>
            </div>
            <div class="divider" responsive-class></div>
            <div class="packed">
                <ng-container *ngTemplateOutlet='getViewTemplate()'></ng-container>
            </div>
        </div>
        <div class="buttons" responsive-class>
            <app-secondary-button [disabled]="!screen.backToPickButton.enabled" (buttonClick)="doAction(screen.backToPickButton)">
                <span>{{screen.backToPickButton.title}}</span>
            </app-secondary-button>
            <app-primary-button [disabled]="!screen.primaryActionButton.enabled" (buttonClick)="doAction(screen.primaryActionButton)">
                <span>{{screen.primaryActionButton.title}}</span>
            </app-primary-button>
        </div>
    </div>
</app-bacon-strip>
<app-status-strip *ngIf="!(isMobile$ | async)"></app-status-strip>

<ng-template #packageView>
    <div *ngIf="hasPackages()">
        <div class="message-and-new-package">
            <app-instructions class="packedMessage" [instructions]="screen.packedListMessage" [instructionsSize]="'text-sm'"></app-instructions>
            <button *ngIf="screen.createPackageAction" mat-button class="new-package-button" (click)="doAction(screen.createPackageAction)">
                <strong [ngClass]="{'muted-color': ! screen.createPackageAction.enabled}">{{screen.createPackageAction.title}}</strong>
                <app-icon *ngIf="screen.createPackageAction.icon" [iconName]="screen.createPackageAction.icon"></app-icon>
            </button>
        </div>
        <div class="packages">
            <app-expansion-panel *ngFor="let package of screen.packages" 
                [expanded]="package.openFlag">
                <ng-container header>
                    <nu-order-package-header [package]="package">
                        <app-icon-button *ngIf="package.button && package.button.enabled" 
                            [iconName]="package.button.icon"
                            [iconClass]="'sm'"
                            (buttonClick)="doAction(package.button)">
                        </app-icon-button>
                    </nu-order-package-header>
                </ng-container>
                <ng-container content>
                    <nu-order-item-list [items]="package.items" 
                        [selectable]="package.openFlag"
                        (itemClick)="onItemClick($event)">
                    </nu-order-item-list>
                </ng-container>
            </app-expansion-panel>
        </div>
    </div>
    <button class="large-icon-and-message create-package-button" *ngIf="!hasPackages()" (click)="doAction(screen.createPackageAction)">
        <app-icon *ngIf="screen.createPackageIcon" responsive-class class="create-package-icon"
            [iconName]="screen.createPackageIcon" [iconClass]="'xxl'"></app-icon>
        <app-instructions class="message" [instructions]="screen.createPackageMessage"
            [instructionsSize]="'text-lg'"></app-instructions>
    </button>
</ng-template>

<ng-template #nonPackageView>
    <app-instructions class="packedMessage" [instructions]="screen.packedListMessage" [instructionsSize]="'text-sm'"></app-instructions>
    <mat-card>
        <nu-order-item-list [items]="screen.packedItems" [selectable]="true" (itemClick)="onItemClick($event)">
        </nu-order-item-list>
    </mat-card>
</ng-template>