import { Component, Input } from '@angular/core';
import { IOrderSummary } from '@jumpmind/openpos-client-core-lib';
@Component({
    selector: 'nu-order-item-header',
    templateUrl: './order-item-header.component.html',
    styleUrls: ['./order-item-header.component.scss']
})
export class OrderItemHeaderComponent {
    @Input()
    orderSummary: IOrderSummary;
}
