import { Component, Injector } from '@angular/core';
import { PosScreenDirective, ScreenComponent, UIDataMessageService } from '@jumpmind/openpos-client-core-lib';
import { Observable } from 'rxjs';
import { InstallGroup, InstallGroupsScreen } from './install-groups.interface';

@ScreenComponent({
    name: 'InstallGroups'
})
@Component({
    templateUrl: 'install-groups.component.html',
    styleUrls: ['install-groups.component.scss']
})
export class InstallGroupsComponent extends PosScreenDirective<InstallGroupsScreen> {
    installGroups$?: Observable<InstallGroup[]>;

    constructor(injector: Injector, private _dataMessageService: UIDataMessageService) {
        super(injector);
    }

    buildScreen(): void {
        this.installGroups$ = this._dataMessageService
            .getData$(this.screen.providerKey);
    }
}
