import { Component, Injector } from '@angular/core';
import { OpenposMediaService, PosScreenDirective, ScreenComponent } from '@jumpmind/openpos-client-core-lib';
import { Observable } from 'rxjs';
import { IOrderItemKey } from '../interfaces/order-item.interface';
import { OrderPickInterface } from './order-pick.interface';

@ScreenComponent({
    name: 'OrderPick'
})
@Component({
    selector: 'nu-order-pick',
    templateUrl: './order-pick.component.html',
    styleUrls: ['./order-pick.component.scss']
})
export class OrderPickComponent extends PosScreenDirective<OrderPickInterface> {
    isMobile$: Observable<boolean>;

    constructor(injector: Injector, media: OpenposMediaService) {
        super(injector);
        this.isMobile$ = media.mediaObservableFromMap(new Map([
            ['xs', true],
            ['sm', true],
            ['md', false],
            ['lg', false],
            ['xl', false]
        ]));
    }

    buildScreen(): void {
    }

    onItemClick(orderItemKey: IOrderItemKey): void {
        this.actionService.doAction(this.screen.listChangedAction, orderItemKey);
    }
}
