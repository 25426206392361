import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActionService, IActionItem, MediaBreakpoints, OpenposMediaService } from '@jumpmind/openpos-client-core-lib';
import { Observable } from 'rxjs/internal/Observable';
import { IOrderStatusSection } from './order-status-section.interface';

@Component({
    selector: 'nu-order-status-section',
    templateUrl: './order-status-section.component.html',
    styleUrls: ['./order-status-section.component.scss']
})
export class OrderStatusSectionComponent {

    @Input()
    section: IOrderStatusSection;

    @Input()
    orientation = 'horizontal';

    @Input()
    selectOrderAction: IActionItem;

    @Input()
    displayAllOrders = true;

    @Output()
    filterClicked = new EventEmitter<string>();

    isMobile$: Observable<boolean>;

    constructor(private media: OpenposMediaService, public actionService: ActionService) {
        this.initIsMobile();
    }

    initIsMobile(): void {
        this.isMobile$ = this.media.observe(new Map([
            [MediaBreakpoints.MOBILE_PORTRAIT, true],
            [MediaBreakpoints.MOBILE_LANDSCAPE, true],
            [MediaBreakpoints.TABLET_PORTRAIT, true],
            [MediaBreakpoints.TABLET_LANDSCAPE, true],
            [MediaBreakpoints.DESKTOP_PORTRAIT, false],
            [MediaBreakpoints.DESKTOP_LANDSCAPE, false]
        ]));
    }

    shouldDisplayFilterAction(): boolean {
        return !this.displayAllOrders && this.section.filterAction
            && this.section.numberOfOrders > 0 && this.section.numberOfOrders > this.section.topSummaries.length;
    }

    doAction(action: IActionItem | string, payload?: any): void {
        if (typeof (action) === 'string') {
            this.actionService.doAction({ action }, payload);
        } else {
            this.actionService.doAction(action, payload);
        }
    }
}
