import { AbstractControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

export class SsnEntryErrorStateMatcher implements ErrorStateMatcher {

    error: string;

    isErrorState(control: AbstractControl | null, form: FormGroupDirective | NgForm | null): boolean {
        return (control && (control.dirty && control.invalid) || !!this.error);
    }
}
