<nu-order-list-header (searchValueChanged)="$searchText.next($event)"></nu-order-list-header>
<div responsive-class class="body-content">
    <div responsive-class class="filters">
        <mat-button-toggle-group #group="matButtonToggleGroup" [value]="allOrdersFilterSelectedVal"
            (change)="onAllOrdersFilterSelected(group.value)" class="allOrdersFilter">
            <mat-button-toggle responsive-class class="text-sm" [value]="screen.allOrdersFilter.action">
                {{screen.allOrdersFilter.title}}
            </mat-button-toggle>
        </mat-button-toggle-group>
        <mat-button-toggle-group #group2="matButtonToggleGroup" [value]="deliveryMethodSelectedVal"
            (change)="onDeliveryMethodValChanged(group2.value)" class="deliveryFilter">
            <mat-button-toggle responsive-class *ngFor="let button of screen.deliveryMethodFilter.actionItems"
                class="text-sm" [value]="button.action">
                <app-icon responsive-class *ngIf="button.icon" [iconName]="button.icon" [iconClass]="'xs'"></app-icon>
                <span *ngIf="!button.icon">{{button.title}}</span>
            </mat-button-toggle>
        </mat-button-toggle-group>
        <mat-button-toggle-group #group3="matButtonToggleGroup" [value]="statusSelectedVal"
            (change)="onStatusValChange(group3.value)" class="statusFilter">
            <mat-button-toggle responsive-class *ngFor="let button of screen.statusFilter.actionItems" class="text-sm"
                [value]="button.action">{{button.title}}</mat-button-toggle>
        </mat-button-toggle-group>
    </div>
    <app-rounded-input *ngIf="!(isMobile$ | async)" class="search" [iconName]="screen.search.icon"
        [placeholder]="screen.search.title" [value]="screen.searchText" (valueChange)="$searchText.next($event)">
    </app-rounded-input>
    <div class="order-list">
        <nu-order-status-section *ngFor="let section of screen.orderStatusSections"
            [ngClass]="getFilteredVisibilityClass(section)" [section]="section"
            [orientation]="getStatusSectionOrientation()"
            [displayAllOrders]="!(isMobile$ | async) || isFilteredOnStatus()"
            [selectOrderAction]="screen.selectOrderAction" (filterClicked)="onStatusValChange($event)">
        </nu-order-status-section>
    </div>
</div>
<app-status-strip *ngIf="!(isMobile$ | async)"></app-status-strip>
<app-scan-part></app-scan-part>