import { Injectable } from '@angular/core';
import {
    ActionMessage,
    AndroidContentProviderPlugin, AndroidContentQuery,
    IActionItem,
    MessageTypes,
    SessionService,
    SingleSignOnMessage
} from '@jumpmind/openpos-client-core-lib';
import { filter, tap } from 'rxjs/operators';
import { SignOnProviderNames } from './sign-on-provider-names';

@Injectable({
    providedIn: 'root'
})
export class BlueFletchAuthProvider {

    private responseAction: IActionItem;

    constructor(private sessionService: SessionService, private androidContentProviderPlugin: AndroidContentProviderPlugin) {
        console.log('BlueFletch Auth provider loaded');

        sessionService.getMessages(MessageTypes.SINGLE_SIGN_ON_REQUEST).pipe(
            filter(m => (m as SingleSignOnMessage).providerName === SignOnProviderNames.BLUE_FLETCH),
            tap(m => this.responseAction = (m as SingleSignOnMessage).responseAction),
            tap(m => console.log('BlueFletch sign on request received'))
        ).subscribe(m => this.querySession());
    }

    private querySession() {
        if (this.androidContentProviderPlugin.pluginPresent()) {
            this.androidContentProviderPlugin.query(new AndroidContentQuery('content://com.bluefletch.launcherprovider/session', ['data']))
                .subscribe({
                    next: data => this.sendSignOnResponse(data[0]['DATA']),
                    error: err =>
                        this.sessionService.sendMessage(
                            new ActionMessage(this.responseAction.action, this.responseAction.doNotBlockForResponse)
                        )
                });
        } else {
            console.warn('Android Content Provider not present');
            this.sessionService.sendMessage(new ActionMessage(this.responseAction.action, this.responseAction.doNotBlockForResponse));
        }
    }

    private sendSignOnResponse(sessionData: string) {
        console.log(`responding with session data: ${sessionData}`);
        const session = JSON.parse(sessionData);
        if (session.extendedAttributes) {
            session.extendedAttributes = JSON.parse(session.extendedAttributes);
        }
        this.sessionService.sendMessage(
            new ActionMessage(this.responseAction.action, this.responseAction.doNotBlockForResponse, JSON.stringify(session))
        );
    }
}
