import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Component, Injector, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import {
    MediaBreakpoints,
    OpenposMediaService,
    PosScreenDirective,
    ScreenComponent,
    UIDataMessageService
} from '@jumpmind/openpos-client-core-lib';
import { ItemSearchResult } from './item-search-result';
import { ItemSearchResultsInterface } from './item-search-results.interface';

@ScreenComponent({
    name: 'ItemSearchResults'
})
@Component({
    selector: 'nu-item-search-results',
    templateUrl: './item-search-results.component.html',
    styleUrls: ['./item-search-results.component.scss']
})
export class ItemSearchResultsComponent extends PosScreenDirective<ItemSearchResultsInterface> {

    @ViewChild(CdkVirtualScrollViewport, { static: false })
    scrollViewPort: CdkVirtualScrollViewport;

    results$: Observable<ItemSearchResult[]>;

    isMobile: Observable<boolean>;

    constructor(injector: Injector, private resultsService: UIDataMessageService, private media: OpenposMediaService) {
        super(injector);

        this.isMobile = media.observe(new Map([
            [MediaBreakpoints.MOBILE_PORTRAIT, true],
            [MediaBreakpoints.MOBILE_LANDSCAPE, true],
            [MediaBreakpoints.TABLET_PORTRAIT, false],
            [MediaBreakpoints.TABLET_LANDSCAPE, false],
            [MediaBreakpoints.DESKTOP_PORTRAIT, false],
            [MediaBreakpoints.DESKTOP_LANDSCAPE, false]
        ]));
    }

    buildScreen() {
        this.results$ = this.resultsService.getData$(this.screen.searchResultsDataKey);
    }

    resultsTrackFn(index: number, item: ItemSearchResult) {
        return item.id;
    }
}
