import { Component } from '@angular/core';
import { PosScreenDirective, ScreenComponent, IActionItem } from '@jumpmind/openpos-client-core-lib';
import { CentralConfigurationInterface } from './central-configuration.interface';

@ScreenComponent({
    name: 'CentralConfiguration'
})
@Component({
    selector: 'nu-central-configuration',
    templateUrl: './central-configuration.component.html',
    styleUrls: ['./central-configuration.component.scss'],
})
export class CentralConfigurationComponent extends PosScreenDirective<CentralConfigurationInterface> {

    buildScreen(): void {
    }

    isSelectedOption(option: IActionItem) {
        return this.screen.configOptions.selectedAction === option.action;
    }

}
