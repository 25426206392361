import { Component, Injector, TemplateRef, ViewChild } from '@angular/core';
import { OpenposMediaService, PosScreenDirective, ScreenComponent } from '@jumpmind/openpos-client-core-lib';
import { Observable } from 'rxjs';
import { OrderType } from '../interfaces/order-type.enum';
import { OrderDeliverInterface } from './order-deliver.interface';

@ScreenComponent({
    name: 'OrderDeliver'
})
@Component({
    selector: 'nu-order-deliver',
    templateUrl: './order-deliver.component.html',
    styleUrls: ['./order-deliver.component.scss']
})
export class OrderDeliverComponent extends PosScreenDirective<OrderDeliverInterface> {

    @ViewChild('packageView') packageTemplate: TemplateRef<any>;
    @ViewChild('itemView') nonPackageTemplate: TemplateRef<any>;

    isMobile$: Observable<boolean>;

    constructor(injector: Injector, media: OpenposMediaService) {
        super(injector);
        this.isMobile$ = media.mediaObservableFromMap(new Map([
            ['xs', true],
            ['sm', true],
            ['md', false],
            ['lg', false],
            ['xl', false]
        ]));
    }

    buildScreen(): void {
    }


    public getViewTemplate(): TemplateRef<any> {
        const orderType = this.screen.orderSummary.orderType;
        if (OrderType.SFS === orderType) {
            return this.packageTemplate;
        } else {
            return this.nonPackageTemplate;
        }
    }
}
