import { Component, OnInit, Injector } from '@angular/core';
import { ScreenComponent, PosScreenDirective, UIDataMessageService, OpenposMediaService, MediaBreakpoints } from '@jumpmind/openpos-client-core-lib';
import { MicroCapScreenInterface } from './micro-cap-screen.interface';

@ScreenComponent({
  name: 'MicroCapScreen'
})
@Component({
  selector: 'commerce-micro-cap-screen',
  templateUrl: './micro-cap-screen.component.html',
  styleUrls: ['./micro-cap-screen.component.scss']
})
export class MicroCapScreenComponent extends PosScreenDirective<MicroCapScreenInterface> implements OnInit {

  constructor(injector: Injector, private dataMessageService: UIDataMessageService,
    mediaService: OpenposMediaService) {
    super(injector);
  }

  ngOnInit(): void {
  }

  buildScreen() {

    var screenNotTypeSafe = this.screen as any;

    // const allItems = new Map<number, any>();
    // const allDisabledItems = new Map<number, any>();

    // for (let i = 0; i < screenNotTypeSafe.data.clientelingFeedItems.length; i++) {
    //     const item = screenNotTypeSafe.data.clientelingFeedItems[i];
    //     allItems.set(i, item);
    // }
  }


}
