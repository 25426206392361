<app-bacon-strip class="order-bacon-strip" [searchEnabled]="false">
    <div class="body-container" responsive-class>
        <div class="header" responsive-class>
            <div class="header-labels" responsive-class>
                <nu-order-header-label [iconName]="screen.headerIcon" 
                                       [headerText]="screen.headerLabel"
                                       [orderType]="screen.orderSummary.orderType" 
                                       [orderNumber]="screen.orderSummary.number">
                </nu-order-header-label>
                <nu-order-customer *ngIf="screen.shipToLabel" 
                                   [customer]="screen.orderSummary.customer"
                                   [label]="screen.shipToLabel" 
                                   [showAddress]="true">
                </nu-order-customer>
            </div>
        </div>
        <div class="content" responsive-class>
            <app-instructions [instructions]="screen.instructions" [instructionsSize]="'text-sm'"></app-instructions>
            <ng-container *ngTemplateOutlet='getViewTemplate()'></ng-container>
        </div>
        <div class="buttons" responsive-class>
            <app-secondary-button *ngIf="screen.backToPackButton" [disabled]="!screen.backToPackButton.enabled" (buttonClick)="doAction(screen.backToPackButton)">
                <span>{{screen.backToPackButton.title}}</span>
            </app-secondary-button>
            <app-secondary-button *ngIf="screen.printLabelsButton" [disabled]="!screen.printLabelsButton.enabled" (buttonClick)="doAction(screen.printLabelsButton)">
                <span>{{screen.printLabelsButton.title}}</span>
            </app-secondary-button>
            <app-primary-button *ngIf="screen.primaryActionButton" [disabled]="!screen.primaryActionButton.enabled" (buttonClick)="doAction(screen.primaryActionButton)">
                <span>{{screen.primaryActionButton.title}}</span>
            </app-primary-button>
        </div>
    </div>
</app-bacon-strip>
<app-status-strip *ngIf="!(isMobile$ | async)"></app-status-strip>

<ng-template #packageView>
    <div class="packages">
        <app-expansion-panel *ngFor="let package of screen.packages">
            <ng-container header>
                <nu-order-package-header [package]="package">
                    <button responsive-class mat-flat-button
                        *ngIf="package.button && package.button.enabled" 
                        class="print-label-button"
                        (click)="doAction(package.button)">
                        {{package.button.title}}
                    </button>
                </nu-order-package-header>
            </ng-container>
            <ng-container content>
                <nu-order-item-list [items]="package.items" 
                    [selectable]="false">
                </nu-order-item-list>
            </ng-container>
        </app-expansion-panel>
    </div>
</ng-template>

<ng-template #itemView>
    <mat-card>
        <nu-order-item-list [items]="screen.items" [selectable]="false">
        </nu-order-item-list>
    </mat-card>
</ng-template>