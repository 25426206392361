<div class="mgmt-container">
    <app-dynamic-bacon-strip></app-dynamic-bacon-strip>

    <div class="mobile-checkout">
        <nu-checkout-device-toggle-button *ngFor="let cs of (checkoutSummaryList$ | async)" [isMobile]="isMobile$ | async"
                                          [checkoutSummary]="cs" [selected]="cs.checkoutDevice.id===selectedDeviceId"
                                          [deviceIcon]="screen.checkoutLaneIcon"
                                          (change)="onSelectedChange($event)">
        </nu-checkout-device-toggle-button>

        <!-- displayed if no items -->
        <app-image-text-panel [instructionsSize]="'text-lg'"></app-image-text-panel>
    </div>
</div>