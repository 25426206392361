<app-dialog-header></app-dialog-header>
<app-content-card>
    <mat-dialog-content>
        <div class="address" *ngFor="let address of screen.addresses;let addessIndex = index">
            <div class="line1">
                {{address.address}}
            </div>
            <div class="line2">
                {{address.city}}, {{address.state}} {{address.postalCode}}
            </div>
            <div class="address-actions">
                <app-secondary-button *ngFor="let action of screen.addressActions" (buttonClick)="doAction(action, addessIndex)">
                    {{action.title}}
                </app-secondary-button>
                <app-primary-button (buttonClick)="doAction(screen.selectAction, addessIndex)">
                    {{screen.selectAction.title}}
                </app-primary-button>
            </div>
        </div>
    </mat-dialog-content>
</app-content-card>

<mat-dialog-actions>
    <app-secondary-button *ngFor="let m of screen.actions"[actionItem]="m"
                          (buttonClick)="doAction(m)">
        <span>{{m.title}}</span>&nbsp;
    </app-secondary-button>
</mat-dialog-actions>
