<div responsive-class class="container">
    <app-icon responsive-class [iconName]="package.packageIcon" [iconClass]="'md'"></app-icon>
    <div responsive-class>
        <strong responsive-class class="package-label">{{package.packageLabel}}</strong>
        <div class="package-details">
            <strong responsive-class> {{package.itemCount}} {{package.itemCountLabel}}</strong>
            <strong *ngIf="package.trackingLabel" responsive-class>{{package.trackingLabel}}</strong>
        </div>
    </div>
</div>
<ng-content></ng-content>