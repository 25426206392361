<div class="shipping-item-container" responsive-class>
    <app-icon *ngIf="getIconName()" [ngClass]="{'checked': item.selected}"
              [iconName]="getIconName()" [iconClass]="getIconClass()" responsive-class></app-icon>
    <div [class]="classes + ' shipping-item'" responsive-class>
        <div responsive-class class="item-details">
            <strong>{{item.chooseMethodAction.title}}</strong>
            <div class="additional-details">
                <div>
                    <div>{{item.estDeliveryDateLabel}} {{item.estDeliveryDate}}</div>
                    <app-currency-text responsive-class
                                       class="secondary-text"
                                       [class.no-charge]="item.costFree"
                                       [amountText]="item.cost + ' ' + item.surcharge">
                    </app-currency-text>
                </div>
            </div>
        </div>
    </div>
</div>
