import { IMessageHandler, SessionService, ElectronPlatform } from '@jumpmind/openpos-client-core-lib';
import { Injectable } from '@angular/core';

declare global {
    interface Window {
        nw: {
            App: {
                quit();
            };
        };
    }
}

@Injectable({
    providedIn: 'root',
})
export class AppControlService implements IMessageHandler<any> {

    constructor(public sessionService: SessionService, private electronPlatform: ElectronPlatform) {
        this.sessionService.registerMessageHandler(this, 'AppControl');
    }

    handle(message: any) {
        if (message.command === 'EXIT') {
            if (this.electronPlatform.platformPresent()) {
                console.log('exiting app based on message: ' + message);
                // close the window to exit electron.  we were previously exiting node which (i think) was causing a crash
                window.openposElectron?.quit();
            } else {
                console.log('NOT exiting app based on message because this is not an electron application: ' + message);
            }
        }
    }
}
