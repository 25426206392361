<div class="batch-pick-outer">
    <app-bacon-strip class="order-bacon-strip" [searchEnabled]="true"></app-bacon-strip>
    <div responsive-class class="body-container">
        <div class="header" responsive-class>
            <nu-order-header-label class="header-label" responsive-class
                [iconName]="screen.headerIcon"
                [headerText]="screen.headerLabel">
            </nu-order-header-label>
            <div responsive-class class="filters">
                <mat-button-toggle-group #group="matButtonToggleGroup" [value]="groupingSelectedVal"
                    (change)="onGroupingSelected(group.value)" class="groupingFilter">
                    <mat-button-toggle responsive-class *ngFor="let button of screen.groupingTypeFilter.actionItems"
                        class="text-sm" [value]="button.action">{{button.title}}</mat-button-toggle>
                </mat-button-toggle-group>
                <mat-button-toggle-group #group2="matButtonToggleGroup" [value]="deliveryMethodSelectedVal"
                    (change)="onDeliveryMethodValChanged(group2.value)" class="deliveryFilter">
                    <mat-button-toggle responsive-class *ngFor="let button of screen.deliveryMethodFilter.actionItems"
                        class="text-sm" [value]="button.action">
                        <app-icon responsive-class *ngIf="button.icon" [iconName]="button.icon" [iconClass]="'xs'">
                        </app-icon>
                        <span *ngIf="!button.icon">{{button.title}}</span>
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div>
            <div *ngIf="!(isMobile$ | async)" class="scan-or-search">
                <app-scan-or-search class="mat-elevation-z1" [focusInitial]="false"></app-scan-or-search>
            </div>
        </div>
        <div class="content" responsive-class>
            <app-instructions [instructions]="screen.pickListMessage" [instructionsSize]="'text-sm'"></app-instructions>
            <div class="content-body">
                <ng-container *ngTemplateOutlet='getGroupingSelectedTemplate()'></ng-container>
            </div>
        </div>
        <div class="buttons" responsive-class>
            <app-secondary-button *ngIf="screen.printPickListButton"
                (buttonClick)="doAction(screen.printPickListButton)">
                {{screen.printPickListButton.title}}
            </app-secondary-button>
            <app-primary-button *ngIf="screen.primaryActionButton" 
                [disabled]="!screen.primaryActionButton.enabled"
                (buttonClick)="doAction(screen.primaryActionButton)">
                <span>{{screen.primaryActionButton.title}}</span>
            </app-primary-button>
        </div>
    </div>
    <app-status-strip *ngIf="!(isMobile$ | async)"></app-status-strip>


    <ng-template #byOrder>
        <app-infinite-scroll [dataKey]="screen.byOrderDataProviderKey" [itemTemplate]="itemTemplate"
            listClass="infinite-list-with-row-gap" virtualScrollMinBufferPx="200" virtualScrollMaxBufferPx="400"
            [itemHeightPx]="100">
            <ng-template #itemTemplate let-order>
                <app-expansion-panel>
                    <ng-container header>
                        <nu-order-item-header [orderSummary]="order.orderSummary"></nu-order-item-header>
                    </ng-container>
                    <ng-container content>
                        <nu-order-item-list [items]="order.orderItems" 
                            [selectable]="true"
                            (itemClick)="onItemClick($event)"></nu-order-item-list>
                    </ng-container>
                </app-expansion-panel>
            </ng-template>
        </app-infinite-scroll>
    </ng-template>

    <ng-template #byItem>
        <app-infinite-scroll [dataKey]="screen.byItemDataProviderKey" [itemTemplate]="itemTemplate"
            listClass="infinite-list-with-row-gap" virtualScrollMinBufferPx="200" virtualScrollMaxBufferPx="200"
            [itemHeightPx]="100">
            <ng-template #itemTemplate let-group>
                <mat-card>
                    <nu-order-item-list [items]="group.orderItems" 
                        [showOrderDetails]="true"
                        [selectable]="true" 
                        (itemClick)="onItemClick($event)"></nu-order-item-list>
                </mat-card>
            </ng-template>
        </app-infinite-scroll>
    </ng-template>

    <ng-template #byItemLocation>
        <app-infinite-scroll [dataKey]="screen.byItemLocationDataProviderKey" [itemTemplate]="itemTemplate"
            listClass="infinite-list-with-row-gap" virtualScrollMinBufferPx="200" virtualScrollMaxBufferPx="400"
            [itemHeightPx]="100">
            <ng-template #itemTemplate let-group>
                <app-expansion-panel>
                    <ng-container header>
                        <div responsive-class class="by-location-header">
                            <app-icon *ngIf="group.groupIcon" [iconName]="group.groupIcon" [iconClass]="'sm'">
                            </app-icon>
                            <strong>{{group.groupName}}</strong>
                        </div>
                    </ng-container>
                    <ng-container content>
                        <nu-order-item-list [items]="group.orderItems" 
                            [showOrderDetails]="true"
                            [selectable]="true" 
                            (itemClick)="onItemClick($event)"></nu-order-item-list>
                    </ng-container>
                </app-expansion-panel>
            </ng-template>
        </app-infinite-scroll>
    </ng-template>
</div>