import { Component, Input } from '@angular/core';

/**
 * Displays instructions to the user and/or an icon below the text.
 */
@Component({
    selector: 'nu-icon-instructions',
    templateUrl: './icon-instructions.component.html',
    styleUrls: ['./icon-instructions.component.scss']
})
export class IconInstructionsComponent {

    @Input()
    instructions: string;

    @Input()
    instructionsSize: string;

    /**
     * URL for the primary image
     */
    @Input()
    iconName: string;

    /**
     * URL for the alternate image
     */
    @Input()
    iconClass: string;

    constructor() { }
}
