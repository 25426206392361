<app-bacon-strip class="bacon-strip">
    <div class="store-cycle-body">
        <app-content-card>
            <div responsive-class class="store-section">
                <div responsive-class class="store-section-left">
                    <app-icon [iconName]="screen.storeSectionIcon" [iconClass]="'xl'" [class.success]="screen.storeOpen" [class.muted-color]="!screen.storeOpen"></app-icon>
                    <div responsive-class class="store-section-details">
                        <app-title [title]="screen.storeSectionHeader" [titleSize]="'text-md'"></app-title>
                        <div *ngFor="let detail of screen.storeSectionDetails">
                            <app-instructions [instructions]="detail" [instructionsSize]="'text-sm'"></app-instructions>
                        </div>
                    </div>
                </div>
                <div *ngIf="screen.storeButton" responsive-class class="section-button">
                    <app-secondary-button *ngIf="!isStoreButtonPrimary()" 
                        [disabled]="!screen.storeButton.enabled"
                        (buttonClick)="doAction(screen.storeButton)">
                        <span>{{screen.storeButton.title}}</span>
                    </app-secondary-button>
                    <app-primary-button *ngIf="isStoreButtonPrimary()" 
                        [disabled]="!screen.storeButton.enabled"
                        (buttonClick)="doAction(screen.storeButton)">
                        <span>{{screen.storeButton.title}}</span>
                     </app-primary-button>
                </div>
            </div>
            <div responsive-class class="device-section">
                <div responsive-class class="device-section-left">
                    <app-icon [iconName]="screen.deviceSectionIcon" [iconClass]="'xl'" [class.success]="screen.deviceOpen" [class.muted-color]="!screen.deviceOpen"></app-icon>
                    <div responsive-class class="device-section-details">
                        <app-title [title]="screen.deviceSectionHeader" [titleSize]="'text-md'"></app-title>
                        <div *ngFor="let detail of screen.deviceSectionDetails">
                            <app-instructions [instructions]="detail" [instructionsSize]="'text-sm'"></app-instructions>
                        </div>
                    </div>
                </div>
                <div *ngIf="screen.deviceButton" responsive-class class="section-button">
                    <app-secondary-button *ngIf="!isDeviceButtonPrimary()" 
                        [disabled]="!screen.deviceButton.enabled"
                        (buttonClick)="doAction(screen.deviceButton)">
                        <span>{{screen.deviceButton.title}}</span>
                    </app-secondary-button>
                    <app-primary-button *ngIf="isDeviceButtonPrimary()" 
                        [disabled]="!screen.deviceButton.enabled"
                        (buttonClick)="doAction(screen.deviceButton)">
                        <span>{{screen.deviceButton.title}}</span>
                     </app-primary-button>
                </div>
            </div>
            <div *ngIf="screen.tillSectionEnabled" responsive-class class="till-section">
                <div responsive-class class="till-section-left">
                    <app-icon [iconName]="screen.tillSectionIcon" [iconClass]="'xl'" [class.success]="screen.tillOpen" [class.muted-color]="!screen.tillOpen"></app-icon>
                    <div responsive-class class="till-section-details">
                        <app-title [title]="screen.tillSectionHeader" [titleSize]="'text-md'"></app-title>
                        <div *ngFor="let detail of screen.tillSectionDetails">
                            <app-instructions [instructions]="detail" [instructionsSize]="'text-sm'"></app-instructions>
                        </div>
                    </div>
                </div>
                <div *ngIf="screen.tillButton" responsive-class class="section-button">
                    <app-secondary-button *ngIf="!isTillButtonPrimary()" 
                        [disabled]="!screen.tillButton.enabled"
                        (buttonClick)="doAction(screen.tillButton)">
                        <span>{{screen.tillButton.title}}</span>
                    </app-secondary-button>
                    <app-primary-button *ngIf="isTillButtonPrimary()" 
                        [disabled]="!screen.tillButton.enabled"
                        (buttonClick)="doAction(screen.tillButton)">
                        <span>{{screen.tillButton.title}}</span>
                     </app-primary-button>
                </div>
            </div>
        </app-content-card>
    </div>
</app-bacon-strip>