import { DialogComponent } from '@jumpmind/openpos-client-core-lib';
import { Component } from '@angular/core';
import { TillCountComponent } from './till-count.component';

@DialogComponent({
    name: 'TillCount'
})
@Component({
    selector: 'nu-till-count-dialog',
    templateUrl: './till-count-dialog.component.html',
    styleUrls: ['./till-count-dialog.component.scss']
})
export class TillCountDialogComponent extends TillCountComponent {

}
