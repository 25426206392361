<div responsive-class class="container">
    <div *ngIf="label" responsive-class class="label">{{label}}</div>
    <div class="info">
        <div responsive-class class="name">
            <div class="name-value" responsive-class>{{customer.fullName}}</div>
        </div>
        <div *ngIf="showContact" class="contact">
            <div class="contact-phone">{{customer.phone | valueFormatter: 'phone'}}</div>
            <div class="contact-email">{{customer.email}}</div>
        </div>
        <div *ngIf="showAddress && customer.address" class="address">
            <div class="address-value">{{customer.address.address}}</div>
            <div class="address-value">{{customer.address.address2}}</div>
            <div class="address-city-state-zip">
                {{customer.address.city}}, {{customer.address.state}} {{customer.address.postalCode}}
            </div>
        </div>
    </div>
</div>