import { ITillSection } from './till-section.interface';
import { Input, Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'nu-till-section',
    templateUrl: './till-section.component.html',
    styleUrls: ['./till-section.component.scss']
})
export class TillSectionComponent {

    @Input()
    section: ITillSection;

    @Output()
    sectionValueChanged = new EventEmitter<ITillSection>();

    onValueChanged($event) {
        this.section.count = $event.srcElement.value;
        this.sectionValueChanged.emit(this.section);
    }
}
