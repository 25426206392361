<app-dialog-header></app-dialog-header>
<app-instructions *ngIf="screen.instructions" [instructions]="screen.instructions" [instructionsSize]="'text-sm'">
</app-instructions>
<mat-dialog-content>

<app-content-card class="app-content-card">
        <div class="store-outer" responsive-class>
            <div class="deployments-versions">
                <div class="deployments-versions-category">
                    <div>
                        <span class="field-title">Name: </span>
                        <span>{{screen.store.storeName}}</span>
                    </div>
                    <div>
                        <span class="field-title">Brand: </span>
                        <span>{{screen.store.brand}}</span>
                    </div>
                    <div>
                        <span class="field-title">Address: </span>
                        <span>{{screen.store.address}}</span>
                    </div>
                    <div>
                        <span class="field-title">Version: </span>
                        <span>{{screen.store.version}}</span>
                    </div>
                    <div>
                        <div class="field-title">Upgrades Available:</div>
                    </div>
                    <div class="deployments-versions-list">
                        <app-primary-button *ngFor="let version of screen.store.availableVersions" (click)="onUpgradeTo(version)" class={{convertToIdString(version)}}>{{version}}</app-primary-button>
                        <!-- <app-primary-button class="version3">4.0.0</app-primary-button> -->
                    </div>
                </div>
            </div>
            <div class="deployments-versions">
                <google-map #storeDetailsMap [options]="options" [center]="center" [zoom]="zoom"
                    height="300px">
                </google-map>
            </div>
        </div>
</app-content-card>
</mat-dialog-content>