import { OpenposMessage } from '@jumpmind/openpos-client-core-lib';

export class ClientSerialPairMessage implements OpenposMessage {
    deviceIndex: number;
    type = 'WebSerialPair';
    clientSerialStatus: ClientSerialStatus;
}

export interface ClientSerialStatus {
    enabledFlag: boolean;
    pairedFLag: boolean;
    timestamp: Date;
    clientSerialPorts: Array<ClientSerialConfig>;
}

export class ClientSerialConfig {
    logicalName: string;
    pairedFlag: boolean;
    displayName: string;
    portName: string;
    peripheralType: string; // PERIPHERAL_TYPE_PRINTER, PERIPHERAL_TYPE_SCANNER, PERIPHERAL_TYPE_POLEDISPLAY
    clientSerialType: string; // ELECTRON vs. WEB_SERIAL
    settings: any;
}