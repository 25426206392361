<div mat-ripple
    responsive-class
    class="container order-identifier"
    [class.priority]="orderSummary.priorityFlag"
    [class.vertical]="displayVertical"
    [class.horizontal]="!displayVertical"
    [class.showStatusOnMobile]="showStatus && (isMobile$ | async)">
    <nu-order-header
        [orderNumber]="orderSummary.number"
        [orderType]="orderSummary.orderType"
        [iconName]="orderSummary.orderTypeIcon"
        [customer]="orderSummary.customer"
    ></nu-order-header>
    <div class="status">
        <nu-order-status *ngIf="showStatus" [status]="orderSummary.status"></nu-order-status>
        <nu-order-due responsive-class
            *ngIf="orderSummary.orderDue && displayVertical"
            [label]="orderSummary.orderDueLabel"
            [dueDate]="orderSummary.orderDue"
            [timeLabels]="orderSummary.timeUnitLabels"
        ></nu-order-due>
    </div>
    <div class="details" responsive-class>
        <strong *ngIf="!isOrderPendingDelivery()"><nu-order-item-count [label]="orderSummary.itemCountLabel" [count]="orderSummary.itemCount"></nu-order-item-count></strong>
        <nu-order-due responsive-class
            *ngIf="orderSummary.orderDue && !displayVertical && !isOrderOpen()"
            [label]="orderSummary.orderDueLabel"
            [dueDate]="orderSummary.orderDue"
            [timeLabels]="orderSummary.timeUnitLabels"
        ></nu-order-due>
        <nu-order-total *ngIf="isOrderOpen()" [total]="orderSummary.orderTotal"></nu-order-total>
    </div>
    <app-icon *ngIf="(isMobile$ | async)" class="icon" [iconName]="actionIcon" iconClass="sm"></app-icon>
</div>
