<app-bacon-strip class="order-bacon-strip">
    <div class="order-review" responsive-class>
        <div class="order-content" responsive-class>
            <div class="order-header" responsive-class>
                <nu-order-header-label
                        [iconName]="screen.orderIcon"
                        [headerText]="screen.orderTitle"
                        [orderType]="screen.orderNumberLabel"
                        [orderNumber]="screen.orderNumber">>
                </nu-order-header-label>
            </div>
            <div class="billing-header" responsive-class>
                <nu-order-customer responsive-class
                                   [customer]="screen.customer"
                                   [label]="screen.billToLabel"
                                   [showContact]="true" [showAddress]="true">
                </nu-order-customer>
                <div class="shipping-details" responsive-class>
                    <app-content-card class="order-shipping-with-methods" responsive-class>
                        <div class="shipping-address-content">
                            <div class="shipping-address-label" responsive-class>
                                {{screen.shippingAddressLabel}}
                            </div>
                            <div *ngIf="screen.customer.shippingAddress;else noShippingAddress"
                                 responsive-class
                                 class="shipping-address">
                                <div class="shipping-address-attention">
                                    {{screen.customer.shippingAddress.attention}}
                                </div>
                                <div class="shipping-address-address">
                                    {{screen.customer.shippingAddress.address}}
                                </div>
                                <div class="shipping-address-address">
                                    {{screen.customer.shippingAddress.address2}}
                                </div>
                                <div class="shipping-address-city-state-zip">
                                    {{screen.customer.shippingAddress.city}},
                                    {{screen.customer.shippingAddress.state}}
                                    {{screen.customer.shippingAddress.postalCode}}
                                </div>
                            </div>
                            <ng-template #noShippingAddress>
                                <div class="shipping-none">{{screen.noAddressMessage}}</div>
                            </ng-template>
                        </div>
                    </app-content-card>
                </div>
            </div>
            <div class="shipping-options-text" responsive-class>
                <p> Choose Shipping options </p>
            </div>
            <div class="order-group-list" responsive-class>
                <div *ngFor="let group of screen.orderReviewGroups" responsive-class
                     [ngClass]="{'order-group': !(isMobile$ | async)}">
                    <div class="order-item-list">
                        <mat-card>
                            <mat-card-content>
                                <div class="row">
                                    <div class="col-lg-7">
                                        <div class="order-item" *ngFor="let item of group.items" #items>
                                            <div class="order-item-content"
                                                 [ngClass]="{'order-item-content-mobile': (isMobile$ | async)}">
                                                <div class="order-item-description" responsive-class>
                                                    {{item.description}}
                                                </div>
                                                <app-image responsive-class
                                                           *ngIf="item.imageUrl"
                                                           class="order-item-image"
                                                           [imageUrl]="item.imageUrl"
                                                           [altText]="'Item Image'">
                                                </app-image>
                                                <div class="order-item-details" responsive-class>
                                                    <div class="order-item-details-field">
                                                        <span class="order-item-details-label">{{item.itemNumberLabel}}</span>
                                                        <span class="order-item-details-value">{{item.itemNumber}}</span>
                                                    </div>
                                                    <div class="order-item-status">{{item.status}}</div>
                                                    <div class="order-item-details-field">
                                                        <span class="order-item-details-label">{{item.quantityLabel}}</span>
                                                        <span class="order-item-details-value">{{item.quantity}}</span>
                                                    </div>
                                                </div>
                                                <div class="order-item-price">
                                                    <app-currency-text *ngIf="item.discountedAmount"
                                                                       responsive-class
                                                                       class="order-item-price-discount"
                                                                       [amountText]="item.discountedAmount">
                                                    </app-currency-text>
                                                    <app-currency-text class="order-item-price-original"
                                                                       responsive-class
                                                                       [amountText]="item.originalAmount">
                                                    </app-currency-text>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-5 shipping-method-list" *ngIf="group.shippingMethods?.length > 0">
                                        <app-content-card class="shipping-methods">
                                            <div responsive-class>
                                                <app-radio-item-list [items]="group.shippingMethods"
                                                                     (itemClick)="onItemClick($event, group)">
                                                    <ng-template #itemTemplate let-item>
                                                        <nu-shipping-method [item]="item" [selectable]="true">
                                                        </nu-shipping-method>
                                                    </ng-template>
                                                </app-radio-item-list>
                                            </div>
                                        </app-content-card>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>

                    <nu-order-notes *ngIf="screen.notesEnabled"
                                    class="order-notes"
                                    [notes]="group.notes"
                                    [addNoteButton]="screen.addNoteButton"></nu-order-notes>
                </div>
            </div>
        </div>
        <app-sale-total-panel *ngIf="!(isMobile$ | async)" class="order-total-panel"></app-sale-total-panel>
        <app-mobile-totals-part *ngIf="(isMobile$ | async)" class="order-total-panel-mobile"></app-mobile-totals-part>
    </div>
</app-bacon-strip>

