<app-bacon-strip class="promotion-bacon-strip">
<div class="main-layout">
    <div class="header-container">
        <div class="header-header">
            <svg width="40px"  viewBox="0 0 70 146" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g id="bab-party-integration" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Balloon-Copy-2" fill="#FFF" fill-rule="nonzero">
                        <path d="M43.12,81.2976119 L49,90.0229092 L38.5,90.0229092 C37.45,108.789223 35,127.486289 31.5,145.97561 L24.5,144.867635 C28,126.724557 30.38,108.442981 31.5,90.0229092 L21,90.0229092 L26.88,81.2976119 C11.48,74.7190147 0,50.9668163 0,34.6241959 C0,15.5017805 15.6700338,0 35,0 C44.2825771,0 53.1849641,3.64789588 59.7487373,10.1411922 C66.3125106,16.6344885 70,25.4412882 70,34.6241959 C70,50.9668163 58.52,74.7190147 43.12,81.2976119 Z" id="Path"></path>
                    </g>
                </g>
            </svg>

            <div class="header-text">
                <div class="header-title">{{screen.data.title}}</div>
                <div class="header-subtitle">Confirmation {{screen.data.confirmation}}</div>
                <div class="header-subtitle">{{screen.data.dateString}}</div>
                <div class="header-subtitle">Type:{{screen.data.type}}</div>
            </div>
        </div>
    </div>
    <div class="guest-container">
        <div class="guest-header-container">
            <div class="guest-title-container">
                <div class="guest-title">Guests</div>
                <div class="guest-people-icons-container">
                    <svg class="guest-person-icon" *ngFor="let item of [].constructor(screen.data.expectingGuestCount); let i = index" width="30px"viewBox="0 0 55 92" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g id="bab-party-integration" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Group" fill="#1C9DD5" fill-rule="nonzero">
                                <path d="M27.5,0 C35.0939153,0 41.25,6.17847045 41.25,13.8 C41.25,21.4215295 35.0939153,27.6 27.5,27.6 C19.9060847,27.6 13.75,21.4215295 13.75,13.8 C13.75,6.17847045 19.9060847,0 27.5,0 M22.9166667,92 L9.16666667,92 L9.16666667,64.4 L0,64.4 L0,32.2 L55,32.2 L55,64.4 L45.8333333,64.4 L45.8333333,92 L32.0833333,92 L32.0833333,73.6 L22.9166667,73.6 L22.9166667,92 Z" id="Shape"></path>
                            </g>
                        </g>
                    </svg> 
                                                 
                </div>
            </div>
            <div class="expecting-container">
                <div class="expecting-title"> Expecting </div>
                <div class="expecting-box">{{screen.data.expectingGuestCount}}</div>
            </div>
            <div class="checkedin-container">
                <div class="checkedin-title"> Checked-In </div>
                <div class="checkedin-box">{{screen.data.checkedInGuestCount}}</div>
            </div>            
        </div>   
        
        <div class="guest-list">
            <div class="guest-list-item" *ngFor="let item of screen.data.checkedInNames" >
                <svg width="13px" viewBox="0 0 25 52" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g id="bab-party-integration" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="Balloon-Copy-3" fill="#CBCBCB" fill-rule="nonzero">
                            <path d="M15.4,28.9601518 L17.5,32.0683112 L13.75,32.0683112 C13.375,38.7533207 12.5,45.4136622 11.25,52 L8.75,51.6053131 C10,45.142315 10.85,38.629981 11.25,32.0683112 L7.5,32.0683112 L9.6,28.9601518 C4.1,26.6166983 0,18.1555977 0,12.3339658 C0,5.5221046 5.59644063,0 12.5,0 C15.8152061,0 18.99463,1.29946767 21.3388348,3.61253496 C23.6830395,5.92560224 25,9.06279472 25,12.3339658 C25,18.1555977 20.9,26.6166983 15.4,28.9601518 Z" id="Path"></path>
                        </g>
                    </g>
                </svg>                
                <div class="guest-name">{{item}}</div>
            </div> 
        </div>   
        
        <div class="checkin-button">Check-In
            <svg class="checkin-icon" width="24px"  viewBox="0 0 44 44" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g id="bab-party-integration" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Group-2" transform="translate(-316.000000, -40.000000)" fill="#0155A5" fill-rule="nonzero">
                        <g id="Group" transform="translate(316.000000, 40.000000)">
                            <path d="M33,24.2 L24.2,24.2 L24.2,33 L19.8,33 L19.8,24.2 L11,24.2 L11,19.8 L19.8,19.8 L19.8,11 L24.2,11 L24.2,19.8 L33,19.8 M22,0 C9.8497355,0 0,9.8497355 0,22 C0,27.8347628 2.31785049,33.4305489 6.44365081,37.5563492 C10.5694511,41.6821495 16.1652372,44 22,44 C27.8347628,44 33.4305489,41.6821495 37.5563492,37.5563492 C41.6821495,33.4305489 44,27.8347628 44,22 C44,16.1652372 41.6821495,10.5694511 37.5563492,6.44365081 C33.4305489,2.31785049 27.8347628,0 22,0 Z" id="Shape"></path>
                        </g>
                    </g>
                </g>
            </svg>
        </div>        
    </div>    

    <div class="tasks-container">
        <div class="tasks-title">Game Plan</div> 
        <div class="party-clock">
            <div class="party-clock-title">Party Timer</div>
            <div class="party-clock-face">{{subscribeTimer}} / 1:30:00</div>
        </div>
        <div class="task-list">
            <div class="task-list-item">
                <div class="task-title">Party Leader: <b>{{screen.data.partyLeaderName}}</b></div>
            </div>
            <div class="task-list-item">
                <div class="task-title">Assistant: <b>{{screen.data.assistantName}}</b></div>
            </div>           
            <div class="task-list-item">
                <div class="task-title">Mascot: {{screen.data.mascotName}}</div>
            </div>                               
        </div> 
        <div class="progress-container">
            <div class="game-plan-progress">
                
            </div>                    
            <div class="progress-item">
                <div class="step-number">1</div>
                <div class="step-timeframe">5 mins</div>
                <div class="step-details">Introduction :) / Set expections</div>
            </div>
            <div class="progress-item">
                <div class="step-number red-item">2</div>
                <div class="step-timeframe">5-10 mins</div>
                <div class="step-details">Choose stuffed animal</div>
            </div>   
            <div class="progress-item">
                <div class="step-number yellow-item">3</div>
                <div class="step-timeframe">30 mins</div>
                <div class="step-details">Games and Stuffing / Game Ideas</div>
            </div>   
            <div class="progress-item">
                <div class="step-number lightblue-item">4</div>
                <div class="step-timeframe">10 mins</div>
                <div class="step-details">Mascot</div>
            </div>   
            <div class="progress-item">
                <div class="step-number blue-item">5</div>
                <div class="step-timeframe">5 mins</div>
                <div class="step-details">Sing Happy Birthday</div>
            </div>  
            <div class="progress-item">
                <div class="step-number darkblue-item">6</div>
                <div class="step-timeframe">5 mins</div>
                <div class="step-details">Heart Ceremony</div>
            </div>
            <div class="progress-item">
                <div class="step-number teal-item">7</div>
                <div class="step-timeframe">5 mins</div>
                <div class="step-details">Bear Carriers & Wrap up Games</div>
            </div>            
        
        </div>
</div> 



</div>
    
</app-bacon-strip>

