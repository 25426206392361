import { PosScreenDirective, PrinterService, ScreenComponent } from '@jumpmind/openpos-client-core-lib';
import { Component, Injector } from '@angular/core';
import { ShowReportInterface } from './show-report.interface';

@ScreenComponent({
    name: 'ShowReport'
})
@Component({
    selector: 'nu-show-report',
    templateUrl: './show-report.component.html',
    styleUrls: ['./show-report.component.scss'],

})
export class ShowReportComponent extends PosScreenDirective<ShowReportInterface> {
    constructor(
        injector: Injector,
        private printerService: PrinterService) {
        super(injector);
    }

    buildScreen(): any {
    }

    printReport(): void {
        this.printerService.print(this.screen.renderedReport).subscribe();
    }
}
