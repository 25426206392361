import { ChangeDetectorRef, Component, Injector } from '@angular/core';
import { PosScreenDirective, ScreenComponent } from '@jumpmind/openpos-client-core-lib';
import { GiftReceiptItemsSelectionInterface, GiftReceiptSelectionItem } from './gift-receipt-items-selection.interface';

@ScreenComponent({
    name: 'GiftReceiptItemsSelection'
})
@Component({
    selector: 'nu-gift-receipt-items-selection',
    templateUrl: './gift-receipt-items-selection.component.html',
    styleUrls: ['./gift-receipt-items-selection.component.scss']
})
export class GiftReceiptItemsSelectionComponent extends PosScreenDirective<GiftReceiptItemsSelectionInterface> {

    constructor(injector: Injector, private changeDetectorRef: ChangeDetectorRef) {
        super(injector);
    }

    buildScreen(): any {
    }

    selectAll(): void {
        if (this.screen.items.some(item => !item.giftReceipt)) {
            this.screen.items.forEach(item => item.giftReceipt = true);
        } else {
            this.screen.items.forEach(item => item.giftReceipt = false);
        }

        this.changeDetectorRef.detectChanges();
    }

    select(item: GiftReceiptSelectionItem): void {
        item.giftReceipt = !item.giftReceipt;
        this.changeDetectorRef.detectChanges();
    }

    submit(): void {
        if (this.screen.submitAction) {
            this.doAction(this.screen.submitAction, this.screen.items);
        }
    }
}
